import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData3";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import ICON from "../assets/deposit-imp.png";

const Dashboard = () => {
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [withdrawStatus,setWithdrawStatus,withdrawStatusref]= useState("")

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setfromCurrencyRef(currArrayCrypto);
    getSiteSettings();
  }, [0]);

  const navigate = useNavigate();

  const [siteLoader, setSiteLoader] = useState(false);

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [newAddres, setnewAddres] = useState("");
  const [newAddresErr, setnewAddresErr] = useState("");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const bankdetails = () => {};
  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      showsuccessToast(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      showerrorToast(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };

  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [sitekycStatus, setsitekycStatus, sitekycStatusref] =
    useState("DeActive");
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);

  const [addresshide, setaddresshide, addresshideref] = useState("Deactive");

  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
    getAddress();
    // getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const addresshides = async (e) => {
    if (addresshideref.current == "Deactive") {
      setaddresshide("Active");
    } else {
      setaddresshide("Deactive");
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].withdrawStatus == "Active") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            key: data[i]._id,
            text: data[i].currencySymbol,
            image: { avatar: true, src: data[i].Currency_image },
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
    }
  };
  const onSelect1 = async (e, option) => {
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );

    var currencydata = allCryptoref.current[indexData];

    console.log(currencydata, "currencydata");
    setnewCurrency(currencydata.currencySymbol);

    // setnewCurrency(option.label);
  };
  const onSelect = async (e, option) => {
    console.log(option.label, "option");
    formValue.withAddress = null;
    setwithdrawAddress("");
    console.log(currAddresref.current, "=-=currAddresref=--=-");
    setnet_default(null);
    setwithdrawAddress(null);
    setcur_network("");
    setcurrency(option.label);
    setcointype(option.coinType);
    setnet_current("");
    getAddress();
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (option.label == "USD") {
      showerrorToast("Fiat withdraw is not allowed by the site");
    } else {
      if (indexData != -1) {
        var currencydata = allCryptoref.current[indexData];
        setcurrentcurrency(currencydata);
        setcur_network([]);
        setnet_default("");
        var network_cur = {};
        var network_names = [];
        if (currencydata.currencyType == "2") {
          if (currencydata.erc20token == "1") {
            network_cur = {
              value: "erc20token",
              label: "erc20token",
              text: "ERC20",
              key: "erc20token",
            };
            network_names.push(network_cur);
          }
          if (currencydata.bep20token == "1") {
            network_cur = {
              value: "bep20token",
              label: "bep20token",
              text: "BEP20",
              key: "bep20token",
            };
            network_names.push(network_cur);
          }
          if (currencydata.trc20token == "1") {
            network_cur = {
              value: "trc20token",
              label: "trc20token",
              text: "TRC20",
              key: "trc20token",
            };
            network_names.push(network_cur);
          }

          if (currencydata.rptc20token == "1") {
            network_cur = {
              value: "rptc20token",
              label: "rptc20token",
              text: "RPTC20",
              key: "rptc20token",
            };
            network_names.push(network_cur);
          }
          setcur_network(network_names);
          // setnet_default(network_currencyref.current[0].label);
        }
        setwithdrawType(currencydata.coinType);
        // if (currencydata.coinType== "1") {
        var obj = {
          currency: currencydata.currencySymbol,
          currId: option.value,
        };

        console.log(obj, "obj");
        var data = {
          apiUrl: apiService.user_balance,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setBalance(resp.data);

          console.log(resp.data, "Balanceref");
        } else {
        }
        // } else {
        // }
      }
    }
  };

  const nav_page = async (link) => {
    navigate(link);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }

    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };

    setSiteLoader(true);
    var getKYC = await getMethod(data);
    setSiteLoader(false);

    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
      setBankwire(getKYC.bankdatastatus);
    } else {
      // setkycStatus(0);
    }
  };

  const onSelect_network = async (e, option) => {
    setnet_current(option.label);
  };

  const onSelect_address = async (e) => {
    console.log(
      e.target.value,
      "=-=-=-=option=-=-=-=-=option=-=-=-option=-=-="
    );
    setwithdrawAddress(e.target.value);
    formValue.withAddress = e.target.value;
    setwithAddress(false);
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };

  const withdrawPreview = async () => {
    try {
      console.log(formValue, "=-=-=v=-formValue-=-formValue=-=-vformValue");
      if (
        currentcurrency.currencySymbol == "USD" ||
        currentcurrency.currencySymbol == "INR"
      ) {
        if (bankwire == 1) {
          if (withdrawType == "2") {
            validateFiat_preview(formValue);
            if (formValue.amount != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showerrorToast(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showerrorToast(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      showsuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      showerrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showerrorToast("Insufficient Balance!");
                  setbuttonLoader(false);
                }
              } else {
                showerrorToast("Please give valid withdraw amount!");
                setbuttonLoader(false);
              }
            }
          } else {
            validate_preview(formValue);
            if (formValue.amount != "" && formValue.withAddress != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showerrorToast(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showerrorToast(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      showsuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      showerrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showerrorToast("Insufficient Balance");

                  setbuttonLoader(false);
                }
              } else {
                showerrorToast("Please give valid withdraw amount!");
              }
            }
          }
        } else {
          showerrorToast("Kindly update your Bank details");
          navigate("/bankdetails");
        }
      } else {
        if (withdrawType == "2") {
          validateFiat_preview(formValue);
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showerrorToast(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showerrorToast(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    showsuccessToast(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    showerrorToast(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                showerrorToast("Insufficient Balance!");

                setwithdrawAddress("");
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
              setbuttonLoader(false);
            }
          }
        } else {
          validate_preview(formValue);
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showerrorToast(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showerrorToast(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    showsuccessToast(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    showerrorToast(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                showerrorToast("Insufficient Balance");

                setbuttonLoader(false);
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const withdrawSubmit = async () => {
    try {
      if (withdrawType == "2") {
        validateFiat_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          } else {
            showerrorToast("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          } else {
            showerrorToast("Please give all the fields !");
          }
        }
      } else {
        validate_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          } else {
            showerrorToast("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const [newCurrency, setnewCurrency] = useState("");
  const [newCurrencyErr, setnewCurrencyErr] = useState(false);
  const [newNetwork, setnewNetwork] = useState("");
  const [alertTab, setalertTab] = useState("hide");
  const [allAddress, setallAddress, allAddressref] = useState([]);
  const [currAddres, setcurrAddres, currAddresref] = useState([]);
  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState();

  const getSiteSettings = async () =>{
    try{
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data.depositStatus,"resp==-=-=-=-=-=-resp");
      if (resp.status == true) {
        setWithdrawStatus(resp.data.withdrawStatus)
      }
    }catch(err){
         console.log(err,"=-=-=-=")
    }
  }

  const getAddress = async () => {
    var data = {
      apiUrl: apiService.getAddress,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      setallAddress(resp.data);
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];

        if (element.currency) {
          console.log(element, "element");

          var dropData = {
            value: element.address,
            label: element.currency,
            key: element.currency,
            text: element.address,
          };
          data.push(dropData);
        }
        setcurrAddres(data);
      }
    } else {
      setallAddress([]);
    }
  };
  const Addaddress = async () => {
    if (newAddres == "") {
      setnewAddresErr(true);
    } else if (newCurrency == "") {
      setnewCurrencyErr(true);
      setnewAddresErr(false);
    } else {
      setnewCurrencyErr(false);
      var obj = {
        Address: newAddres,
        currency: newCurrency,
        network: newNetwork,
      };

      console.log(obj, "obj");

      var data = {
        apiUrl: apiService.Addaddress,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        showsuccessToast(resp.message);
        setnewAddres("");
        setaddresshide("Deactive");
        setnewCurrency("");
        setnewNetwork("");
        getAddress();
      } else {
        showerrorToast(resp.message);
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main h-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 pad-lft">
                <Side_bar />
              </div>

              <div className="col-lg-10 pad-lft">
                <section className="asset_section">
                
                {
                      withdrawStatusref.current == "Active"?
                      kycStatusref.current == 0 ? (
<>
<div className="row">
                    <div className="withdraw_title_content">
                      <div className="withdraw_title">Withdrawal</div>
                      <Link to="" className="deposit--btn">
                        Fiat Withdrawal{" "}
                        <span>
                          <i class="fa-solid fa-chevron-right"></i>
                        </span>
                      </Link>
                    </div>

                    <div className="col-lg-7">
                      <div className="deposit border-rg mt-5">
                        <div className="form_div">
                          <div className="sides">
                            <div className="w-100 rights">
                              <h4>
                                {" "}
                                <span>1</span> Choose token to Withdraw
                              </h4>
                              <h6>Token</h6>
                              <Dropdown
                                placeholder="Select Coin"
                                fluid
                                className="dep-drops"
                                selection
                                options={allCurrencyref.current}
                                onChange={onSelect}
                                defaultValue={currencyref.current}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </div>

                        {show_otpref.current == false &&
                        currentcurrencyref.current.currencyType == "2" ? (
                          <div className="form_div ">
                            <h4>
                              {" "}
                              <span>2</span> Choose a network
                            </h4>
                            <h6>Network</h6>
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              className="dep-drops"
                              selection
                              options={network_currencyref.current}
                              onChange={onSelect_network}
                              defaultValue={net_defaultref.current}
                              isSearchable={true}
                              // onChange={(o) =>
                              //   onSelect(o, "fromTab")
                              // }
                              // onChange={handleOnChange_from}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form_div ">
                          <h4>
                            {" "}
                            <span>2</span> Withdrawal to
                          </h4>
                          <h6>Address</h6>
                          <input
                            type="text"
                            value={withdrawAddressref.current}
                            onChange={onSelect_address}
                            placeholder="Enter your address"
                            fluid
                            className="dep-drops"
                          />

                          {withAddressValidate == true ? (
                            <p className="errorcss withdraw-errcss">
                              {" "}
                              {validationnErr.withAddress}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        {show_otpref.current == true ? (
                          <div>
                            {localStorage.getItem("tfa_status") == 0 || 1 ? (
                              <>
                                <div className="form_div ">
                                  <h4>
                                    {" "}
                                    <span>4</span> Verification
                                  </h4>
                                  <h6>2FA</h6>

                                  <input
                                    type="text"
                                    pattern="\S*"
                                    autocomplete="off"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    placeholder="Enter 2FA Code"
                                    name="tfa"
                                    value={tfa}
                                    onChange={handleChange}
                                    className="dep-drops"
                                  />

                                  <div>
                                    {tfaValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.tfa}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}{" "}
                            <>
                              {" "}
                              <div className="form_div boder-none">
                                <h6>Withdraw OTP</h6>
                                <input
                                  type="text"
                                  pattern="\S*"
                                  autocomplete="off"
                                  placeholder="Enter Withdraw OTP"
                                  name="withdraw_otp"
                                  value={withdraw_otp}
                                  onChange={handleChange}
                                  className="dep-drops"
                                />
                                {otpValidate == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {validationnErr.withdraw_otp}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>{" "}
                          </div>
                        ) : (
                          ""
                        )}

                        {show_otpref.current == false ? (
                          <div className="form_div boder-none ">
                            <h4>
                              {" "}
                              <span>3</span> Withdrawal Amount
                            </h4>
                            <h6>Amount</h6>
                            <input
                              type="number"
                              pattern="[0-9]*"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              name="amount"
                              value={amount}
                              onChange={handleChange}
                              placeholder="Enter the amount"
                              fluid
                              className="dep-drops"
                            />
                            {amountValidate == true ? (
                              <p className="errorcss withdraw-errcss">
                                {" "}
                                {validationnErr.amount}{" "}
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="fees_content">
                              <h4>Fees</h4>
                              <p>{currentcurrencyref.current.withdrawFee} %</p>
                            </div>
                            <div className="fees_content">
                              <h4>Minimum withdraw</h4>
                              <p>
                                {currentcurrencyref.current.minWithdrawLimit}
                              </p>
                            </div>
                            <div className="fees_content">
                              <h4>Maximum withdraw</h4>
                              <p>
                                {currentcurrencyref.current.maxWithdrawLimit}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {buttonLoader == false ? (
                          <div className="sumbit_btn">
                            {localStorage.getItem("tfa_status") == 0 ? (
                              <button onClick={() => nav_page("/security_2fa")}>
                                Enable2FA
                              </button>
                            ) : show_otpref.current == true ? (
                              <button onClick={() => withdrawSubmit()}>
                                Submit
                              </button>
                            ) : show_otpref.current == false ? (
                              <button onClick={() => withdrawPreview()}>
                                Submit
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div className="sumbit_btn">
                            <button>Loading...</button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div>
                        <div className="container">
                          <div className="deposit-imp-notes mt-5">
                            <div className="imp-notes-title">
                              <span>
                                <img
                                  src={ICON}
                                  alt="warn-icon"
                                  className="deposit-imp-icon"
                                />
                              </span>
                              <p>Important Notes</p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Double-check the deposit address</h6>
                              <p>
                                Ensure the deposit address provided is accurate.
                                Sending funds to the wrong address may result in
                                the loss of your assets.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Verify deposit amount</h6>
                              <p>
                                Confirm the deposit amount and ensure it meets
                                the minimum deposit requirements, if any.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Security</h6>
                              <p>
                                Make sure your account is secure and that you
                                are using a trusted network before proceeding
                                with the deposit.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Network compatibility</h6>
                              <p>
                                Ensure you are depositing on the correct
                                network. Deposits made on an incompatible
                                network may be lost, and Voltirx Crypt will not
                                be able to recover or refund your funds.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard_table">
                    <div className="staking-flex dash_assets">
                      <h5 className="opt-title">Recent Withdraw</h5>
                      <Link to="/withdrawHistory">
                        <div className="ref_view_main">
                          <span className="invite_text">View All</span>
                          <i
                            class="ri-arrow-right-s-line"
                            style={{ color: "#33C2B9" }}
                          ></i>
                        </div>
                      </Link>
                    </div>
                    <div className="table-responsive table-cont dash_table_content">
                      <table className="table ">
                        <thead>
                          <tr className="stake-head font-satoshi">
                            <th> Date</th>
                            <th className="table_center_text">Time</th>
                            <th className="table_center_text">Currency</th>
                            <th className="table_center_text">Amount</th>
                            <th className="table_center_text">
                              Transaction Id
                            </th>
                            <th className="table_action">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {withdrawHistory && withdrawHistory.length > 0 ? (
                            withdrawHistory.slice(0, 5).map((item, i) => {
                              return (
                                <tr>
                                  {/* <td className="table-flex">
                                    <img
                                      src={require(`../assets/${options.optImg}`)}
                                      alt=""
                                    />
                                    <div className="table-opt-name">
                                      <h4 className="opt-name font-satoshi font_14">
                                        {options.optName}
                                      </h4>
                                      <h3 className="opt-sub font-satoshi font_14">
                                        {options.optSub}
                                      </h3>
                                    </div>
                                  </td> */}
                                  <td className="opt-term font-satoshi font_14 px-4">
                                    {Moment(item.date).format("YYYY-MM-DD")}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {Moment(item.date).format("HH:mm:ss")}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {item.currencySymbol}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {parseFloat(item.amount).toFixed(4)}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {item.txnid
                                      ? item.txnid.substring(0, 10) + "... "
                                      : "----"}
                                    <i
                                      class="fa fa-clone"
                                      aria-hidden="true"
                                      onClick={() => copy(item.txnid)}
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </td>
                                  <td className="opt-term font-satoshi font_14 px-2 table_center_last text-success">
                                    Completed
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center py-5">
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../assets/No-data.png")}
                                      width="100px"
                                    />
                                  </div>
                                  <div className="no_records_text">
                                    No Records Found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* <div className="pagination">
                          <Stack spacing={2}>
                            <Pagination count={4} size="small" />
                          </Stack>
                        </div> */}
                    </div>
                  </div></>
                      ):
                      <>
                        {/*/TODO: withdraw - KYC Verification required box*/}

                <div className="row ">
                    <div className="withdraw_title_content">
                      <div className="withdraw_title">Withdrawal</div>
                      <Link to="" className="deposit--btn">
                        Fiat Withdrawal{" "}
                        <span>
                          <i class="fa-solid fa-chevron-right"></i>
                        </span>
                      </Link>
                    </div>

                    <div className="col-lg-7">
                      <div className="deposit-identity-box mt-5 h-100">
                        <div className="dep-kyc">
                          <div className="dep-kyc-head">
                            <img
                              src={ICON}
                              alt="warn-icon"
                              className="deposit-imp-icon"
                            />
                            <h6>KYC Verification Required</h6>
                          </div>
                          <p>
                            You haven't completed the KYC verification process.
                            To ensure the security and compliance of our
                            platform, you must complete your KYC verification
                            before you can withdraw any crypto.
                          </p>
                          <div>
                            <img
                              src={require("../assets/deposit-id.webp")}
                              alt="Verify kyc"
                              className="before_kyc_depo withdraw-p-l-24"
                            />
                          </div>
                          <p className="mt-4">
                            Please click the button below to verify your account
                          </p>
                          <div className="withdraw-p-l-24">
                            <Link to="/kyc">
                              <button className="dep-kyc-btn  w-100 mb-2">
                                Verify Now
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5">
                      <div>
                        <div className="container">
                          <div className="deposit-imp-notes mt-5 max-lg-t-3">
                            <div className="imp-notes-title">
                              <span>
                                <img
                                  src={ICON}
                                  alt="warn-icon"
                                  className="deposit-imp-icon"
                                />
                              </span>
                              <p>Important Notes</p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Double-check the deposit address</h6>
                              <p>
                                Ensure the deposit address provided is accurate.
                                Sending funds to the wrong address may result in
                                the loss of your assets.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Verify deposit amount</h6>
                              <p>
                                Confirm the deposit amount and ensure it meets
                                the minimum deposit requirements, if any.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Security</h6>
                              <p>
                                Make sure your account is secure and that you
                                are using a trusted network before proceeding
                                with the deposit.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Network compatibility</h6>
                              <p>
                                Ensure you are depositing on the correct
                                network. Deposits made on an incompatible
                                network may be lost, and Voltirx Crypt will not
                                be able to recover or refund your funds.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> </>
                      :
                  <>
                  {/*/TODO: withdraw - Temporary Unavailable */}
          <div className="row ">
          <div className="withdraw_title_content">
            <div className="withdraw_title">Withdrawal</div>
            <Link to="" className="deposit--btn">
              Fiat Withdrawal{" "}
              <span>
                <i class="fa-solid fa-chevron-right"></i>
              </span>
            </Link>
          </div>

          <div className="col-lg-7">
            <div className="deposit-identity-box mt-5 h-100">
              <div className="dep-kyc">
                <div className="dep-kyc-head">
                  <img
                    src={require("../assets/dep-warn.png")}
                    alt="warn-icon"
                    className="deposit-imp-icon"
                  />
                  <h6>Withdrawals Temporarily Unavailable</h6>
                </div>
                <p>
                  Due to ongoing platform maintenance, withdrawals are
                  currently restricted. We apologize for any
                  inconvenience this may cause. Our team is working
                  diligently to restore full service as soon as
                  possible.
                </p>
                <p className="my-3">
                  Estimated Time to Resolution:{" "}
                  <span className="text-primary">00:00:00</span>
                </p>
                <div>
                  <img
                    src={require("../assets/dep-unavail.png")}
                    alt="Verify kyc"
                    className="before_kyc_depo withdraw-p-l-24"
                  />
                </div>
                <p className="mt-4">
                  Thank you for your patience and understanding.
                </p>
                <div className="withdraw-p-l-24">
                  <Link to="/dashboard">
                    <button className="dep-kyc-btn  w-100 mb-2">
                      Back To Home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div>
              <div className="container">
                <div className="deposit-imp-notes mt-5 max-lg-t-3">
                  <div className="imp-notes-title">
                    <span>
                      <img
                        src={ICON}
                        alt="warn-icon"
                        className="deposit-imp-icon"
                      />
                    </span>
                    <p>Important Notes</p>
                  </div>
                  <div className="imp-notes-content">
                    <h6>Double-check the destination address</h6>
                    <p>
                      Make sure the address you provided is correct.
                      Transactions cannot be reversed once completed.
                    </p>
                  </div>
                  <div className="imp-notes-content">
                    <h6>Verify withdrawal details</h6>
                    <p>
                      Confirm the amount and fees before finalizing
                      the request.
                    </p>
                  </div>
                  <div className="imp-notes-content">
                    <h6>Security</h6>
                    <p>
                      Ensure that your account is secure and that you
                      are using a trusted network.
                    </p>
                  </div>
                  <div className="imp-notes-content">
                    <h6>Network verification</h6>
                    <p>
                      Double-check your network for compatibility.
                      Transactions made on the wrong network cannot be
                      reversed, and Voltrix crypt will not be able to
                      recover or provide any amount.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        </>
        }
                

                

                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Dashboard;
