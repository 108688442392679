import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { assetList } from "../utils/rewardsdata";

const AssetListTable = () => {
  const [rewardHistory, setrewardHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [rewardcurrentpage, setrewardcurrentpage] = useState(1);
  const [rewardtotalpage, setrewardTotalpages] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getrewardHistory(1);
  }, [0]);

  const getrewardHistory = async (page) => {
    var data = {
      apiUrl: apiService.admin_reward,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);
    var reward_history_list = await postMethod(data);
    setSiteLoader(false);
    if (reward_history_list) {
      setrewardHistory(reward_history_list.data.data);
      setrewardTotalpages(reward_history_list.pages);
    }
  };

  const rewardrecordpage = 5;
  const rewardpagerange = 5;

  const handlepagereward = (event, page) => {
    getrewardHistory(page);
    setrewardcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  return (
    <div className="table-responsive table-cont">
      <table className="table">
        <thead>
          <tr className="stake-head font-satoshi">
            <th>S.No </th>
            <th className="opt-nowrap txt-center pad-left-23">Email</th>
            <th className="opt-nowrap txt-center pad-left-23">Type</th>
            <th className="opt-nowrap txt-center pad-left-23">Amount</th>
            <th className="opt-nowrap txt-center pad-left-23">Currency</th>
            <th className="opt-nowrap txt-center pad-left-23">date</th>
          </tr>
        </thead>

        <tbody>
        {rewardHistory.length > 0 ? (
                              rewardHistory.map((data, i) => {
                                return (
              <tr>
                <td className="table-flex ">
                  {/* <img src={require(`../assets/${options.optImg}`)} alt="" /> */}
                  <div className="table-opt-name">
                    <h4 className="opt-name font-satoshi font_14">
                    {i+1}
                    </h4>
                    {/* <h3 className="opt-sub font-satoshi font_14">
                      {options.optSub}
                    </h3> */}
                  </div>
                </td>
                {/* opt-percent */}
                <td className=" font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                {data.userName}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                {data.userEmail}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                {data.type}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                {data.bonusAmount}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                {data.currency}
                </td>
                <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                {Moment(data.date).format("lll")}
                </td>
              </tr>
            )
          })):(
            <tr>
                                <td colSpan={5} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.png")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      No Records Found
                                    </div>
                                  </div>
                                </td>
                              </tr>
          )}
        </tbody>
      </table>
      {rewardHistory && rewardHistory.length > 0 ? (
                          <div className="pagination">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(
                                  rewardtotalpage / rewardrecordpage
                                )}
                                page={rewardcurrentpage}
                                onChange={handlepagereward}
                                size="small"
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        ) : (
                          ""
                        )}
      {/* <div className="paginate font-satoshi">
        <span>
          <i class="fa-solid fa-chevron-left"></i>
        </span>
        <span className="paginate-one">1</span>
        <span>2</span>
        <span>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
      </div> */}
    </div>
  );
};

export default AssetListTable;
