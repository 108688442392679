import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Lightlogo from "../assets/Logo.png";
import Darklogo from "../assets/Logo.png";
import UserIcon from "../assets/account.png";
import security from "../assets/security.png";
import logout from "../assets/logout.png";
import arrow from "../assets/arrow.png";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@material-ui/core";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Wallet from "@mui/icons-material/Wallet";
import { Link, useNavigate } from "react-router-dom";
import { removeAuthorization } from "../core/service/axios";
import { removeAuthToken } from "../core/lib/localStorage";

import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Sidebar from "../Pages/Side_bar"
import env from "../core/service/envconfig";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [Theme,setTheme]=useState(localStorage.getItem("theme"))
  const handleThemeToggle = () => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      localStorage.setItem("theme", "light");
      setTheme("light");
    } else {
      localStorage.setItem("theme", "dark");
      document.body.classList.add("dark");
      setTheme("dark");
    }
    window.location.reload();
  };
  

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [profileData, setprofileData] = useState("");
  const [themeToggle, setThemeToggle] = useState(localStorage.getItem('Mode') || 'light');

  const classes = useStyles();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
      getProfile();
    } else {
      setloginCheck(false);
    }
    getTheme();
  }, []);

  const getTheme = () => {
    const theme = localStorage.getItem("theme");
    if (theme !== null) {
      if (theme === "dark") {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      document.body.classList.add("dark");
    }
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);

      if (resp.status == true) {
        setprofileData(resp.data);
      }
    } catch (error) {}
  };

  const obfuscateEmail = (email) => {
    if (!email) return "";
    const [localPart, domainPart] = email.split("@");
    const firstFive = localPart.slice(0, 5);
    return `${firstFive}***@${domainPart}`;
  };

  const [open1, setOpen] = useState(false);

  const navigate = useNavigate();

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/login");
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("UID copied");
  };

  // const [mode, setMode] = useState("pro");
  const handleLightToggle = () => {
    setThemeToggle("light");
    localStorage.setItem("Mode", "light");
    window.location.reload();
  };

  const handleProToggle = () => {
    setThemeToggle("pro");
    localStorage.setItem("Mode", "pro");
    window.location.reload();
  };
  <Sidebar data={themeToggle}  />

  return (
    <div>
      <header className="scrolled">
        <div className="">
          <div className="header_new_mega">
            <div className={`${classes.root} `}>
              <AppBar position="static">
                <Toolbar className="container">
                  <Typography variant="h6" className={classes.title}>
                    <div className="logo_new_sectio d-flex">
                      <Link to="/" className="logo-brand">
                        <img
                          src={Lightlogo}
                          className="img-fluid fidex_logo_light"
                          alt="logo"
                        />
                        <img
                          src={require("../../src/assets/fidex_dark_logo.webp")}
                          className="fidex_dark_logo"
                        />
                      </Link>

                      <span className="das ml-4">|</span>

                      <div className={`menu_new_typr ${classes.appBarItems}`}>
                        <a
                          href="/buycrypto"
                          color="inherit"
                          className="contact_button"
                        >
                          Buy crypto
                        </a>
                        {themeToggle == "pro" ? (
                          <>
                            <a
                              href="/market"
                              color="inherit"
                              className="contact_button"
                            >
                              Market
                            </a>
                            <a
                              href="/trade/ETH_USDT"
                              color="inherit"
                              className="contact_button"
                            >
                              Trade
                            </a>
                          </>
                        ) : (
                          ""
                        )}

                        <a
                          href="/swap"
                          color="inherit"
                          className="contact_button"
                        >
                          Convert
                        </a>

                        <a
                          href="/staking"
                          color="inherit"
                          className="contact_button"
                        >
                          Staking
                        </a>
                        {themeToggle == "pro" ? (
                          <a
                            href="/refferal"
                            color="inherit"
                            className="contact_button"
                          >
                            Invite and earn
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Typography>

                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                  >
                    <Link className="contact_button" to="/stake">
                      <img
                        src={require("../assets/download (1).png")}
                        className="moons"
                        width="30px"
                      />
                    </Link>

                    {Theme == "dark" ?
                         <Link className="contact_button" >
                         <img
                          onClick={handleThemeToggle}
                           src={require("../assets/Day.png")}
                           width="28px"
                         />
                        </Link>
                        :
                        <Link className="contact_button">
                        <img
                          onClick={handleThemeToggle}
                          src={require("../assets/moon.png")}
                          width="28px"
                        />
                        </Link>
                        }
                    <>
                      <h6
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className=" head-drops"
                        onClick={handleClick}
                      >
                        <img
                          src={require("../assets/profile.png")}
                          className="minimage"
                          width="30px"
                        />
                      </h6>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem>
                          <LogoutIcon />
                          <span className="mx-3">Disconnect</span>{" "}
                        </MenuItem>
                      </Menu>
                    </>

                    <MenuIcon className="meus" onClick={handleMobileMenuOpen} />
                  </IconButton>

                  <div className={`menu_new_typr ${classes.appBarItems}`}>
                    {loginCheck == true ? (
                      <>
                    {
                       themeToggle === "pro" ?

                       <Link to="/deposit">
                       {" "}
                       <button className="head-btn">Deposit</button>
                     </Link>
                       :""
                    }

                        <Link className="contact_button" to="/assets">
                          Assets
                        </Link>

                        <>
                          <h6
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className=" head-drops"
                            onClick={handleClick}
                          >
                            <img
                              src={require("../assets/profile.png")}
                              width="28px"
                              className="dark_display_none"
                            />
                            <img
                              src={require("../../src/assets/profile_dark.png")}
                              className="dark_profile"
                            />
                          </h6>

                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <div className="frame-container">
                              {/* user details */}
                              <div className="user-details">
                                <img
                                  src={UserIcon}
                                  alt="usericon"
                                  className="user-img"
                                />
                                <div className="details">
                                  <span className="details-mail">
                                    {obfuscateEmail(profileData.email)}
                                  </span>
                                  <span className="details-udi">
                                    UID:{profileData.uuid}{" "}
                                    <i
                                      className="fa-regular fa-copy cursor-pointer"
                                      onClick={() => copy(profileData.uuid)}
                                    ></i>{" "}
                                  </span>
                                </div>
                              </div>
                              {/* links */}
                              <ul className="links">
                                <li>
                                  <Link to="/dashboard">Dashboard</Link>
                                </li>
                                <li className="security-link">
                                  <Link to="/security">Security</Link>
                                  <span className="identify">
                                    <img src={security} alt="security" />
                                  </span>
                                </li>
                                <li className="security-link">
                                  <Link href="/kyc">Identification</Link>
                                  <Link to="/kyc">
                                    <div>
                                      {profileData.kycstatus == 1 ? (
                                        <>
                                          <span className="identify">
                                            verified
                                          </span>
                                          <span>
                                            <img
                                              src={arrow}
                                              alt="arrow-icon"
                                              className="arrow"
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="identify">
                                            Not verified
                                          </span>
                                          <span>
                                            <img
                                              src={arrow}
                                              alt="arrow-icon"
                                              className="arrow"
                                            />
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </Link>
                                </li>
                                {
                       themeToggle === "pro" ?
                       <li>
                       <Link to="/withdraw">Withdrawal</Link>
                       </li>
                          :""
                                }
                               
                                <li>
                                  <Link to="/rewards">My Rewards</Link>
                                </li>
                                <li>
                                  <Link to="/depositHistory">History</Link>
                                </li>
                                <li>
                                  <Link to="/support">Support</Link>
                                </li>
                                <li>
                                  <Link to="/settings">Settings</Link>
                                </li>
                              </ul>
                              {/* logout button */}
                              <div
                                className="btn-wrapper security-link"
                                onClick={logout}
                              >
                                <span className="user-btn">Sign Out</span>
                                <img
                                  src={require("../assets/logout.png")}
                                  alt="logoutimg"
                                  className="logout-img"
                                />
                              </div>
                            </div>
                          </Menu>
                        </>
                      </>
                    ) : (
                      <>
                        <Link
                          className="contact_button txt-primary"
                          to="/login"
                        >
                          Sign in
                        </Link>
                        <Link to="/register">
                          {" "}
                          <button className="head-btn">Sign up</button>
                        </Link>
                      </>
                    )}

                    <span className="das mar-lft-ryt">|</span>

                    <div className="header-toggle-cont">
                      <button
                        onClick={handleLightToggle}
                        className={
                          themeToggle === "pro" ? "pro_btn" : "light_btn"
                        }
                      >
                        Lite
                      </button>
                      <button
                        onClick={handleProToggle}
                        className={
                          themeToggle === "light" ? "pro_btn" : "light_btn"
                        }
                      >
                        Pro
                      </button>
                    </div>

                    {/* </Link> */}

                    {/* <Link className="contact_button" to="/stake">
                      <img src={require("../assets/Globe.png")} width="28px" />
                    </Link> */}

                    <span className="das mar-lft-ryt">|</span>
                        
                        {Theme == "dark" ?
                         <Link className="contact_button" >
                         <img
                          onClick={handleThemeToggle}
                           src={require("../assets/Day.png")}
                           width="28px"
                         />
                        </Link>
                        :
                        <Link className="contact_button">
                        <img
                          onClick={handleThemeToggle}
                          src={require("../assets/moon.png")}
                          width="28px"
                        />
                        </Link>
                        }
                  </div>
                </Toolbar>
              </AppBar>

              <Drawer
                anchor="left"
                open={mobileMenuOpen}
                onClose={handleMobileMenuClose}
                className={classes.drawer}
              >
                <div className={classes.mobileMenu}>
                  <div className="logo_new_sectio mobile_menu_icon">
                    <Link to="/" className="logo-brand">
                      <img
                        src={Darklogo}
                        className="img-fluid m-3 fidex_mobile_lightlogo"
                        alt="logo"
                      />
                      <img
                        src={require("../assets/fidex_dark_logo.webp")}
                        className="fidex_mobile_darklogo"
                      />
                    </Link>
                  </div>
                  {/*  */}

                  <div className="menu_statis">
                    <ListItem button className="drawa">
                      <Link to="/buycrypto">Buy Crypto</Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/market">Market</Link>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <Link to="/trade/ETH_USDT">Trade</Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/swap">Convert</Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/staking">Staking</Link>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <Link to="/refferal">Invite and earn</Link>
                    </ListItem>
                    {loginCheck == true ? (
                      <>
                        <ListItem button className="drawa ">
                          <Link to="/assets">Assets</Link>
                        </ListItem>

                        <ListItem button className="drawa ">
                          <Link to="/dashboard">Dashboard</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/security">Security</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/deposit">Deposit</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/KycInformation1">Identification</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/Withdraw">Withdrawal</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/rewards">My Rewards</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/depositHistory">History</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/settings">Settings</Link>
                        </ListItem>
                        <div className="header-toggle-cont">
                          <button
                            onClick={handleLightToggle}
                            className={
                              themeToggle === "pro" ? "pro_btn" : "light_btn"
                            }
                          >
                            Lite
                          </button>
                          <button
                            onClick={handleProToggle}
                            className={
                              themeToggle === "light" ? "pro_btn" : "light_btn"
                            }
                          >
                            Pro
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <ListItem button className="drawa">
                          <Link to="/login" className="drawar-signin">
                            Sign in
                          </Link>
                        </ListItem>

                        <ListItem button className="drawa ">
                          <Link to="/register" className="drawar-signup">
                            Sign up
                          </Link>
                        </ListItem>
                      </>
                    )}
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
