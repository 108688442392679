import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

export default function Verification() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  const submit = async () => {
    try {
      if (OTP !== "") {
        console.log(OTP, "otp-=-=-");
        var obj = {
          emailOtp: OTP,
          email: localStorage.getItem("useremail"),
        };

        var data = {
          apiUrl: apiService.emailotpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/login");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Enter OTP");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleResend = async () => {
    setCounter(60);
    setIsResendVisible(false);
    // Add logic to resend the OTP
    console.log("OTP resent");
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification">
          <div className="container">
            <div>
              <Link to="/register">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Sign Up
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  <span class="heading">Verification</span>

                  <div className="notify">
                    {" "}
                    We have sent you an verification code to the register email
                    address and phone number{" "}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">Email Verification code</h6>
                    <input
                      type="number"
                      name="OTP"
                      min={1000}
                      max={9999}
                      value={OTP}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => setOTP(e.target.value)}
                      className="input-field"
                      placeholder="Enter the code"
                    />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">Phone.No Verification code</h6>
                    <input
                      className="input-field"
                      placeholder="Enter the code"
                    />
                  </div>

                  <div className="Submit my-4" onClick={submit}>
                    {buttonLoader == false ? (
                      <button>Submit</button>
                    ) : (
                      <button>Loading ...</button>
                    )}
                  </div>

                  <div className="foot">
                    <p>
                      Didn't receive a code ?{" "}
                      <a>
                        {isResendVisible ? (
                          <span
                            onClick={handleResend}
                            className="cursor-pointer"
                          >
                            <a> Resend</a>
                          </span>
                        ) : (
                          <span className="textgreen">{counter}s</span>
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
