import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "moment";
import ICON from "../assets/deposit-imp.png";

const Dashboard = () => {
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setfromCurrencyRef(currArrayCrypto);
  }, [0]);

  const navigate = useNavigate();

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("USDT");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [Fullname, Setfullname, Fullnameref] = useState("Tether");
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);

  const [depositStatus,setDepositStatus,depositStatusref]= useState("")
  useEffect(() => {
    getAllcurrency();
    getKYCstatus();
    getdepositHistory();
    getTransaction();
    getSiteSettings();
  }, [0]);

  const getSiteSettings = async () =>{
    try{
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data.depositStatus,"resp==-=-=-=-=-=-resp");
      if (resp.status == true) {
        setDepositStatus(resp.data.depositStatus)
      }
    }catch(err){
         console.log(err,"=-=-=-=")
    }
  }
  const getTransaction = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    setrefreshStatus(false);
    if (resp.message == true) {
      getdepositHistory();
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].depositStatus == "Active") {
          var obj = {
            value: data[i]._id,
            key: data[i]._id,
            text: data[i].currencySymbol,
            image: { avatar: true, src: data[i].Currency_image },
            label: data[i].currencySymbol,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            coinType: data[i].coinType,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
      setcurrency(currArrayCrypto[0].label);
      Setfullname(currArrayCrypto[0].currencyName);
      setImage(currArrayCrypto[0].imgurl);
      setcointype(currArrayCrypto[0].coinType);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    setSiteLoader(true);
    var getKYC = await getMethod(data);
    setSiteLoader(false);
    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
    } else {
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: 1 },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
    }
  };

  const onSelect = async (e, option) => {
    setcur_network([]);
    setnet_default("");
    setcurrency(option.label);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
            text: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
            text: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
            text: "TRC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        setnet_default(network_currencyref.current[0].label);
      }
      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currencySymbol: currencydata.currencySymbol,
          currId: option.value,
          network: "",
        };

        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };

        setview("load");
        var resp = await postMethod(data);
        if (resp.status) {
          setview("view");
          setAddress(resp.data);
        } else {
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setBankwire(resp.data);
        } else {
        }
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  const onSelect_network = async (e, option) => {
    setNetworks(option.label);
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currencySymbol: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      var data = {
        apiUrl: apiService.generateAddress,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      if (resp.status) {
        setview("view");
        setAddress(resp.data);
      } else {
      }
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main assets_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 pad-lft">
                <Side_bar />
              </div>
              <div className="col-lg-10 pad-lft">
                <section className="asset_section">
                  <div className="row">
                    {
                      depositStatusref.current == "Active"?
                      kycStatusref.current == 1 ? (
                        <>
                         <div>
                      <div className="identification_title deposit-flex">
                        <h5 className="deposit--title">Deposit</h5>
                        <Link to="" className="deposit--btn">
                          Fiat Deposit{" "}
                          <span>
                            <i class="fa-solid fa-chevron-right"></i>
                          </span>
                        </Link>
                      </div>
                    </div>
                          <div className="col-lg-7">
                            <div className="deposit mt-5">
                              <div className="form_div">
                                <div className="sides">
                                  <div className="w-100 rights">
                                    <h4>
                                      {" "}
                                      <span>1</span> Choose token to deposit
                                    </h4>
                                    <h6>Token</h6>
                                    <Dropdown
                                      placeholder="Select Coin"
                                      fluid
                                      className="dep-drops"
                                      selection
                                      options={allCurrencyref.current}
                                      defaultValue={allCurrencyref.current[0]}
                                      onChange={onSelect}
                                    />
                                  </div>
                                </div>
                              </div>
                              {cur_currencyref.current.currencyType == "2" ? (
                                <div className="form_div ">
                                  <h4>
                                    {" "}
                                    <span>2</span> Choose a network
                                  </h4>
                                  <h6>Network</h6>
                                  <Dropdown
                                    placeholder="Select Coin"
                                    fluid
                                    className="dep-drops"
                                    selection
                                    options={network_currencyref.current}
                                    defaultValue={network_currencyref.current[0]}
                                    onChange={onSelect_network}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {addressref.current == undefined ? (
                                ""
                              ) : (
                                <>
                                  <div className="form_div boder-none ">
                                    <h4>
                                      {" "}
                                      <span>3</span> Network Address
                                    </h4>
                                    <img
                                      // src={require("../assets/qr.png")}
                                      src={
                                        addressref.current == undefined
                                          ? ""
                                          : addressref.current.qrcode
                                      }
                                      className="d-block mx-auto dep-qr "
                                    />
                                    <p>
                                      Scan the QR code or copy the address to
                                      deposit
                                    </p>
                                  </div>
  
                                  <div className="form_div boder-none">
                                    <div className="add_box">
                                      <h6 className="address">
                                        {addressref.current == undefined
                                          ? "Ajhrb=-=-=-=-=--=-=-=-"
                                          : addressref.current.address}
                                      </h6>
                                      <i
                                        class="ri-file-copy-line text_green icon"
                                        onClick={() =>
                                          copy(addressref.current.address)
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-5">
                      <div>
                        <div className="container">
                          <div className="deposit-imp-notes mt-5">
                            <div className="imp-notes-title">
                              <span>
                                <img
                                  src={ICON}
                                  alt="warn-icon"
                                  className="deposit-imp-icon"
                                />
                              </span>
                              <p>Important Notes</p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Double-check the deposit address</h6>
                              <p>
                                Ensure the deposit address provided is accurate.
                                Sending funds to the wrong address may result in
                                the loss of your assets.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Verify deposit amount</h6>
                              <p>
                                Confirm the deposit amount and ensure it meets
                                the minimum deposit requirements, if any.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Security</h6>
                              <p>
                                Make sure your account is secure and that you
                                are using a trusted network before proceeding
                                with the deposit.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Network compatibility</h6>
                              <p>
                                Ensure you are depositing on the correct
                                network. Deposits made on an incompatible
                                network may be lost, and Voltirx Crypt will not
                                be able to recover or refund your funds.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard_table">
                    <div className="staking-flex dash_assets">
                      <h5 className="opt-title">Recent Deposit</h5>
                      <Link to="/depositHistory">
                        <div className="ref_view_main">
                          <span className="invite_text">View All</span>
                          <i
                            class="ri-arrow-right-s-line"
                            style={{ color: "#33C2B9" }}
                          ></i>
                        </div>
                      </Link>
                    </div>
                    <div className="table-responsive table-cont dash_table_content">
                      <table className="table ">
                        <thead>
                          <tr className="stake-head font-satoshi">
                            <th> Date</th>
                            <th className="table_center_text">Time</th>
                            <th className="table_center_text">Currency</th>
                            <th className="table_center_text">Amount</th>
                            <th className="table_center_text">
                              Transaction Id
                            </th>
                            <th className="table_action">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {depositHistory && depositHistory.length > 0 ? (
                            depositHistory.slice(0, 5).map((item, i) => {
                              return (
                                <tr>
                                  {/* <td className="table-flex">
                                    <img
                                      src={require(`../assets/${options.optImg}`)}
                                      alt=""
                                    />
                                    <div className="table-opt-name">
                                      <h4 className="opt-name font-satoshi font_14">
                                        {options.optName}
                                      </h4>
                                      <h3 className="opt-sub font-satoshi font_14">
                                        {options.optSub}
                                      </h3>
                                    </div>
                                  </td> */}
                                  <td className="opt-term font-satoshi font_14 px-4">
                                    {Moment(item.date).format("YYYY-MM-DD")}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {Moment(item.date).format("HH:mm:ss")}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {item.currencySymbol}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {parseFloat(item.amount).toFixed(4)}
                                  </td>
                                  <td className="opt-term font-satoshi font_14 table_center_text">
                                    {item.txnid.substring(0, 10)} ...{" "}
                                    <i
                                      class="fa fa-clone"
                                      aria-hidden="true"
                                      onClick={() => copy(item.txnid)}
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </td>
                                  <td className="opt-term font-satoshi font_14 px-2 table_center_last text-success">
                                    Completed
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center py-5">
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../assets/No-data.png")}
                                      width="100px"
                                    />
                                  </div>
                                  <div className="no_records_text">
                                    No Records Found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                        </>
                      ) : (
                        <div className="row ">
                        <div>
                          <div className="identification_title deposit-flex">
                            <h5 className="deposit--title">Deposit</h5>
                            <Link to="" className="deposit--btn">
                              Fiat Deposit{" "}
                              <span>
                                <i class="fa-solid fa-chevron-right"></i>
                              </span>
                            </Link>
                          </div>
                        </div>
    
                        <div className="col-lg-7">
                          <div className="deposit-identity-box mt-5 h-100">
                            <div className="dep-kyc">
                              <div className="dep-kyc-head">
                                <img
                                  src={ICON}
                                  alt="warn-icon"
                                  className="deposit-imp-icon"
                                />
                                <h6>KYC Verification Required</h6>
                              </div>
                              <p>
                                You haven't completed the KYC verification process.
                                To ensure the security and compliance of our
                                platform, you must complete your KYC verification
                                before you can Deposit any crypto.
                              </p>
                              <div>
                                <img
                                  src={require("../assets/deposit-id.webp")}
                                  alt="Verify kyc"
                                  className="before_kyc_depo withdraw-p-l-24"
                                />
                              </div>
                              <p className="mt-4">
                                Please click the button below to verify your account
                              </p>
                              <div className="withdraw-p-l-24">
                                <Link to="/kyc">
                                  <button className="dep-kyc-btn  w-100 mb-2">
                                    Verify Now
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
    
                        <div className="col-lg-5">
                          <div>
                            <div className="container">
                              <div className="deposit-imp-notes mt-5 max-lg-t-3">
                                <div className="imp-notes-title">
                                  <span>
                                    <img
                                      src={ICON}
                                      alt="warn-icon"
                                      className="deposit-imp-icon"
                                    />
                                  </span>
                                  <p>Important Notes</p>
                                </div>
                                <div className="imp-notes-content">
                                  <h6>Double-check the deposit address</h6>
                                  <p>
                                    Ensure the deposit address provided is accurate.
                                    Sending funds to the wrong address may result in
                                    the loss of your assets.
                                  </p>
                                </div>
                                <div className="imp-notes-content">
                                  <h6>Verify deposit amount</h6>
                                  <p>
                                    Confirm the deposit amount and ensure it meets
                                    the minimum deposit requirements, if any.
                                  </p>
                                </div>
                                <div className="imp-notes-content">
                                  <h6>Security</h6>
                                  <p>
                                    Make sure your account is secure and that you
                                    are using a trusted network before proceeding
                                    with the deposit.
                                  </p>
                                </div>
                                <div className="imp-notes-content">
                                  <h6>Network compatibility</h6>
                                  <p>
                                    Ensure you are depositing on the correct
                                    network. Deposits made on an incompatible
                                    network may be lost, and Voltirx Crypt will not
                                    be able to recover or refund your funds.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                      )
                      :
                      
                   <div className="row ">
                    <div>
                      <div className="identification_title deposit-flex">
                        <h5 className="deposit--title">Deposit</h5>
                        <Link to="" className="deposit--btn">
                          Fiat Deposit{" "}
                          <span>
                            <i class="fa-solid fa-chevron-right"></i>
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <div className="deposit-identity-box mt-5 h-100">
                        <div className="dep-kyc">
                          <div className="dep-kyc-head">
                            <img
                              src={require("../assets/dep-warn.png")}
                              alt="warn-icon"
                              className="deposit-imp-icon"
                            />
                            <h6>Deposits Temporarily Unavailable</h6>
                          </div>
                          <p>
                            Due to ongoing platform maintenance, Deposits are
                            currently restricted. We apologize for any
                            inconvenience this may cause. Our team is working
                            diligently to restore full service as soon as
                            possible.
                          </p>
                          <p className="my-3">
                            Estimated Time to Resolution:{" "}
                            <span className="text-primary">00:00:00</span>
                          </p>
                          <div>
                            <img
                              src={require("../assets/dep-unavail.png")}
                              alt="Verify kyc"
                              className="before_kyc_depo withdraw-p-l-24"
                            />
                          </div>
                          <p className="mt-4">
                            Thank you for your patience and understanding.
                          </p>
                          <div className="withdraw-p-l-24">
                            <Link to="/dashboard">
                              <button className="dep-kyc-btn  w-100 mb-2">
                                Back To Home
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5">
                      <div>
                        <div className="container">
                          <div className="deposit-imp-notes mt-5 max-lg-t-3">
                            <div className="imp-notes-title">
                              <span>
                                <img
                                  src={ICON}
                                  alt="warn-icon"
                                  className="deposit-imp-icon"
                                />
                              </span>
                              <p>Important Notes</p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Double-check the destination address</h6>
                              <p>
                                Make sure the address you provided is correct.
                                Transactions cannot be reversed once completed.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Verify withdrawal details</h6>
                              <p>
                                Confirm the amount and fees before finalizing
                                the request.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Security</h6>
                              <p>
                                Ensure that your account is secure and that you
                                are using a trusted network.
                              </p>
                            </div>
                            <div className="imp-notes-content">
                              <h6>Network verification</h6>
                              <p>
                                Double-check your network for compatibility.
                                Transactions made on the wrong network cannot be
                                reversed, and Voltrix crypt will not be able to
                                recover or provide any amount.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 

                    }

                  </div>

                 

                  {/*/TODO: deposit - KYC Verification required box*/}

                  {/*/TODO: deposit - Temporary Unavailable */}

                 
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Dashboard;
