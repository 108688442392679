import React from "react";
import QR from "../assets/qr.png";
import Header from "./Header";

const EnableTFA = () => {
  return (
    <>
      <Header />{" "}
      <div>
        <div className="Verification">
          <div className="container">
            <div className="">
              <h6>
                {" "}
                <i class="fa-solid fa-arrow-left-long mr-3"></i> Security
              </h6>
              <div className="row justify-content-center cards ">
                <div className="col-lg-4">
                  <span class="heading">Enable 2FA</span>

                  <div className="notify">
                    {" "}
                    Protect your transactions and account security, keep your
                    login password safe and do not disclose it to others{" "}
                  </div>

                  <div className="input-groups qr">
                    <img src={QR} alt="QR" />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">2FA Code</h6>
                    <input
                      className="input-field"
                      placeholder="Enter the code"
                    />
                    <span className="textgreen eyeicons"> 56 s </span>
                  </div>

                  <div className="Submit my-4">
                    <button>Enable</button>
                  </div>

                  <div className="foot">
                    <p>
                      Didn't receive a code ? <a>Resend</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnableTFA;
