import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer_content_section">
      <div className="container bor-bottom">
        <div className="row">
          <div className="col-lg-6">
            <div className="footer_content">
              <img
                src={require("../assets/footer_logo.webp")}
                className="footer_light_logo"
              />
              <img
                src={require("../assets/fidex_dark_logo.webp")}
                className="footer_logo_dark"
              />
              <p>
                Our products, services, information and materials contained
                within these web pages may not <br /> be available for residents
                of certain jurisdictions. Please consult our Terms & Conditions
                or the <br /> sales restrictions relating to the products or
                services in question for further information.
              </p>
            </div>
          </div>

          <div className="col-lg-6 services_content">
            <div className="services">
              <h3>Services</h3>
              <li>
                <Link to="/staking">Staking</Link>
              </li>
              <li>
                <Link to="/deposit">Deposit</Link>
              </li>
              <li>
                <Link to="/Withdraw">Withdraw</Link>
              </li>
              <li>
                <Link to="/buycrypto">Buy Crypto</Link>
              </li>
              <li>
                <Link to="/trade/ETH_USDT">Trading</Link>
              </li>
              <li>
                <Link to="/swap">Convert</Link>
              </li>
            </div>
            <div className="services">
              <h3>Company</h3>
              <li>
                <Link to="">About Us</Link>
              </li>
              <li>
                <Link to="">Contact Us</Link>
              </li>
            </div>
            <div className="services">
              <h3>Support</h3>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="">Privacy Policy</Link>
              </li>
              <li>
                <Link to="">Terms and Conditions</Link>
              </li>
            </div>
            <div className="services">
              <h3>Socials</h3>
              <Link>
                <img src={require("../assets/twitter x.webp")} />
              </Link>
              <Link>
                <img src={require("../assets/Facebook.webp")} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">&copy; 2024 FIDEX. All Rights Reserved.</div>
    </footer>
  );
};

export default Footer;
