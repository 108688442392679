import React, { useEffect } from "react";
import Header from "./Header";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";

const Changepassword = () => {
  const [loaderButton, setloaderButton] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    fetchTfaData();
  }, []);

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      setTfaDetails(resp.data.tfastatus);
      setaddDetails(resp.data);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      showerrorToast("2FA code is required");
    }else if (tfaCode.length !== 6) {
    showerrorToast('Number must be exactly 6 digits');
  }else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        showsuccessToast(resp.Message);
        setTfaCode("");
        fetchTfaData();
        localStorage.setItem("tfa_status", resp.result.tfastatus);
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
        showerrorToast(resp.Message);
      }
    }
  };
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Secret Key Copied");
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="anti_phishing_main">
          <div>
            <div className="Verification ">
              <div className="container">
                <div>
                  <Link to="/security">
                    <h6>
                      <i class="fa-solid fa-arrow-left-long mr-3"></i> Security
                    </h6>
                  </Link>
                  <div className="row justify-content-center cards">
                    <div className="col-lg-4">
                      {tfaDetails === 0 ? (
                        <span class="heading">Enable 2FA</span>
                      ) : (
                        <span class="heading">Disable 2FA</span>
                      )}

                      <div className="notify">
                        {" "}
                        Protect your transactions and account security, keep
                        your login password safe and do not disclose it to
                        others
                      </div>
                      {tfaDetails === 0 ? (
                        <div className="two_fa_qr">
                          <div className="two_fa_qr">
                            <img src={addDetails.tfa_url} />
                            <p className="font-satoshi">Scan the QR code</p>
                          </div>
                          <div className="input-groups-tfa">
                            <div className="flex_input_posion">
                              <input
                                type="text"
                                value={
                                  addDetails.tfaenablekey == undefined
                                    ? ""
                                    : addDetails.tfaenablekey
                                }
                                readOnly
                                className="input-field"
                              />
                              <i
                                class="fa-regular fa-copy eyeicons-tfa "
                                id="Eye"
                                onClick={() => copy(addDetails.tfaenablekey)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="input-groups icons">
                        <h6 className="input-label">2FA Code</h6>
                        <input
                          type="text"
                          id="numberInput"
                          min="0"
                          maxLength={6}
                          name="tfaCode"
                          value={tfaCode}
                          onChange={(e) => setTfaCode(e.target.value)}
                          className="input-field"
                          placeholder="Enter the code"
                        />
                        {/* <span className="textgreen eyeicons"> 56 s </span> */}
                      </div>

                      <div className="Submit my-4">
                        {tfaDetails === 0 ? (
                          loaderButton == false ? (
                            <button onClick={handleSubmit}>Enable</button>
                          ) : (
                            <button>loading...</button>
                          )
                        ) : loaderButton == false ? (
                          <button onClick={handleSubmit}>Disable</button>
                        ) : (
                          <button>loading...</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Changepassword;
