import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

const FP = () => {
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [OTP, setOTP] = useState("");
  const [activeStatus, seractiveStatus] = useState(false);

  const navigate = useNavigate();

  const initialFormValue = {
      email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
};

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
  useState(false);
const [formValue1, setFormValue1] = useState(initialFormValue1);
const [
  confirmPasswordValidate,
  setconfirmPasswordValidate,
  confirmPasswordValidateref,
] = useState(false);
const [validationnErr1, setvalidationnErr1] = useState("");
const [passHide, setPasshide] = useState(false);
const [inputType, setinputType] = useState("password");
const [passHidconf, setPasshideconf] = useState(false);
const [inputTypeconf, setinputTypeconf] = useState("password");

  const { email } = formValue;
  const { password, confirmPassword } = formValue1;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
};

const handleChange1 = async (e) => {
  e.preventDefault();
  const { name, value } = e.target;
  let formData1 = { ...formValue1, ...{ [name]: value } };
  setFormValue1(formData1);
  validate1(formData1);
};

const validate = async (values) => {
  const errors = {};
  if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
  } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
  ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
  } else if (
      !/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
          values.email
      )
  ) {
      errors.email =
          "Invalid email address! Email must contain at least one character.";
      setemailValidate(true);
  } else if (values.email.length > 254) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
  } else {
      setemailValidate(false);
  }
  setvalidationnErr(errors);
  return errors;
};

const validate1 = async (values) => {
  const errors1 = {};

  if (!values.password) {
      errors1.password = "Password is a required field";
      setpasswordValidate(true);
  } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors1.password = "Password should not below 5 above 25 letters !";
  } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least lower character !";
  } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least upper character !";
  } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at One digit character !";
  } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one special character !";
  } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors1.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
  } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
  ) {
      errors1.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
  } else {
      setconfirmPasswordValidate(false);
  }

  setvalidationnErr1(errors1);
  return errors1;
};

const passwordHide = (data) => {
  if (data == "hide") {
      setPasshide(true);
      setinputType("text");
  } else {
      setPasshide(false);
      setinputType("password");
  }
};

const passwordHideconf = (data) => {
  if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
  } else {
      setPasshideconf(false);
      setinputTypeconf("password");
  }
};

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter === 0 && isEmailSubmitted) {
      setIsResendVisible(true);
    }
    return () => clearTimeout(timer);
  }, [counter, isEmailSubmitted]);

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    validate(formValue);
    try {
      if (formValue.email != "" && emailValidateref.current == false) {
        var obj = {
            email: formValue.email,
        };
        var data = {
            apiUrl: apiService.forgotemailotp,
            payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        console.log(resp, "==-=-resp");
        setbuttonLoader(false);
        if (resp.status == true) {
          showSuccessToast(resp.Message);
            setIsEmailSubmitted(true);
            setCounter(60);
            setIsResendVisible(false);
        } else {
            showErrorToast(resp.message);
        }
    }
    } catch (error) {
      setbuttonLoader(false);
    }
   
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    console.log("Verification code submitted:");
    try {
      if (OTP !== "") {
        console.log(OTP, "otp-=-=-");
        var obj = {
          emailOtp: OTP,
          email: formValue.email,
        };

        var data = {
          apiUrl: apiService.forgototpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          showSuccessToast(resp.Message);
          seractiveStatus(true);
        } else {
          showErrorToast(resp.Message);
        }
      } else {
        showErrorToast("Enter OTP");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleResend = () => {
    setCounter(60);
    setIsResendVisible(false);
    // Add logic to resend the OTP here
    console.log('OTP resent');
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (
        passwordValidateref.current === false &&
        confirmPasswordValidateref.current === false
    ) {
        var obj = {
            password: formValue1.password,
            confimPassword: formValue1.confirmPassword,
            email: formValue.email ,
        };

        console.log(obj, "=-=-=-=-=-=-=-==-=");
        var data = {
            apiUrl: apiService.resetpassword,
            payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        setFormValue1(initialFormValue1);
        if (resp.status == true) {
            showSuccessToast(resp.Message);
            navigate("/login");
        } else {
            showErrorToast(resp.Message);
        }
    }
};

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>
      <div>
        <div className="Verification">
          <div className="container">
            <div>
              <Link to="/login">
                <h6>
                  <i className="fa-solid fa-arrow-left-long mr-3"></i> Login
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                {activeStatus == false ?  (
                  <>
                      <span className="heading">Forget Password</span>
                   {!isEmailSubmitted ? (
                       <div className="notify">
                       Enter your email address to verify by sending an OTP.
                       </div>
                    ) : (
                      <div className="notify">
                      We have sent you a verification code to the registered email address
                      </div>
                    )}
                 
                    <div className="input-groups icons">
                      <h6 className="input-label">Email</h6>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className="input-field"
                        placeholder="Enter the Email"
                      />
                       {emailValidateref.current == true ? (
                            <p className="errorcss"> {validationnErr.email} </p>
                        ) : (
                            ""
                        )}
                    </div>
                    {isEmailSubmitted && (
                      <div className="input-groups icons">
                        <h6 className="input-label">Verification code</h6>
                        <input
                           type="number"
                           name="OTP"
                           min={1000}
                           max={9999}
                           value={OTP}
                           onKeyDown={(evt) =>
                             ["e", "E", "+", "-"].includes(evt.key) &&
                             evt.preventDefault()
                           }
                           onChange={(e) => setOTP(e.target.value)}
                          className="input-field"
                          placeholder="Enter the code"
                        />
                       
                      </div>
                    )}
                           <div className="Submit my-4">
                    {!isEmailSubmitted ? (
                      <>
                      {buttonLoader == false ? (
                      <button onClick={handleEmailSubmit}>Submit</button>
                      ) : (
                        <button>Loading ...</button>
                      )}
                      </>
                    ) : (
                      <>
                       {buttonLoader == false ? (
                      <button onClick={handleResetPassword}>Reset Password</button>
                      ) : (
                        <button>Loading ...</button>
                      )}
                      </>
                    )}
                  </div>
                  {isEmailSubmitted && (
                    <div className="foot">
                      <p>
                        Didn't receive a code?{' '}
                        {isResendVisible ? (
                          <span onClick={handleResend} className='cursor-pointer'>
                           <a> Resend</a>
                            </span>
                        ) : (
                          <span className="textgreen">
                            {counter}s
                          </span> 
                        )}
                      </p>
                    </div>
                  )}
                  </>
                ) : (
                  <>
                  <span className="heading">Reset Password</span>
                  
                  <div className="notify">
                    Continue to create your new password 
                  </div>
                    <div className="input-groups">
                    <h6 className="input-label"> New Password</h6>
                    <div className="flex_input_posion">
                    <input
                        type={inputType}
                        name="password"
                        value={password}
                        minLength={6}
                        maxLength={15}
                        onChange={handleChange1}
                          className="input-field"
                          placeholder="Enter new Password"
                        />
                        {passHide == true ? (
                              <i class="fa-regular fa-eye eyeicons" onClick={() => passwordHide("show")}></i>
                            
                          ) : (
                              <i class="fa-regular fa-eye-slash eyeicons" onClick={() => passwordHide("hide")}></i>
                          )}
                    </div>
                    {passwordValidate == true ? (
                          <p className="errorcss">
                              {" "}
                              {validationnErr1.password}{" "}
                          </p>
                      ) : (
                          ""
                      )}
                  </div>
                  <div className="input-groups">
                        <h6 className="input-label">Confirm New Password</h6>
                        <div className="flex_input_posion">
                        <input
                         type={inputTypeconf}
                         name="confirmPassword"
                         value={confirmPassword}
                         minLength={6}
                         maxLength={15}
                         onChange={handleChange1}
                          className="input-field"
                          placeholder="Re-Enter the Password"
                        />
                         {passHidconf == true ? (
                                <i class="fa-regular fa-eye eyeicons" onClick={() => passwordHideconf("show")}></i>
                          ) : (
                              <i class="fa-regular fa-eye-slash eyeicons" onClick={() => passwordHideconf("hide")}></i>
                          )}
                          </div>
                          {confirmPasswordValidate == true ? (
                                <p className="errorcss">
                                    {" "}
                                    {validationnErr1.confirmPassword}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                        <div className="Submit my-4" onClick={formSubmitchange}>
                        {buttonLoader == false ? (
                            <button>Reset Password</button>
                        ) : (
                          <button>Loading ...</button>
                        )}
                  </div>
                  </>
                )}
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FP;
