import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function Register() {
  const [referalGet, setReferalGet] = useState("");

  useEffect(() => {
    let str = window.location.href;
    const letters = str.includes("?");
    if (letters) {
      console.log(
        window.location.href.split("=")[1],
        "window.location.href",
        letters
      );
      // showSuccessToast("Yeah it comes");
      setReferalGet(window.location.href.split("=")[1]);
      let locationData = window.location.href.split("=")[1];
      let formData = { ...formValue, ...{ referral_code: locationData } };
      setFormValue(formData);
    } else {
      console.log("asfasdfasdfasdfasdfsd");
    }
  }, []);

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const initialFormValue = {
    name: "",
    email: "",
    phonenumber: "",
    businesstype: "",
    businessname: "",
    incorporationplace: "",
    password: "",
    confirmPassword: "",
    referral_code: "",
  };

  const options = countryList().getData();

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate, nameValidateref] = useState(false);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [phoneNumberValidate, setPhoneNumberValidate, phoneNumberValidateref] =
    useState(false);
  // const [
  //   businessTypeValidate,
  //   setBusinessTypeValidate,
  //   businessTypeValidateref,
  // ] = useState(false);

  // new business type
  const [
    businessTypeValidate,
    setBusinessTypeValidate,
    businessTypeValidateref,
  ] = useState(false);

  const [
    businessNameValidate,
    setBusinessNameValidate,
    businessNameValidateref,
  ] = useState(false);

  const [
    corporationPlaceValidate,
    setCorporationPlaceValidate,
    corporationPlaceValidateref,
  ] = useState(false);

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);

  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);

  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");

  const [registerType, setRegisterType, registerTyperef] =
    useState("Individuals");

  const {
    name,
    email,
    phonenumber,
    businesstype,
    businessname,
    incorporationplace,
    password,
    confirmPassword,
    referral_code,
  } = formValue;

  const businessOptions = [
    { value: "Operating company", label: "Operating company" },
    { value: "Non-operating company", label: "Non-operating company" },
    { value: "External assest manager", label: "External assest manager" },
    { value: "Family office", label: "Family office" },
    { value: "Financial intermediary", label: "Financial intermediary" },
    { value: "Broker dealers", label: "Broker dealers" },
    { value: "Trust", label: "Trust" },
    { value: "Broker dealers", label: "Broker dealers" },
    { value: "Foundation", label: "Foundation" },
    { value: "VARA/VASP", label: "VARA/VASP" },
    { value: "Crypto exchange", label: "Crypto exchange" },
  ];

  const navigate = useNavigate();

  const createUid = uuidv4();
  const createdUuid = createUid.split("-")[0].toString();

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    if (name === "phonenumber") {
      sanitizedValue = sanitizedValue.replace(/\D/g, "").slice(0, 10);
    }
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handleSelectChange = (selectedOption) => {
    setFormValue({ ...formValue, incorporationplace: selectedOption.value });
    validate({ ...formValue, incorporationplace: selectedOption.value });
  };

  // New business Type
  const handleBusinessChange = (selectedOption) => {
    setFormValue({ ...formValue, businesstype: selectedOption.value });
    validate({ ...formValue, businesstype: selectedOption.value });
  };

  const handlePhoneChange = (value) => {
    setFormValue({ ...formValue, phonenumber: value });
    validate({ ...formValue, phonenumber: value });
  };

  const typeChange = (value) => {
    setRegisterType(value);
    setFormValue(initialFormValue);
    setnameValidate(false);
    setemailValidate(false);
    setPhoneNumberValidate(false);
    // setBusinessTypeValidate(false);
    setBusinessTypeValidate(false);
    setBusinessNameValidate(false);
    setCorporationPlaceValidate(false);
    setpasswordValidate(false);
    setconfirmPasswordValidate(false);
    setIschecked(false);
    setTerms(false);
    setTermsValidation(false);
    setvalidationnErr("");
    console.log(value, "-=value=-");
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };

  const validate = (values) => {
    let errors = {};
    const username = values.email.split("@")[0];

    // if (registerTyperef.current == "Corporates") {
      if (!values.name) {
        errors.name = "Name is a required field !";
        setnameValidate(true);
      } else if (!/^[a-zA-Z]/.test(values.name)) {
        errors.name = "Username must start with a letter !";
        setnameValidate(true);
      } else if (values.name.length < 3 || values.name.length > 25) {
        errors.name = "Name must have an  3 to 25 characters !";
        setnameValidate(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(values.name)) {
        errors.name =
          "Username can only contain letters, numbers, and underscores !";
        setnameValidate(true);
      }else
      //  else {
      //   setnameValidate(false);
      // }
    // }

    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (username.length < 4 || username.length > 30) {
      errors.email = "Email username must be between 4-30 characters long";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email =
        " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setemailValidate(true);
    } else if (values.email.length > 50) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    }else
    //  else {
    //   setemailValidate(false);
    // }

    if (values.phonenumber == "") {
      setPhoneNumberValidate(true);
      errors.phonenumber = "Phone number is required !";
    } else if (values.phonenumber.length < 5) {
      errors.phonenumber = "Phone number should not below 5 numbers !";
      setPhoneNumberValidate(true);
    } else
    // else {
    //   setPhoneNumberValidate(false);
    // }

    if (registerTyperef.current == "Corporates") {
      // if (values.businesstype == "") {
      //   setBusinessTypeValidate(true);
      //   errors.businesstype = "Business type is required !";
      // } else {
      //   setBusinessTypeValidate(false);
      // }

      // new business type
      if (values.businesstype == "") {
        setBusinessTypeValidate(true);
        errors.businesstype = "Business Type is required !";
      }else
      //  else {
      //   setBusinessTypeValidate(false);
      // }

      if (values.businessname == "") {
        setBusinessNameValidate(true);
        errors.businessname = "Business name is required !";
      } else
      // else {
      //   setBusinessNameValidate(false);
      // }
      if (values.incorporationplace == "") {
        setCorporationPlaceValidate(true);
        errors.incorporationplace = "Incorporation place is required !";
      } 
      else {
        setCorporationPlaceValidate(false);
        setBusinessNameValidate(false);
        setBusinessTypeValidate(false);
      }
    }else
    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required !";
    } else if (values.password.length < 5 || values.password.length > 15) {
      setpasswordValidate(true);
      errors.password = "Password should not below 5 above 15 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    }else
    //  else {
    //   setpasswordValidate(false);
    // }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else
    // else {
    //   setconfirmPasswordValidate(false);
    // }

    if (!Terms) {
      errors.terms = "Terms is a required field ";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setTermsValidation(false);
      setconfirmPasswordValidate(false);
      setpasswordValidate(false);
      setPhoneNumberValidate(false);
      setemailValidate(false);
      setnameValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    console.log(formValue, "=-=-=-formvalue=-=-");
    let errros = validate(formValue);
    console.log(registerTyperef.current, "reg type current");

    console.log(errros, "errros---");

    formValue["UUID"] = createdUuid;
    if (registerTyperef.current === "Individuals") {
      if (
        emailValidateref.current === false &&
        phoneNumberValidateref.current === false &&
        passwordValidateref.current === false &&
        confirmPasswordValidateref.current === false &&
        Terms == true
      ) {
        formValue["registertype"] = "Individuals";
        console.log(formValue, "formvalue11111");
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setFormValue(initialFormValue);
        localStorage.setItem("useremail", resp.email);
        setbuttonLoader(false);
        if (resp.status) {
          showSuccessToast(resp.Message);
          navigate("/verification");

          localStorage.setItem("registe_type", registerTyperef.current);
        } else {
          showErrorToast(resp.Message);
        }
      } else {
      }
    } else {
      if (
        nameValidateref.current === false &&
        emailValidateref.current === false &&
        phoneNumberValidateref.current === false &&
        // businessTypeValidateref.current === false &&
        businessTypeValidateref.current === false &&
        businessNameValidateref.current === false &&
        corporationPlaceValidateref.current === false &&
        passwordValidateref.current === false &&
        confirmPasswordValidateref.current === false &&
        Terms == true
      ) {
        formValue["registertype"] = "Corporates";
        console.log(formValue, "formvalue22222");
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setFormValue(initialFormValue);
        localStorage.setItem("useremail", resp.email);

        setbuttonLoader(false);
        if (resp.status) {
          showSuccessToast(resp.Message);
          navigate("/verification");
          localStorage.setItem("registe_type", registerTyperef.current);
        } else {
          showErrorToast(resp.Message);
        }
      } else {
      }
    }
  };

  return (
    <>
      <div>
        <Header />
        <div className="register">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-lg-4">
                <div className="register-left">
                  <img src={require("../assets/register.webp")} />
                  <h4>Trade and Earn Rewards !</h4>
                  <h5>
                    Get rewarded for every trade. Sign Up or Sign In now to{" "}
                    <br /> unlock your potential and start earning more!
                  </h5>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="register-card">
                  <span class="heading">Sign up to FIDEX</span>

                  {/* toggle buttons (email and phone)*/}
                  <div class="nav nav-tabs mt-2" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={() => typeChange("Individuals")}
                    >
                      Individuals
                    </button>
                    <button
                      class="nav-link "
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => typeChange("Corporates")}
                    >
                      Corporates
                    </button>
                  </div>

                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      tabindex="0"
                    >
                      <div className="input-groups">
                        <h6 className="input-label">Name</h6>
                        <input
                          type="text"
                          name="name"
                          minLength={3}
                          maxLength={25}
                          value={name}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the Name"
                        />
                        {validationnErr && validationnErr.name && (
                          <p className="errorcss">{validationnErr.name}</p>
                        )}
                      </div>
                      <div className="input-groups ">
                        <h6 className="input-label">Email</h6>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the Email"
                        />
                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">Phone Number</h6>
                        <PhoneInput
                          country={"us"}
                          value={phonenumber}
                          onChange={handlePhoneChange}
                          inputProps={{
                            name: "phonenumber",
                            required: true,
                            className: "input-phone",
                          }}
                        />
                        {/* <input
                          type="number"
                          name="phonenumber"
                          value={phonenumber}
                          onChange={handleChange}
                          maxLength={10}
                          pattern="\d*"
                          className="input-field"
                          placeholder="Enter the Phone Number"
                        /> */}
                        {validationnErr && validationnErr.phonenumber && (
                          <p className="errorcss">
                            {validationnErr.phonenumber}
                          </p>
                        )}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">Password</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            minLength={6}
                            maxLength={15}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Enter the Password"
                          />
                          {passHide == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.password && (
                          <p className="errorcss">{validationnErr.password}</p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">Confirm Password</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={6}
                            maxLength={15}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Re-Enter the Password"
                          />
                          {passHidconf == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.confirmPassword && (
                          <p className="errorcss">
                            {validationnErr.confirmPassword}
                          </p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">Referral ID</h6>
                        <input
                          type="text"
                          name="referral_code"
                          maxLength={15}
                          value={referral_code}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the referral ID (optional)"
                        />
                      </div>
                      <div className="terms">
                        <input
                          className="input-field"
                          type="checkbox"
                          id="cb1"
                          value={isChecked}
                          name="check"
                          onChange={handleTerms}
                        />
                        <div className="custom-check"></div>
                        <p>
                          I agree to the <span>terms</span> and{" "}
                          <span>condition</span>
                        </p>
                      </div>
                      {termsValidation ? (
                        <p className="errorcss">
                          Terms and Conditions are required
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="Submit" onClick={formSubmit}>
                        {buttonLoader == false ? (
                          <button>Submit</button>
                        ) : (
                          <button>Loading ...</button>
                        )}
                      </div>

                      <div className="foot">
                        <p>
                          Already have a account ?{" "}
                          <Link to="/login"> Sign in</Link>
                        </p>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                      tabindex="0"
                    >
                      {/* corporate name */}
                      <div className="input-groups">
                        <h6 className="input-label">Name</h6>
                        <input
                          type="text"
                          name="name"
                          minLength={3}
                          maxLength={25}
                          value={name}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the Name"
                        />
                        {validationnErr && validationnErr.name && (
                          <p className="errorcss">{validationnErr.name}</p>
                        )}
                      </div>
                      {/* corporate email  */}
                      <div className="input-groups">
                        <h6 className="input-label">Email</h6>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          minLength={3}
                          maxLength={250}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the Email"
                        />
                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}
                      </div>
                      {/* cor-phone */}
                      <div className="input-groups">
                        <h6 className="input-label">Phone No.</h6>
                        <PhoneInput
                          country={"us"}
                          value={phonenumber}
                          onChange={handlePhoneChange}
                          inputProps={{
                            name: "phonenumber",
                            required: true,
                            className: "input-phone",
                          }}
                        />
                        {/* <div className="select-business">
                          <input
                            className="input-field phone_input"
                            placeholder="Enter the Number"
                          />
                          <span className="set-phoneIcon">
                            <div className="set-phone-no">+91 </div>
                            <i class="fa-solid fa-caret-down"></i>
                          </span>
                        </div> */}
                        {validationnErr && validationnErr.phonenumber && (
                          <p className="errorcss">
                            {validationnErr.phonenumber}
                          </p>
                        )}
                      </div>
                      {/* Old business type */}
                      {/* <div className="input-groups select-wrapper">
                        <h6 className="input-label">Business Type</h6>
                        <select
                          name="businesstype"
                          value={businesstype}
                          onChange={handleChange}
                          className="input-field"
                        >
                          <option value="" disabled className="disabled_text">
                            Please Select
                          </option>
                          <option value="Operating company">
                            Operating company
                          </option>
                          <option value="Non-operating company">
                            Non-operating company
                          </option>
                          <option value="External assest manager">
                            External assest manager
                          </option>
                          <option value="Family office">Family office</option>
                          <option value="Financial intermediary">
                            Financial intermediary
                          </option>
                          <option value="Broker dealers">Broker dealers</option>
                          <option value="Trust">Trust</option>
                          <option value="Foundation">Foundation</option>
                          <option value="VARA/VASP">VARA/VASP</option>
                          <option value="Crypto exchange">
                            Crypto exchange
                          </option>
                        </select>
                        <i class="fa-solid fa-caret-down select-arrow"></i>
                      </div>
                      {validationnErr && validationnErr.businesstype && (
                        <p className="errorcss mt-1">
                          {validationnErr.businesstype}
                        </p>
                      )} */}

                      {/* New Business Type */}
                      <div className="input-groups select-wrapper">
                        <h6 className="input-label mb-2">Business Type</h6>
                        <Select
                          options={businessOptions}
                          value={options.find(
                            (option) => option.value === businesstype
                          )}
                          onChange={(selectedOption) =>
                            handleBusinessChange(selectedOption)
                          }
                          classNamePrefix="custom-select"
                          placeholder="Please Select"
                          styles={colourStyles}
                        />

                        <i class="fa-solid fa-caret-down select-arrow"></i>
                      </div>

                      {validationnErr && validationnErr.businesstype && (
                        <p className="errorcss mt-1">
                          {validationnErr.businesstype}
                        </p>
                      )}

                      {/* business name */}
                      <div className="input-groups">
                        <h6 className="input-label">Business Name</h6>
                        <input
                          type="text"
                          name="businessname"
                          value={businessname}
                          minLength={3}
                          maxLength={250}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the Business Name"
                        />
                        {validationnErr && validationnErr.businessname && (
                          <p className="errorcss">
                            {validationnErr.businessname}
                          </p>
                        )}
                      </div>
                      {/* place of incorporation */}
                      <div className="input-groups select-wrapper">
                        <h6 className="input-label mb-2">
                          Place of Incorporation
                        </h6>
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === incorporationplace
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange(selectedOption)
                          }
                          classNamePrefix="custom-select"
                          placeholder="Select"
                          styles={colourStyles}
                        />
                        <i class="fa-solid fa-caret-down select-arrow"></i>
                        {/* <div className="select-business">
                          <input
                            className="input-field"
                            placeholder="Please Select"
                          />
                          <span className="set-icon">
                            <i class="fa-solid fa-caret-down"></i>
                          </span>
                        </div> */}
                      </div>
                      {validationnErr && validationnErr.incorporationplace && (
                        <p className="errorcss mt-1">
                          {validationnErr.incorporationplace}
                        </p>
                      )}
                      <div className="input-groups icons">
                        <h6 className="input-label">Password</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            minLength={6}
                            maxLength={60}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Enter the Password"
                          />
                          {passHide == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.password && (
                          <p className="errorcss">{validationnErr.password}</p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups icons">
                        <h6 className="input-label">Confirm Password</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={6}
                            maxLength={60}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Re-Enter the Password"
                          />
                          {passHidconf == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.confirmPassword && (
                          <p className="errorcss">
                            {validationnErr.confirmPassword}
                          </p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">Referral ID</h6>
                        <input
                          type="text"
                          name="referral_code"
                          maxLength={6}
                          value={referral_code}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the referral ID (optional)"
                        />
                      </div>
                      <div className="terms">
                        <input
                          className="input-field"
                          type="checkbox"
                          id="cb1"
                          value={isChecked}
                          name="check"
                          onChange={handleTerms}
                        />
                        <div className="custom-check"></div>
                        <p>
                          I agree to the <span>terms</span> and{" "}
                          <span>condition</span>
                        </p>
                      </div>
                      {termsValidation ? (
                        <p className="errorcss">
                          Terms and Conditions are required
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="Submit" onClick={formSubmit}>
                        {buttonLoader == false ? (
                          <button>Submit</button>
                        ) : (
                          <button>Loading ...</button>
                        )}
                      </div>

                      <div className="foot">
                        <p>
                          Already have a account ?{" "}
                          <Link to="/login">Sign in</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
