import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [selectCheck, setSelectCheck] = useState(false);
  const [selectCompCheck, setSelectCompCheck] = useState(false);
  const [selectProof, setSelectProof] = useState(false);
  const [isShowStatement, setIsShowStament] = useState(false);
  const [proofchoosingName, setproofchoosingName] = useState();
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const changeHandler = (value) => {
    setValue(value);
  };

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  // checkbox button functions
  const handleCheck = () => {
    setSelectCheck((prevCheck) => !prevCheck);
  };
  const handleCheckComp = () => {
    setSelectCompCheck((prevCheck) => !prevCheck);
  };
  const handleCheckProof = () => {
    setSelectProof((prevCheck) => !prevCheck);
  };
  const handleShowStatement = (value) => {
    console.log(value, "value");
    setIsShowStament(!isShowStatement);
    setproofchoosingName(value);
    setSelectProof(false);
  };

  // AUTHOR OF JOTHI

  const registrationOptions = [
    { value: "Corporation", label: "Corporation" },
    {
      value: "ChildLimited Liability Corporation (LLC)",
      label: "ChildLimited Liability Corporation (LLC)",
    },
    { value: "Non-Profit", label: "Non-Profit" },
    { value: "Partnership", label: "Partnership" },
    { value: "Sole Proprietorship", label: "Sole Proprietorship" },
    { value: "Trust", label: "Trust" },
    {
      value: "Unincorporated Association",
      label: "Unincorporated Association",
    },
  ];

  const documentOptions = [
    { value: "Utility Bill", label: "Utility Bill" },
    { value: "Bank Statement ", label: "Bank Statement " },
    { value: "Credit Card Statement", label: "Credit Card Statement" },
    {
      value: "Proof of Residency Certificate",
      label: "Proof of Residency Certificate",
    },
  ];

  const addressProofOptions = [
    { value: "Bank Statement", label: "Bank Statement" },
    { value: "Credit Card Statement", label: "Credit Card Statement" },
    { value: "Utility Bill", label: "Utility Bill" },
    { value: "Salary Slip", label: "Salary Slip" },
    { value: "Insurance Statement", label: "Insurance Statement" },
    { value: "Tax Document", label: "Tax Document" },
    { value: "Residence Certificate", label: "Residence Certificate" },
  ];
  

  useEffect(() => {
    getStep();
  }, [0]);

  const initialFormValue = {
    registrationtype: "",
    registrationnumber: "",
    Dateofformation: "",
    Country: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validcorporateFormationproof, setvalidcorporateFormationproof] =
    useState(0);
  const [corporateFormationproofLoad, setcorporateFormationproofLoad] =
    useState(false);
  const [
    corporateFormationproof,
    setcorporateFormationproof,
    corporateFormationproofref,
  ] = useState("");
  const [
    corporateFormationproofname,
    setcorporateFormationproofname,
    corporateFormationproofnameref,
  ] = useState("");
  const [validcompanyproof, setvalidcompanyproof] = useState(0);
  const [companyproofLoad, setcompanyproofLoad] = useState(false);
  const [companyproof, setcompanyproof, companyproofref] = useState("");
  const [companyproofname, setcompanyproofname, companyproofnameref] =
    useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofup, setaddressProof, addressProofref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");
  const [addressProofLoad, setaddressProofLoad] = useState(false);
  const { registrationtype, registrationnumber, Dateofformation, Country } =
    formValue;

  const [
    registrationtypevallidate,
    setregistrationtypevallidate,
    registrationtypevallidateref,
  ] = useState("");
  const [
    registrationtypeErrMsg,
    setregistrationtypeErrMsg,
    registrationtypeErrMsgref,
  ] = useState("");
  const [regnumErrMsg, setregnumErrMsg, regnumErrMsgref] = useState("");
  const [regnumvallidate, setregnumvallidate, regnumvallidateref] =
    useState("");
  const [dofvallidate, setdofvallidate, dofvallidateref] = useState("");
  const [dofErrMsg, setdofErrMsg, dofErrMsgref] = useState("");
  const [countryErrMsg, setcountryErrMsg, countryErrMsgref] = useState("");
  const [countryvallidate, setcountryvallidate, countryvallidateref] =
    useState("");
  const [companyproofErrMsg, setcompanyproofErrMsg, companyproofErrMsgref] =
    useState("");
  const [
    companyproofvallidate,
    setcompanyproofvallidate,
    companyproofvallidateref,
  ] = useState("");
  const [addressProofErrMsg, setaddressProofErrMsg, addressProofErrMsgref] =
    useState("");
  const [
    addressProofvallidate,
    setaddressProofvallidate,
    addressProofvallidateref,
  ] = useState("");
  const [corporateFormErrMsg, setcorporateFormErrMsg, corporateFormErrMsgref] =
    useState("");
  const [
    corporateFormvallidate,
    setcorporateFormvallidate,
    corporateFormvallidateref,
  ] = useState("");

  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      Country: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
  };

  const handletypeChange = (selectOption) => {
    const updatedFormValue = {
      ...formValue,
      registrationtype: selectOption.value,
    };
    setFormValue(updatedFormValue);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validation();
  };

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        type == "corporateFormation"
          ? setcorporateFormationproofLoad(true)
          : type == "companyShareRegistry"
          ? setcompanyproofLoad(true)
          : setaddressProofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "corporateFormation") {
              setcorporateFormationproofLoad(false);
              setvalidcorporateFormationproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidcorporateFormationproof(1);
              }
              setcorporateFormationproof(data.secure_url);
              setcorporateFormationproofname(val.name);
            }
            if (type == "companyShareRegistry") {
              setcompanyproofLoad(false);
              setvalidcompanyproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidcompanyproof(1);
              }
              setcompanyproofname(val.name);
              setcompanyproof(data.secure_url);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);
              setaddressProof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const handleAddressProofChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      addressProof: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };

  
  const validation = () => {
    try {
      if (formValue.registrationtype === "") {
        setregistrationtypeErrMsg("Register Type is a required field!");
        setregistrationtypevallidate(true);
      } else {
        setregistrationtypevallidate(false);
      }

      if (formValue.registrationnumber === "") {
        setregnumErrMsg("Register Number is a required field!");
        setregnumvallidate(true);
      } else {
        setregnumvallidate(false);
      }

      if (formValue.Dateofformation === "") {
        setdofErrMsg("Date is a required field!");
        setdofvallidate(true);
      } else {
        setdofvallidate(false);
      }

      if (formValue.Country === "") {
        setcountryErrMsg("Country is a required field!");
        setcountryvallidate(true);
      } else {
        setcountryvallidate(false);
      }

      if (companyproofref.current === "") {
        setcompanyproofErrMsg("Company Proof  is a required field!");
        setcompanyproofvallidate(true);
      } else {
        setcompanyproofvallidate(false);
      }

      if (addressProofref.current === "") {
        setaddressProofErrMsg("Address Proof  is a required field!");
        setaddressProofvallidate(true);
      } else {
        setaddressProofvallidate(false);
      }

      if (corporateFormationproofref.current === "") {
        setcorporateFormErrMsg("  Corporation Proof is a required field!");
        setcorporateFormvallidate(true);
      } else {
        setcorporateFormvallidate(false);
      }
    } catch (err) {}
  };


  const submit = async () => {
    validation();
    if (
      !registrationtypevallidateref.current &&
      !regnumvallidateref.current &&
      !dofvallidateref.current &&
      !countryvallidateref.current &&
      !companyproofvallidateref.current &&
      !addressProofvallidateref.current
    ) {
      formValue["verifiedSteps"] = "Step-2";
      formValue["corporateFormationproof"] = corporateFormationproofref.current;
      formValue["companyproof"] = companyproofref.current;
      formValue["addressProof"] = addressProofref.current;
      formValue["addressProofName"] = proofchoosingName;
      var data = {
        apiUrl: apiService.kybSecond,
        payload: formValue,
      };
      console.log(formValue, "lkjkljkl");
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        navigate("/KypInformation3");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
        formValue.registrationtype = kybData.registrationtype;
        formValue.registrationnumber = kybData.registrationnumber;
        formValue.Dateofformation = kybData.Dateofformation;
        formValue.Country = kybData.Country;
        formValue.verifiedSteps = kybData.verifiedSteps;
        formValue.corporateFormationproof = kybData.corporateFormationproof;
        if (kybData.companyproof) {
          setcompanyproof(kybData.companyproof);
          setvalidcompanyproof(2);
        }
        if (kybData.addressProof) {
          setcorporateFormationproof(kybData.corporateFormationproof);
          setvalidcorporateFormationproof(2);
        }
        if (kybData.addressProof) {
          setaddressProof(kybData.addressProof);
          setvalidaddressProof(2);
          setSelectProof(false);
          setIsShowStament(true)
        }
        setproofchoosingName(kybData.addressProofName)
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KypInformation1">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Verification
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">2</span>Business Registration
                    Information
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">Registration Type</h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={registrationOptions}
                        value={registrationOptions.find(
                          (option) => option.value === registrationtype
                        )}
                        onChange={handletypeChange}
                        placeholder="Select Type"
                        styles={colourStyles}
                      />
                    </div>
                    {registrationtypevallidateref.current && (
                      <p className="errorcss">
                        {registrationtypeErrMsgref.current}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">Registration number</h6>
                    <div className="input-field ref_input">
                      <input
                        type="text"
                        name="registrationnumber"
                        value={registrationnumber}
                        onChange={handleChange}
                        className=""
                        placeholder="Government-issued business registration or tax identification number"
                      />
                    </div>
                    {regnumvallidateref.current && (
                      <p className="errorcss">{regnumErrMsgref.current}</p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">Date of formation</h6>
                    <div className="input-field ref_input">
                      <input
                        type="text"
                        name="Dateofformation"
                        value={Dateofformation}
                        onChange={handleChange}
                        className=""
                        placeholder="Enter the date of formation"
                      />
                    </div>
                    {dofvallidateref.current && (
                      <p className="errorcss">{dofErrMsgref.current}</p>
                    )}
                  </div>
                  <div className="fiat_deposit_detail country_kyp_dropdown">
                    <h6 className="input-label">Country of formation</h6>
                    <div className="input-field ref_input kyc_country_dropdown">
                      <Select
                        options={options}
                        value={options.find(
                          (option) => option.value === Country
                        )}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        styles={colourStyles}
                      />
                    </div>
                  </div>
                  {countryvallidateref.current && (
                    <p className="errorcss">{countryErrMsgref.current}</p>
                  )}
                  <div className="input-groups icons">
                    <h6 className="input-label">Please attach your business</h6>
                  </div>

                  {/* cor-formation */}
                  <div className="driver_license_content">
                    {/* <input
                      id="check-corporate"
                      type="checkbox"
                      className="checkbox-round"
                      onClick={handleCheck}
                    /> */}
                    <label htmlFor="check-corporate" className="check-label">
                      Corporate Formation
                    </label>
                  </div>
                  {/* {selectCheck && ( */}
                  <div className="driver_license_doc">
                    <div className="upload">
                      <input
                        type="file"
                        name="image"
                        className="image_upload_kyc"
                        onChange={(e) =>
                          imageUpload("corporateFormation", e.target.files[0])
                        }
                      />

                      {corporateFormationproofLoad == false ? (
                        validcorporateFormationproof == 0 ? (
                          <>
                            <div className="inner_frst_display">
                              <i class="ri-upload-2-line"></i>
                              <p>Click to upload file</p>
                            </div>
                          </>
                        ) : validcorporateFormationproof == 1 ? (
                          <i class="bi bi-file-earmark-bar-graph"></i>
                        ) : (
                          <img
                            src={corporateFormationproofref.current}
                            className="up_im_past"
                            alt="National Id Front"
                          />
                        )
                      ) : (
                        <div className="inner_frst_display">
                          <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                        </div>
                      )}
                      {/* <i class="ri-upload-2-line"></i>
                        <p>Click to upload file</p> */}
                    </div>
                  </div>
                  {/* // )} */}

                  {corporateFormvallidateref.current && (
                    <p className="errorcss">{corporateFormErrMsgref.current}</p>
                  )}

                  {/* company share */}
                  <div className="driver_license_content">
                    {/* <input
                      id="check-comp"
                      type="checkbox"
                      class="checkbox-round"
                      onClick={handleCheckComp}
                    /> */}
                    <label htmlFor="check-comp" className="check-label">
                      Company Share Registry
                    </label>
                  </div>
                  {/* {selectCompCheck && ( */}
                  <div className="driver_license_doc">
                    <div className="upload">
                      <input
                        type="file"
                        name="image"
                        className="image_upload_kyc"
                        onChange={(e) =>
                          imageUpload("companyShareRegistry", e.target.files[0])
                        }
                      />

                      {companyproofLoad == false ? (
                        validcompanyproof == 0 ? (
                          <>
                            <div className="inner_frst_display">
                              <i class="ri-upload-2-line"></i>
                              <p>Click to upload file</p>
                            </div>
                          </>
                        ) : validcompanyproof == 1 ? (
                          <i class="bi bi-file-earmark-bar-graph"></i>
                        ) : (
                          <img
                            src={companyproofref.current}
                            className="up_im_past"
                            alt="National Id Front"
                          />
                        )
                      ) : (
                        <div className="inner_frst_display">
                          <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                        </div>
                      )}
                      {/* <i class="ri-upload-2-line"></i>
                        <p>Click to upload file</p> */}
                    </div>
                  </div>
                  {/* )} */}
                  {companyproofvallidateref.current && (
                    <p className="errorcss">{companyproofErrMsgref.current}</p>
                  )}

                  {/* proof */}
                  <div className="driver_license_content mb-4">
                    <label
                      htmlFor="check-proof"
                      className="check-label "
                    >
                      Proof of Business Operating Address
                    </label>
                  </div>
                  
                  <div className="driver_license_content">
                    <input
                      id="check-proof"
                      type="checkbox"
                      className={
                        addressProofref.current == ""
                          ? "checkbox-round"
                          : "checkbox-round-checked"
                      }
                    />
                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                      <Select
                        options={addressProofOptions}
                        onChange={handleAddressProofChange}
                        placeholder="Select  Operating Address"
                        value={addressProofOptions.find(
                          (option) =>
                            option.value === addressProofref.current
                        )}
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>
                  
                    <div className="driver_license_doc">
                      <div className="upload">
                        <input
                          type="file"
                          name="image"
                          className="image_upload_kyc"
                          onChange={(e) =>
                            imageUpload("addressProof", e.target.files[0])
                          }
                        />

                        {addressProofLoad == false ? (
                          validaddressProof == 0 ? (
                            <>
                              <div className="inner_frst_display">
                                <i class="ri-upload-2-line"></i>
                                <p>Click to upload file</p>
                              </div>
                            </>
                          ) : validaddressProof == 1 ? (
                            <i class="bi bi-file-earmark-bar-graph"></i>
                          ) : (
                            <img
                              src={addressProofref.current}
                              className="up_im_past"
                              alt="National Id Front"
                            />
                          )
                        ) : (
                          <div className="inner_frst_display">
                            <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                          </div>
                        )}

         {addressproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div className="">
                              <input
                                className="proofs_name"
                                disabled
                                value={addressproofnameref.current}
                              ></input>
                            </div>
                          )}
                      </div>
                    </div>

                  <>
                    {addressProofvallidateref.current && (
                      <p className="errorcss">
                        {addressProofErrMsgref.current}
                      </p>
                    )}
                  </>

                  <div className="Submit my-4" onClick={submit}>
                    <Link>
                      <button>Next</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
