import React from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";

const Anti = () => {
  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>
      <main className="anti_phishing_main">
        <div>
          <div className="Verification assets_main">
            <div className="container">
              <div>
                <Link to="/buycrypto">
                  {" "}
                  <h6>
                    {" "}
                    <i class="fa-solid fa-arrow-left-long mr-3"></i> Quick Buy
                  </h6>
                </Link>
                <div className="row justify-content-center cards">
                  <div className="col-lg-4">
                    <span class="heading">Fill The Details</span>

                    <div className="input-groups icons mt-4">
                      <h6 className="input-label">Card Number</h6>
                      <input
                        className="input-field"
                        placeholder="Enter the card number"
                      />
                    </div>
                    <div className="input-groups icons">
                      <h6 className="input-label">Card Name</h6>
                      <input
                        className="input-field"
                        placeholder="Enter the card name"
                      />
                    </div>

                    <div className="row">
                      <div className="input-groups col-lg-6 icons">
                        <h6 className="input-label">Expiry Date</h6>
                        <input
                          className="input-field"
                          placeholder="07-07-2024"
                        />
                      </div>
                      <div className="input-groups col-lg-6 icons">
                        <h6 className="input-label">CVV</h6>
                        <input
                          className="input-field"
                          placeholder="Enter the CVV Number"
                        />
                      </div>
                    </div>

                    <div className="Submit my-4">
                      <button>Confirm</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Anti;
