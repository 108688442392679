import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod,getMethod} from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [isShowOther, setIsShowOther] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  const handleDrop = () => {
    setIsShowDrop((prevDrop) => !prevDrop);
  };
  const handleOther = () => {
    setIsShowOther((prevDrop) => !prevDrop);
  };
  // AUTHOR OF JOTHI

  const Business = [
    { value: "Up to 25,000 euros", label: "Up to 25,000 euros" },
    {
      value: "From 25,001 to 100,000 euros",
      label: "From 25,001 to 100,000 euros",
    },
    {
      value: "From 100,001 to 250,000 euros",
      label: "From 100,001 to 250,000 euros",
    },
    { value: "More than 250,000 euros", label: "More than 250,000 euros" },
  ];

  const fundAccountslist = [
    { value: "Ordinary business profits", label: "Ordinary business profits" },
    { value: "Dividends", label: "Dividends" },
    { value: "Sale of assets", label: "Sale of assets" },
    { value: "Sale of properties", label: "Sale of properties" },
    { value: "Leasing", label: "Leasing" },
    { value: "Investments", label: "Investments" },
    { value: "Others", label: "Others" },
  ];

  useEffect (()=>{
    getStep();
  },[0]);
  
  const initialFormValue = {
    fundAccounts: "",
    outflowsFidex: "",
    InvestmentsfundAccounts: "",
    otherfundAccount: "",
    bussinessworth:""
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const handlefundingChange = (event) => {
    const { value, checked } = event.target;
    let updatetransaction;

    if (checked) {
      updatetransaction = [...formValue.fundAccounts, value];
    } else {
      updatetransaction = formValue.fundAccounts.filter(
        (item) => item !== value
      );
    }
    const updatedFormValue = {
      ...formValue,
      fundAccounts: updatetransaction,
    };
    setFormValue(updatedFormValue);
  };

  const handleotherfundAccountsChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      otherfundAccount: event.target.value,
    };
    setFormValue(updatedFormValue);
  };

  const handleInvestmentsChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      InvestmentsfundAccounts: event.target.value,
    };
    setFormValue(updatedFormValue);
  };

  const [fundAccountsErrMsg,setfundAccountsErrMsg,fundAccountsErrMsgref]=useState("");
  const [fundAccountsvallidate,setfundAccountsvallidate,fundAccountsvallidateref]=useState(false);
  const [bussinessworthErrMsg,setbussinessworthErrMsg,bussinessworthErrMsgref]=useState("");
  const [bussinessworthvallidate,setbussinessworthvallidate,bussinessworthvallidateref]=useState(false);


  const handleworthChange = (selectOption) => {
    const updatedFormValue = {
      ...formValue,
      bussinessworth: selectOption.value,
    };
    setFormValue(updatedFormValue);
  };

  const Vallidate = () => {
    try {
      if (formValue.fundAccounts === "") {
        setfundAccountsvallidate(true);
        setfundAccountsErrMsg("Fund Accounts is required field !");
      } else if (formValue.fundAccounts.includes("Others")) {
        if(formValue.otherfundAccount === ""){
          setfundAccountsvallidate(true);
          setfundAccountsErrMsg("Fund Accounts is required field !");
        }else {
          setfundAccountsvallidate(false);
          setfundAccountsErrMsg("");
        }
      } else if (formValue.fundAccounts.includes("Investments")) {
        if(formValue.InvestmentsfundAccounts === ""){
          setfundAccountsvallidate(true);
          setfundAccountsErrMsg("Fund Accounts is required field !");
        }else {
          setfundAccountsvallidate(false);
          setfundAccountsErrMsg("");
        }
      } else {
        setfundAccountsvallidate(false);
        setfundAccountsErrMsg("");
      }

      if (formValue.bussinessworth === "") {
        setbussinessworthvallidate(true);
        setbussinessworthErrMsg("Your Bussiness worth is required field !");
      }else{
        setbussinessworthvallidate(false)
        setbussinessworthErrMsg("");

      }
      
    } catch (err) {}
  };
  const submit = async (e) => {
    e.preventDefault();
    console.log(formValue);
    Vallidate();

    if (
      !bussinessworthvallidateref.current &&
      !bussinessworthErrMsgref.current 
      ){
      formValue["verifiedSteps"] = "Step-3";
      var data = {
        apiUrl: apiService.kybThird,
        payload: formValue,
      };
      console.log(formValue, "lkjkljkl");
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        navigate("/KypInformation4");
      } else {
        toast.error(resp.Message);
      }
      }else{
    Vallidate();

      }

  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
        formValue.fundAccounts = kybData.fundAccounts
        formValue.InvestmentsfundAccounts = kybData.InvestmentsfundAccounts
        formValue.otherfundAccount = kybData.otherfundAccount
        formValue.bussinessworth = kybData.bussinessworth
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KypInformation2">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Verification
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">3</span> Financial Information
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Net Worth of Business (EUR equivalent)
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        type="text"
                        options={Business}
                        value={Business.find(
                          (option) => option.value === formValue.bussinessworth
                        )}
                        onChange={handleworthChange}
                        placeholder="Select Type"
                        styles={colourStyles}
                      />
                    </div>

                    {bussinessworthvallidateref.current && (
                      <p className="errorcss">
                        {bussinessworthErrMsgref.current}
                      </p>
                    )}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">Source of funds for account</h6>

                    <div className="input-checkboxs">
                      {/* <ul>
                        <li className="checkbox-container">
                          <input
                            id="check-business"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-business">
                            {" "}
                            Ordinary business profits
                          </label>
                        </li>
                        <li className="checkbox-container">
                          <input
                            id="check-dividends"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-dividends"> Dividends</label>
                        </li>
                        <li className="checkbox-container">
                          <input
                            id="check-asset"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-asset">Sale of assets</label>
                        </li>
                        <li className="checkbox-container">
                          <input
                            id="check-sale"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-sale">
                            {" "}
                            Sale of properties
                          </label>
                        </li>
                        <li className="checkbox-container">
                          <input
                            id="check-lease"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-lease">Leasing</label>
                        </li>
                        <li className="checkbox-container">
                          <input
                            id="check-invest"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                            onClick={handleDrop}
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-invest">Investments</label>
                        </li>

                        {isShowDrop && (
                          <div className="input-field ref_input upload_icon kyc_country_dropdown">
                            <Select
                              type="text"
                              // options={}
                              options={options}
                              value={value}
                              onChange={changeHandler}
                              className=""
                              placeholder="Specify investment"
                              styles={colourStyles}
                            />
                          </div>
                        )}
                        <li className="checkbox-container">
                          <input
                            id="check-other"
                            type="checkbox"
                            className="regular_checkbox"
                            placeholder="Select your status"
                            onClick={handleOther}
                          />
                          <div className="custom-check"></div>
                          <label htmlFor="check-other">Others</label>
                        </li>

                        {isShowOther && (
                          <div className="input-field ref_input upload_icon kyc_country_dropdown">
                            <Select
                              type="text"
                              // options={}
                              options={options}
                              value={value}
                              onChange={changeHandler}
                              className=""
                              placeholder="Specify investment"
                              styles={colourStyles}
                            />
                          </div>
                        )}
                        {/* <Select
                            type="text"
                            // options={}
                            className=""
                            placeholder="Specify investment"
                          /> */}
                      {/* </ul> */}

                      <ul>
                        {fundAccountslist.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.fundAccounts.includes(
                                option.value
                              )}
                              onChange={handlefundingChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}

                        {formValue.fundAccounts.includes("Others") && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              Please funds for account{" "}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.otherfundAccount}
                                onChange={handleotherfundAccountsChange}
                                className=""
                                placeholder="please specify"
                              />
                            </div>
                          </div>
                        )}
                        {formValue.fundAccounts.includes("Investments") && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              Please funds for account{" "}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.InvestmentsfundAccounts}
                                onChange={handleInvestmentsChange}
                                className=""
                                placeholder="please specify"
                              />
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>
                    {fundAccountsvallidateref.current && (
                      <p className="errorcss">
                        {fundAccountsErrMsgref.current}
                      </p>
                    )}
                  </div>

                  <div className="Submit my-4" onClick={submit}>
                    <Link>
                      <button>Next</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
