import React, { useEffect, useMemo } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  useEffect(() => {
    getStep();
  }, [0]);

  const [selectAssociate, setSelectAssociate, selectAssociateref] =
    useState("yes");

  const [
    showOtherOccupationInput,
    setShowOtherOccupationInput,
    showOtherOccupationInputref,
  ] = useState(false);
  const [
    showOtherEmpstatusInput,
    setShowOtherEmpstatusInput,
    showOtherEmpstatusInputref,
  ] = useState(false);
  const [otherOccupation, setOtherOccupation] = useState("");
  const [unpaidActivity, setUnpaidActivity] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const navigate = useNavigate();

  // relative or associate
  const handleYesAssociate = () => {
    setSelectAssociate("yes");
  };
  const handleNoAssociate = () => {
    setSelectAssociate("no");
    setCompanies([]);
  };

  // const handleCompClick = () => {
  //   setIsAddCompany(true)
  // }

  const initialFormValue = {
    employStatus: "",
    empolyDuration: "",
    occupation: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [
    employStatusValidate,
    setemployStatusValidate,
    employStatusValidateref,
  ] = useState(false);
  const [
    empolyDurationValidate,
    setempolyDurationValidate,
    empolyDurationValidateref,
  ] = useState(false);
  const [occupationValidate, setoccupationValidate, occupationValidateref] =
    useState(false);
  const [companies, setCompanies] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { employStatus, empolyDuration, occupation } = formValue;

  const employStatusOptions = [
    { value: "Self-employed", label: "Self-employed" },
    { value: "Employee", label: "Child" },
    {
      value: "Subsidy recipient (i.e., unemployed/pensioner)",
      label: "Subsidy recipient (i.e., unemployed/pensioner)",
    },
    { value: "Rentier", label: "Rentier" },
    { value: "Unpaid activity", label: "Unpaid activity" },
  ];
  const empolyDurationOptions = [
    { value: "Less than 1 year", label: "Less than 1 year" },
    { value: "1 to 3 years", label: "1 to 3 years" },
    { value: "3 to 7 years", label: "3 to 7 years" },
    { value: "More than 7 years", label: "More than 7 years" },
  ];
  const occupationOptions = [
    {
      value: "Politically Exposed Person (PEP)",
      label: "Politically Exposed Person (PEP)",
    },
    { value: "Law/Notary", label: "Law/Notary" },
    { value: "Art/Antiques", label: "Art/Antiques" },
    { value: "Public Office", label: "Public Office" },
    { value: "Currency Exchange Office", label: "Currency Exchange Office" },
    { value: "Casino/Betting", label: "Casino/Betting" },
    {
      value: "Construction/Real Estate Development",
      label: "Construction/Real Estate Development",
    },
    { value: "Wealth Management", label: "Wealth Management" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Precious Metals", label: "Precious Metals" },
    { value: "Import-Export", label: "Import-Export" },
    { value: "Sports Company", label: "Sports Company" },
    {
      value: "Holding/Instrumental Company",
      label: "Holding/Instrumental Company",
    },
    { value: "Others", label: "Others" },
  ];

  const handleEmployStatusChange = (selectedEmployStatus) => {
    if (selectedEmployStatus.value === "Unpaid activity") {
      setShowOtherEmpstatusInput(true);
      setFormValue({
        ...formValue,
        employStatus: "",
      });
    } else {
      setShowOtherEmpstatusInput(false);
      const updatedFormValue = {
        ...formValue,
        employStatus: selectedEmployStatus.value,
      };
      setFormValue(updatedFormValue);
      validate({
        ...formValue,
        employStatus:
          selectedEmployStatus.value === "Others"
            ? ""
            : selectedEmployStatus.value,
      });
    }
  };
  const handleUnpaidActivityChange = (e) => {
    setUnpaidActivity(e.target.value);
    const updatedFormValue = {
      ...formValue,
      employStatus: e.target.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleEmpolyDurationChange = (selectedEmpolyDuration) => {
    const updatedFormValue = {
      ...formValue,
      empolyDuration: selectedEmpolyDuration.value,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };
  const handleOccupationChange = (selectedOccupation) => {
    console.log(selectedOccupation.value, "selectedOccupation=-=-");
    if (selectedOccupation.value === "Others") {
      setShowOtherOccupationInput(true);
      setFormValue({
        ...formValue,
        occupation: "",
      });
    } else {
      setShowOtherOccupationInput(false);
      const updatedFormValue = {
        ...formValue,
        occupation: selectedOccupation.value,
      };

      setFormValue(updatedFormValue);
      validate({
        ...formValue,
        occupation:
          selectedOccupation.value === "Others" ? "" : selectedOccupation.value,
      });
    }
  };

  const handleOtherOccupationChange = (e) => {
    setOtherOccupation(e.target.value);
    const updatedFormValue = {
      ...formValue,
      occupation: e.target.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleCompClick = () => {
    setCompanies([
      ...companies,
      { legalEntityName: "", taxIdNumber: "", ownershipShare: "" },
    ]);
  };

  const handleCompanyChange = (index, key, value) => {
    const updatedCompanies = companies.map((company, i) =>
      i === index ? { ...company, [key]: value } : company
    );
    setCompanies(updatedCompanies);
  };

  const handleCompanyDelete = (index) => {
    const updatedCompanies = companies.filter((_, i) => i !== index);
    setCompanies(updatedCompanies);
  };

  const validate = (values) => {
    let errors = {};

    if (values.employStatus == "") {
      setemployStatusValidate(true);
      errors.employStatus = " Empoly status is required !";
    } else
    // else {
    //   setemployStatusValidate(false);
    // }

    if (values.empolyDuration == "") {
      setempolyDurationValidate(true);
      errors.empolyDuration = " Empolyment Duration is required !";
    } else
    // else {
    //   setempolyDurationValidate(false);
    // }

    if (values.occupation == "") {
      setoccupationValidate(true);
      errors.occupation = " Occupation is required !";
    } else {
      setoccupationValidate(false);
      setempolyDurationValidate(false);
      setemployStatusValidate(false);
    }
  
    // else {
    //   setoccupationValidate(false);
    // }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async (payload) => {
    let errros = validate(formValue);
    formValue["individualControl"] = selectAssociateref.current;
    formValue["entities"] = companies;
    formValue["verifiedSteps"] = "Step-2";
    if (
      employStatusValidateref.current === false &&
      empolyDurationValidateref.current === false &&
      occupationValidateref.current === false
    ) {
      var data = {
        apiUrl: apiService.kycsecond,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      // setFormValue(initialFormValue);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        navigate("/KycInformation3");
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);

      if (resp.data !== null) {
        var kycData = resp.data;
        formValue.employStatus = kycData.employStatus;
        setUnpaidActivity(kycData.employStatus);
        formValue.empolyDuration = kycData.empolyDuration;
        formValue.occupation = kycData.occupation;
        formValue["individualControl"] = kycData.individualControl;
        // formValue["entities"] =kycData.entities;
        setCompanies(kycData.entities);
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KycInformation1">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Verification
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">2</span>Additional Account Holder
                    Information
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      What is your employment/professional status?
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={employStatusOptions}
                        value={employStatusOptions.find(
                          (option) => option.value === employStatus
                        )}
                        onChange={handleEmployStatusChange}
                        placeholder="Select employment status"
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {showOtherEmpstatusInput && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          Please specify empolyment status
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={unpaidActivity}
                            onChange={handleUnpaidActivityChange}
                            className=""
                            placeholder="Enter your occupation"
                          />
                        </div>
                      </div>
                    )}
                    {validationnErr && validationnErr.employStatus && (
                      <p className="errorcss">{validationnErr.employStatus}</p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Indicate the duration of your current
                      employment/professional status?
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={empolyDurationOptions}
                        value={empolyDurationOptions.find(
                          (option) => option.value === empolyDuration
                        )}
                        onChange={handleEmpolyDurationChange}
                        placeholder="Select empolyment duration"
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {validationnErr && validationnErr.empolyDuration && (
                      <p className="errorcss">
                        {validationnErr.empolyDuration}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">Occupation</h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={occupationOptions}
                        value={occupationOptions.find(
                          (option) => option.value === occupation
                        )}
                        onChange={handleOccupationChange}
                        placeholder="Select occupation"
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {showOtherOccupationInput && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          Please specify your occupation
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={otherOccupation}
                            onChange={handleOtherOccupationChange}
                            className=""
                            placeholder="Enter your occupation"
                          />
                        </div>
                      </div>
                    )}
                    {validationnErr && validationnErr.occupation && (
                      <p className="errorcss">{validationnErr.occupation}</p>
                    )}
                  </div>
                  <div className="access_act_text">
                    <p className="holder_content">
                      Does the individual control more than 25% of the capital
                      of any legal entity/entities, or voting rights, or
                      otherwise exercise direct or indirect control over its
                      management?
                    </p>
                    <div className="access_act_text_btn">
                      <button
                        onClick={handleYesAssociate}
                        className={
                          selectAssociateref.current === "yes"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleNoAssociate}
                        className={
                          selectAssociateref.current === "no"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        No
                      </button>
                    </div>
                  </div>
                  {selectAssociateref.current === "yes" ? (
                    <>
                      <div className="access_act_text">
                        <p className="holder_content">
                          If yes, please add the information of the entity(ies)
                        </p>
                        <div
                          className="add_compant_content cur-pointer"
                          onClick={handleCompClick}
                        >
                          <i class="ri-add-circle-line"></i>
                          <h4>Add Company</h4>
                        </div>
                      </div>

                      {companies.map((company, index) => (
                        <div key={index}>
                          <div className="input-groups icons">
                            <h6 className="input-label">Legal Entity Name</h6>
                            <div className="input-field ref_input upload_icon kyc_country_dropdown">
                              <input
                                type="text"
                                className=""
                                value={company.legalEntityName}
                                placeholder="Enter the entity name"
                                onChange={(e) =>
                                  handleCompanyChange(
                                    index,
                                    "legalEntityName",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="input-groups icons">
                            <h6 className="input-label">
                              Tax Identification Number
                            </h6>
                            <div className="input-field ref_input upload_icon kyc_country_dropdown">
                              <input
                                type="text"
                                className=""
                                value={company.taxIdNumber}
                                placeholder="Tax identification number"
                                onChange={(e) =>
                                  handleCompanyChange(
                                    index,
                                    "taxIdNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="input-groups icons">
                            <h6 className="input-label">Ownership share, %</h6>
                            <div className="input-field ref_input upload_icon kyc_country_dropdown">
                              <input
                                type="text"
                                className=""
                                placeholder="Enter the ownership share"
                                value={company.ownershipShare}
                                onChange={(e) =>
                                  handleCompanyChange(
                                    index,
                                    "ownershipShare",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="delete_text cursor-pointer"
                            onClick={() => handleCompanyDelete(index)}
                          >
                            <i class="ri-delete-bin-6-line"></i> Delete
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}

                  <div className="Submit my-4">
                    {buttonLoader == false ? (
                      <button onClick={formSubmit}>Next</button>
                    ) : (
                      <button>Loading ...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
