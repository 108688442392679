import React, { useState, useEffect } from "react";
import { selectDate, stakeOpt } from "../utils/mockData";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Bitcoin from "../assets/btc.png";
import line from "../assets/straightLine.png";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const StakeOptTable = (data) => {
  const [open, setOpen] = useState(false);
  const [stakeData, setStakeData] = useState({});
  const [Duration, setDuration] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [stakecurrentDate, setstakeCurrentDate] = useState("");
  const [stakeendDate, setstakeendDate] = useState("");
  const [accuralDate, setaccuralDate] = useState("");
  const [stakeValue, setstakeValue] = useState();
  const [userDailyInterest, setuserDailyInterest] = useState(0);
  const [userTotlaInterest, setuserTotlaInterest] = useState(0);
  const [FlexibleEarn, setFlexibleEarn] = useState(0);
  const [TotalFlexible, setTotalFlexible] = useState(0);
  const [apy, setApy] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [buttonStatus, setbuttonStatus] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  useEffect(() => {
    const todaydate = new Date();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedCurrentDate = todaydate.toLocaleString("en-GB", options);
    setCurrentDate(formattedCurrentDate);
    console.log(stakeData, "stakeData");
  }, []);

  const handleOpen = (option) => {
    console.log(option, "optinons");
    setStakeData(option);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrorState(false);
    setErrorMsg("");
  };

  const selectDuration = (data) => {
    console.log(data, "ljfijdsalkf");
    setDuration(data.duration);
    setApy(data.durationApy);
    if (Duration !== "") {
      setErrorState(false);
      setErrorMsg("");
    }
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + data.duration);
    setstakeCurrentDate(currentDate);
    setstakeendDate(endDate);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formattedCurrentDate = currentDate.toLocaleString("en-GB", options);
    const formattedEndDate = endDate.toLocaleString("en-GB", options);
    setCurrentDate(formattedCurrentDate);
    setendDate(formattedEndDate);
    setaccuralDate(formattedEndDate);
  };

  const stakeAmount = (e) => {
    try {
      const value = e.target.value;

      if (Duration == "" && stakeData.type === "fixed") {
        setErrorState(true);
        setErrorMsg("Choose Duration");
      } else if (value.length > 15) {
        setstakeValue();
        setErrorState(true);
        setErrorMsg("Invalid Amount");
      } else if (value == "00000") {
        setErrorState(true);
        setErrorMsg("Invalid Amount");
      } else if (value == "0.0000") {
        setErrorState(true);
        setErrorMsg("Invalid Amount");
      } else if (value < stakeData.minimimumStaking) {
        setErrorState(true);
        setErrorMsg(`Minimum staking level ${stakeData.minimimumStaking} `);
      } else if (value > stakeData.maximimumStaking) {
        setErrorState(true);
        setErrorMsg(`Minimum staking level ${stakeData.maximimumStaking} `);
      } else {
        setErrorState(false);
        setstakeValue(value);
        setbuttonStatus(true);
      }

      var amount = parseFloat(e.target.value);
      if (stakeData.type === "fixed") {
        var dailyinterest = amount * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * Duration;
        setuserTotlaInterest(totalInterest ? totalInterest : 0);
        setuserDailyInterest(dailyinterest ? dailyinterest : 0);
      } else if (stakeData.type === "flexible") {
        console.log("amount::::", amount, "interest:::", stakeData.APRinterest);
        var dailyinterestDate = amount * (+stakeData.APRinterest / 100 / 365);
        var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
        setFlexibleEarn(dailyinterestDate ? dailyinterestDate : 0);
        setTotalFlexible(totalInterestFlex);
      }
    } catch (error) {
      console.log("error", error);
      // showerrorToast("Please try again later");
    }
  };

  const stake = async (e) => {
    try {
      const currentDate = new Date();

      e.preventDefault();
      // setbuttonLoader(true);
      console.log("staketSubmitfunction");
      var obj = {
        stakingPlan: stakeData.type === "fixed" ? Duration : 0,
        totalInterest:
          stakeData.type === "fixed" ? +userTotlaInterest : +TotalFlexible,
        dailyinterest:
          stakeData.type == "fixed" ? +userDailyInterest : +FlexibleEarn,
        startDate: stakeData.type == "fixed" ? stakecurrentDate : currentDate,
        endDate: stakeendDate,
        currentAPY: stakeData.type === "fixed" ? +apy : +stakeData.APRinterest,
        stakeMore: stakeData.stakeid,
        stakeAmont: +stakeValue,
        type: stakeData.type,
      };
      var data = {
        apiUrl: apiService.confirmStaking,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        setbuttonLoader(false);
        showSuccessToast(resp.Message);
        handleClose();
        window.location.reload();
      } else {
        setbuttonLoader(false);
        showErrorToast(resp.Message);
      }
    } catch (err) {}
  };

  return (
    <div className="table-responsive table-cont">
      <table className="table">
        <thead>
          <tr className="stake-head font-satoshi">
            <th>Currency</th>
            <th className="txt-center opt-nowrap pad-left-23">Reference APR</th>
            <th className="txt-center opt-nowrap pad-left-23">
              Minumum Staking
            </th>
            <th className="txt-center opt-nowrap pad-left-23">
              Maximum Staking
            </th>
            <th className="txt-center pad-left-23">Term</th>
            <th className="opt-btn-flex table-action pad-left-23">Action</th>
          </tr>
        </thead>

        <tbody>
          {data.data.length > 0 ? (
            data.data.map((options) => {
              console.log(options, "---");
              return (
                <tr key={options.id}>
                  <td className="table-flex">
                    <img src={options.currencyImage} alt="" />
                    <div className="table-opt-name">
                      <h4 className="opt-name font-satoshi font_14">
                        {options.currencyName}
                      </h4>
                      <h3 className="opt-sub font-satoshi font_14">
                        {options.currencySymbol}
                      </h3>
                    </div>
                  </td>
                  <td className="opt-percent font-satoshi font_14 table_center_text pad-left-23">
                    {options.APRinterest}
                  </td>
                  <td className="opt-percent font-satoshi font_14 table_center_text pad-left-23">
                    {options.minimimumStaking}
                  </td>
                  <td className="opt-percent font-satoshi font_14 table_center_text pad-left-23">
                    {options.maximimumStaking}
                  </td>
                  <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                    {options.type}
                  </td>
                  <td className="opt-btn-flex table-action pad-left-23">
                    <button
                      className="opt-btn"
                      onClick={() => handleOpen(options)}
                    >
                      Stake now
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <td colSpan={7} className="text-center">
              No records found
            </td>
          )}
        </tbody>
      </table>

      {/* <div className="paginate font-satoshi">
        <span>
          <i class="fa-solid fa-chevron-left"></i>
        </span>
        <span className="paginate-one">1</span>
        <span>2</span>
        <span>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
      </div> */}

      {/* staking fixed */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modals">
          <div className="container">
            <div className="row">
              {/* left - staking flexible*/}
              <div className="col-lg-6 modal_left_bg">
                <div className="left">
                  <div className="modal-left-header">
                    <img
                      src={stakeData.currencyImage}
                      alt="bitcoin"
                      className="bitcoin-img"
                    />
                    <span className="stake-flexi">
                      Staking - {stakeData.type}
                    </span>
                  </div>
                  <div className="modal-left-main">
                    {stakeData.type === "fixed" ? (
                      <div className="modal-selectDate">
                        <h6 className="modal-select-title">Select the Date</h6>s
                        <div className="modal-flex">
                          {stakeData.duration === "" ||
                          stakeData.duration === undefined ||
                          stakeData.duration === null
                            ? ""
                            : stakeData.duration.length > 0
                            ? stakeData.duration.map((days) => {
                                return (
                                  <div
                                    key={days.duration}
                                    className={`modal-days ${
                                      Duration === days.duration
                                        ? "modal-days-active"
                                        : ""
                                    }`}
                                    onClick={() => selectDuration(days)}
                                  >
                                    <p>{days.duration} D</p>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {stakeData.type === "fixed" ? (
                      <div className="modal-left-flex">
                        <h6 className="modal-titles">Reference APY</h6>
                        <h5 className="modal-titles-green">{apy} %</h5>
                      </div>
                    ) : (
                      <div className="modal-left-flex">
                        <h6 className="modal-titles">Reference APR</h6>
                        <h5 className="modal-titles-green">
                          {stakeData.APRinterest} %
                        </h5>
                      </div>
                    )}
                    <div className="modal-left-flex">
                      {" "}
                      <h6 className="modal-titles">Term</h6>
                      <h5 className="modal-right-titles">{stakeData.type}</h5>
                    </div>
                    {stakeData.type === "fixed" ? (
                      <div className="modal-left-flex">
                        {" "}
                        <h6 className="modal-titles">Redemption Period</h6>
                        <h5 className="modal-right-titles">
                          {Duration === "" ||
                          Duration === undefined ||
                          Duration === null
                            ? 0
                            : Duration}{" "}
                          days
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-left-foot">
                    <h6 className="modal-left-title font-600">Amount</h6>
                    <div className="modal-input">
                      <input
                        type="number"
                        placeholder="Enter Amount"
                        className="modal-input-num"
                        value={stakeValue}
                        onChange={stakeAmount}
                        min="0"
                      />
                      {errorState === true ? (
                        <p className="text-red"> {errorMsg}</p>
                      ) : (
                        ""
                      )}
                      <span className="modal-span1">
                        {stakeData.currencySymbol}
                      </span>
                      <span className="modal-span2"> MAX</span>
                    </div>
                    <div className="modal-left-flex">
                      <h5 className="modal-titles">Minimum Amount</h5>
                      <h4 className="modal-right-titles">
                        {stakeData.minimimumStaking} {stakeData.currencySymbol}
                      </h4>
                    </div>
                    <div className="modal-left-flex">
                      <h5 className="modal-titles">Maximum Amount</h5>
                      <h4 className="modal-right-titles">
                        {stakeData.maximimumStaking} {stakeData.currencySymbol}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              {/* right - Preview*/}
              <div className="col-lg-6 right-col">
                <div className="right">
                  <div className="modal-right-header modal-left-flex">
                    <h5 className="stake-flexi">Preview</h5>

                    <i
                      className="fa-regular fa-circle-xmark cross_circle"
                      onClick={handleClose}
                    ></i>
                  </div>

                  <div className="modal-right-main">
                    <div className="modal-right-main-flex">
                      {stakeData.type === "fixed" ? (
                        <>
                          {/* IMG */}

                          <div className="straight-img">
                            <img src={line} alt="line" className="line-pic" />
                          </div>
                          {/* RIGHT CONTENT */}
                          <div className="modal-flex-col">
                            <div className="modal-left-flex">
                              <h6 className="modal-titles">
                                Subscription Date
                              </h6>
                              <h5 className="modal-right-titles">
                                {currentDate ? currentDate : "--.--"}
                              </h5>
                            </div>
                            <div className="modal-left-flex">
                              {" "}
                              <h6 className="modal-titles">Accrual Date</h6>
                              <h5 className="modal-right-titles">
                                {accuralDate ? accuralDate : "--.--"}
                              </h5>
                            </div>
                            <div className="modal-left-flex">
                              {" "}
                              <h6 className="modal-titles">
                                Profit Distrubution Date
                              </h6>
                              <h5 className="modal-right-titles">
                                {endDate ? endDate : "--.--"}
                              </h5>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="modal-flex-col">
                          <div className="modal-left-flex">
                            <>
                              <h6 className="modal-titles">
                                Subscription Date
                              </h6>
                              <h5 className="modal-right-titles">
                                {currentDate ? currentDate : "--.--"}
                              </h5>
                            </>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="modal-left-flex pad--border">
                      {" "}
                      <h6 className="modal-titles">Stake Amount</h6>
                      <h5 className="modal-right-titles">
                        {stakeValue} {stakeData.currencySymbol}
                      </h5>
                    </div>
                    <div className="modal-estimated">
                      <h6 className="modal-left-title font-600">
                        Estimated Returns
                      </h6>
                      <div className="modal-left-flex">
                        <h5 className="modal-titles">
                          {stakeData.currencySymbol} earnings
                        </h5>
                        {stakeData.type === "fixed" ? (
                          <h4 className="modal-titles-green">
                            {userTotlaInterest
                              ? userTotlaInterest.toFixed(6)
                              : "00.00"}
                          </h4>
                        ) : (
                          <h4 className="modal-titles-green">
                            {TotalFlexible ? TotalFlexible.toFixed(6) : "00.00"}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-right-foot">
                  {buttonLoader == true ? (
                    <button className="modal-right-btn ">Loading...</button>
                  ) : buttonStatus == true ? (
                    <button className="modal-right-btn " onClick={stake}>
                      Stake
                    </button>
                  ) : (
                    <button className="modal-right-btn disabled" disabled>
                      Stake
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default StakeOptTable;
