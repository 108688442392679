import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod ,getMethod} from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [isShowOther, setIsShowOther] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

 
  const handleDrop = () => {
    setIsShowDrop((prevDrop) => !prevDrop);
  };

  const handleOther = () => {
    setIsShowOther((prevDrop) => !prevDrop);
  };
  // AUTHOR OF JOTHI

  const fidexrelationship =[
    { label: "Savings", value: "Savings" },
    { label: "Financing", value: "Financing" },
    { label: "Investment", value: "Investment" },
    { label: "Others", value: "Others" },
  ]
  const fidexProductList=[
    { label: "Spot", value: "Spot" },
    { label: "Staking", value: "Staking" },
    { label: "Lending", value: "Lending" },
  ]

  const [Frequency_inflow, setFrequency_inflow] = useState([
    { label: "At account opening", value: "At account opening" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Random/Undefined", value: "Random/Undefined" },
    { label: "Annually", value: "Annually" },
    { label: "Semi-annually", value: "Semi-annually" },
  ]);


  const [inflowsFidexdata, setinflowsFidexdata] = useState([
    { label: "Less than EUR 300,000", value: "Less than EUR 300,000" },
    { label: "Between EUR 300,000 and 500,000" },
    {
      label: "Between EUR 500,000 and 1,500,000",
      value: "Between EUR 500,000 and 1,500,000",
    },
    {
      label: "Between EUR 1,500,000 and 3,000,000",
      value: "Between EUR 1,500,000 and 3,000,000",
    },
    {
      label: "Between EUR 3,000,000 and 6,000,000",
      value: "Between EUR 3,000,000 and 6,000,000",
    },
    { label: "More than EUR 6,000,000", value: "More than EUR 6,000,000" },
  ]);

  const [outflowsFidexdata, setoutflowsFidexdata] = useState([
    { label: "Less than EUR 300,000", value: "Less than EUR 300,000" },
    {
      label: "Between EUR 300,000 and 500,000",
      value: "Between EUR 300,000 and 500,000",
    },
    {
      label: "Between EUR 500,000 and 1,500,000",
      value: "Between EUR 500,000 and 1,500,000",
    },
    {
      label: "Between EUR 1,500,000 and 3,000,000",
      value: "Between EUR 1,500,000 and 3,000,000",
    },
    {
      label: "Between EUR 3,000,000 and 6,000,000",
      value: "Between EUR 3,000,000 and 6,000,000",
    },
    { label: "More than EUR 6,000,000", value: "More than EUR 6,000,000" },
  ]);
const [inflowtransaction, setinflowtransaction] = useState([
    { label: "Domestic Transfers", value: "Domestic Transfers" },
    { label: "International Transfers", value: "International Transfers" },
    { label: "Bank Card", value: "Bank Card" },
    { label: "Direct debits", value: "Direct debits" },
    { label: "Internal Operations", value: "Internal Operations" },
  ]);

  const [outflowtransaction, setoutflowtransaction] = useState([
    { label: "Domestic Transfers", value: "Domestic Transfers" },
    { label: "International Transfers", value: "International Transfers" },
    { label: "Internal operations", value: "Internal operations" },
  ]);

  const initialFormValue = {
    relation: "",
    otherrelations:"",
    inflowTransactionfidex: [],
    outflowTransactionfidex: [],
    InternationalTransfers: [],
    fidexProducts: [],
    outInternationalTransfers: [],
    countryofbank:"",
    inflowsFidex:"",
    outflowsFidex:"",
    Frequency_inflowdata:""
    
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [ShowOtherRelationInput, setShowOtherRelationInput] = useState(false);
  const [otherRelation, setOtherRelation] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [relationValidate, setrelationValidate, relationValidateref] = useState(false);
  const [relationErrMsg, setrelationErrMsg, relationErrMsgref] = useState("");
  const [fidexProductsValidate, setfidexProductsValidate, fidexProductsValidateref] = useState(false);
  const [fidexProductsErrMsg, setfidexProductsErrMsg, fidexProductsErrMsgref] = useState("");
  const [Frequency_inflowdataValidate, setFrequency_inflowdataValidate, Frequency_inflowdataValidateref] = useState(false);
  const [Frequency_inflowdataErrMsg, setFrequency_inflowdataErrMsg, Frequency_inflowdataErrMsgref] = useState("");
  const [inflowTransactionfidexValidate, setinflowTransactionfidexValidate, inflowTransactionfidexValidateref] = useState(false);
  const [inflowTransactionfidexErrMsg, setinflowTransactionfidexErrMsg, inflowTransactionfidexErrMsgref] = useState("");
  const [outflowTransactionfidexValidate, setoutflowTransactionfidexValidate, outflowTransactionfidexValidateref] = useState(false);
  const [outflowTransactionfidexErrMsg, setoutflowTransactionfidexErrMsg, outflowTransactionfidexErrMsgref] = useState("");
  const [outflowsFidexValidate, setoutflowsFidexValidate, outflowsFidexValidateref] = useState(false);
  const [outflowsFidexErrMsg, setoutflowsFidexErrMsg, outflowsFidexErrMsgref] = useState("");
  const [inflowsFidexValidate, setinflowsFidexValidate, inflowsFidexValidateref] = useState(false);
  const [inflowsFidexErrMsg, setinflowsFidexErrMsg, inflowsFidexErrMsgref] = useState("");
  const [countryValidate, setcountryValidate,countryValidateref] = useState();
  const [countryErrMsg, setcountryErrMsg,countryErrMsgref] = useState();

  useEffect(() => {
    getStep();
  }, [0]);

  const handlfidexrelationshipChange = (selectedRelation) => {
    if (selectedRelation.value === "Others") {
      setShowOtherRelationInput(true);
      setFormValue({
        ...formValue,
        relation: "Others",
      });
    } else {
      setShowOtherRelationInput(false);
      const updatedFormValue = {
        ...formValue,
        relation: selectedRelation.value,
      };

      setFormValue(updatedFormValue);
    }
  };

  const handleOtherRelationChange = (e) => {
    setOtherRelation(e.target.value);
    const updatedFormValue = {
      ...formValue,
      otherrelations: e.target.value,
    };
    setFormValue(updatedFormValue);
  };

  const handleProductChange = (event) => {
    const { value, checked } = event.target;
    let updatedfidexProducts;

    if (checked) {
      updatedfidexProducts = [...formValue.fidexProducts, value];
    } else {
      updatedfidexProducts = formValue.fidexProducts.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      fidexProducts: updatedfidexProducts,
    };
    setFormValue(updatedFormValue);
    // vallidate(updatedFormValue);
  };

  const handleinflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      inflowsFidex: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };
  const handlefrequencyinflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      Frequency_inflowdata: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };
  
  const handleoutflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      outflowsFidex: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };

  const handleinflowTransactionChange = (event) => {
    const { value, checked } = event.target;
    let updatetransaction;

    if (checked) {
      updatetransaction = [...formValue.inflowTransactionfidex, value];
    } else {
      updatetransaction = formValue.inflowTransactionfidex.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      inflowTransactionfidex: updatetransaction,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);

  };
  

  const handleoutflowTransactionChange = (event) => {
    const { value, checked } = event.target;
    let updateouttransaction;

    if (checked) {
      updateouttransaction = [...formValue.outflowTransactionfidex, value];
    } else {
      updateouttransaction = formValue.outflowTransactionfidex.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      outflowTransactionfidex: updateouttransaction,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };


  const handlInternationalTransfersChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      InternationalTransfers: event.target.value,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const handloutInternationalTransfersChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      outInternationalTransfers: event.target.value,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      countryofbank: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
  };


  const vallidate = () =>{
    try{
      // let errors = {};
    
      if (formValue.relation == "") {
        setrelationValidate(true);
        setrelationErrMsg(" Purpose of the relationship is required !");
      } else if (formValue.relation == "Others" && formValue.otherrelations == "") {
        setrelationValidate(true);
        setrelationErrMsg(" Purpose of the relationship is required !");
      } else {
        setrelationValidate(false);
      }
  
  
      if (formValue.fidexProducts == "") {
        setfidexProductsValidate(true);
        setfidexProductsErrMsg(" Fidex products is required !");
      } else {
        setfidexProductsValidate(false);
      }
  
  
      if (formValue.Frequency_inflowdata == "") {
        setFrequency_inflowdataValidate(true);
        setFrequency_inflowdataErrMsg(" Frequency of inflows is  required !");
      } else {
        setFrequency_inflowdataValidate(false);
      }
  
      if (formValue.inflowTransactionfidex == "") {
        setinflowTransactionfidexValidate(true);
        setinflowTransactionfidexErrMsg(" Inflow Transaction is required !");
      } else if (formValue.inflowTransactionfidex === "International Transfers" && formValue.InternationalTransfers ==""){
        setinflowTransactionfidexValidate(true);
        setinflowTransactionfidexErrMsg(" International Transfers is required !");
      }
      else {
        setinflowTransactionfidexValidate(false);
        setinflowTransactionfidexErrMsg(" ");   
       }
  
  
      if (formValue.outflowTransactionfidex == "") {
        setoutflowTransactionfidexValidate(true);
        setoutflowTransactionfidexErrMsg(" Outflow Transaction is required");   
      }  else if (formValue.outflowTransactionfidex === "International Transfers" && formValue.outInternationalTransfers ==""){
        setoutflowTransactionfidexValidate(true);
        setoutflowTransactionfidexErrMsg(" International Transfers is required !");
      }
      else {
        setoutflowTransactionfidexValidate(false);
        setoutflowTransactionfidexErrMsg(" ");   
       }
    
      if (formValue.outflowsFidex == "") {
        setoutflowsFidexValidate(true);
        setoutflowsFidexErrMsg("Annual volume of outflows is required !");  
      } else {
        setoutflowsFidexValidate(false);
        setoutflowsFidexErrMsg("");  
      }
      console.log(formValue,"formValue.inflowsFidexformValue.inflowsFidex")
      if (formValue.inflowsFidex == "") {
        console.log("=-=-=-=-=-=")
        setinflowsFidexValidate(true);
        setinflowsFidexErrMsg("Annual volume of inflows is required !");  
      } else {
        console.log("==========")
        setinflowsFidexValidate(false);
        setinflowsFidexErrMsg("");  
      }
  
  
      if (formValue.countryofbank == "") {
        setcountryValidate(true);
         setcountryErrMsg(" Country is required !");;
      } else {
        setcountryValidate(false);
        setcountryErrMsg("");
      }
      // setvalidationnErr(errors);

    }catch(err){
        console.log(err,"--=-=")
    }
   

  }

  const submit = async () => {
    try{
      vallidate();
      formValue["verifiedSteps"] = "Step-4";
      if (
        relationValidateref.current === false &&
        fidexProductsValidateref.current === false &&
        Frequency_inflowdataValidateref.current === false &&
        inflowTransactionfidexValidateref.current === false &&
        outflowTransactionfidexValidateref.current === false &&
        outflowsFidexValidateref.current === false &&
        inflowsFidexValidateref.current === false &&
        countryValidateref.current === false
      ) {
        var data = {
          apiUrl: apiService.kybfourth,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/KypInformation5");
        } else {
          toast.error(resp.Message);
        }
      } else {
      }
    }catch(err){
      console.log(err,"=-=-=-=")
    }
  }



  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
      console.log(kybData,"kybData");
       formValue.relation=kybData.relation;
       formValue.otherrelations=kybData.otherrelations;
       formValue.fidexProducts=kybData.fidexProducts;
       formValue.inflowTransactionfidex=kybData.inflowTransactionfidex;
       formValue.outflowTransactionfidex=kybData.outflowTransactionfidex;
       formValue.InternationalTransfers=kybData.InternationalTransfers;
       formValue.outInternationalTransfers=kybData.outInternationalTransfers;
       formValue.countryofbank=kybData.countryofbank;
       formValue.inflowsFidex=kybData.inflowsFidex;
       formValue.outflowsFidex=kybData.outflowsFidex;
       formValue.Frequency_inflowdata=kybData.Frequency_inflowdata;
      }
    } catch (err) {
      console.log(err,"=-=-=-==")
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KypInformation3">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Verification
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">4</span>Account Activity
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Purpose of the relationship with FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={fidexrelationship}
                        value={fidexrelationship.find(
                          (option) => option.value === formValue.relation
                        )}
                        onChange={handlfidexrelationshipChange}
                        placeholder="Select relation"
                        className=""
                        styles={colourStyles}
                      />
                    </div>

                    {ShowOtherRelationInput && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          Please specify your relationship
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={otherRelation}
                            onChange={handleOtherRelationChange}
                            className=""
                            placeholder="Enter your relatioship"
                          />
                        </div>
                       
                      </div>
                    )}
                  </div>
                  {!relationValidateref.current ?"": (
                          <p className="errorcss">
                            {relationErrMsgref.current}
                          </p>
                        )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Which FIDEX products do you plan to use?
                    </h6>
                    <div className="input-checkboxs">
                        <ul>
                        {fidexProductList.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.fidexProducts.includes(
                                option.value
                              )}
                              onChange={handleProductChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {!fidexProductsValidateref.current ?"": (
                          <p className="errorcss">
                            {fidexProductsErrMsgref.current}
                          </p>
                        )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Expected annual volume of inflows/incomes to FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                    <Select
                        options={inflowsFidexdata}
                        value={inflowsFidexdata.find(
                          (option) => option.value === formValue.inflowsFidex
                        )}
                        onChange={handleinflowincomeChange}
                        placeholder="Select expected annual volume "
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>

                  {!inflowsFidexValidateref.current ?"": (
                          <p className="errorcss">
                            {inflowsFidexErrMsgref.current}
                          </p>
                        )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Frequency of inflows/incomes to FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                    <Select
                        options={Frequency_inflow}
                        value={Frequency_inflow.find(
                          (option) => option.value === formValue.Frequency_inflowdata
                        )}
                        onChange={handlefrequencyinflowincomeChange}
                        placeholder="Select frequency of inflows"
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>
             {!Frequency_inflowdataValidateref.current ?"": (
                          <p className="errorcss">
                            {Frequency_inflowdataErrMsgref.current}
                          </p>
                        )}
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Expected annual volume of outflows from FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                    <Select
                        options={outflowsFidexdata}
                        value={outflowsFidexdata.find(
                          (option) => option.value === formValue.outflowsFidex
                        )}
                        onChange={handleoutflowincomeChange}
                        placeholder="Select expected annual volume "
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>

                  {!outflowsFidexValidateref.current ?"": (
                          <p className="errorcss">
                            {outflowsFidexErrMsgref.current}
                          </p>
                        )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Common inflow transactions to FIDEX
                    </h6>
                    <div className="input-checkboxs">

                    <ul>
                        {inflowtransaction.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.inflowTransactionfidex.includes(
                                option.value
                              )}
                              onChange={handleinflowTransactionChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}

                        {formValue.inflowTransactionfidex.includes(
                          "International Transfers"
                        ) && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              Please specify your inflow transactions to FIDEX{" "}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.InternationalTransfers}
                                onChange={handlInternationalTransfersChange}
                                className=""
                                placeholder="please specify"
                              />
                            </div>
                          </div>
                        )}
                      
                      </ul>
                    
                    </div>

                    {!inflowTransactionfidexValidateref.current ?"": (
                          <p className="errorcss">
                            {inflowTransactionfidexErrMsgref.current}
                          </p>
                        )}

                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Common outflow transactions from FIDEX
                    </h6>
                    <div className="input-checkboxs">
                    <ul>
                        {outflowtransaction.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value }
                              checked={formValue.outflowTransactionfidex.includes(
                                option.value 
                              )}
                              onChange={handleoutflowTransactionChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}

                        {formValue.outflowTransactionfidex.includes(
                          "International Transfers"
                        ) && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              Please specify your outflow transactions to FIDEX{" "}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.outInternationalTransfers}
                                onChange={handloutInternationalTransfersChange}
                                className=""
                                placeholder="please specify"
                              />
                            </div>
                          </div>
                        )}
                      

                      </ul>

                      {!outflowTransactionfidexValidateref.current ?"": (
                          <p className="errorcss">
                            {outflowTransactionfidexErrMsgref.current}
                          </p>
                        )}
                 
                    </div>
                
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Which country is your bank account in?
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                    <Select
                        options={options}
                        value={options.find(
                          (option) => option.value === formValue.countryofbank
                        )}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        styles={colourStyles}
                      />
                    </div>
                  </div>
                  {!countryValidateref.current ?"": (
                          <p className="errorcss">
                            {countryErrMsgref.current}
                          </p>
                        )}

                  <div className="Submit my-4" onClick={submit}>
                    <Link >
                      <button>Next</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
