import React, { useEffect } from "react";
import useState from "react-usestateref";
import KycInformation1 from "./Kyc_Information1";
import KycInformation2 from "./Kyc_information2";
import KycInformation3 from "./Kyc_information3";
import KycInformation4 from "./Kyc_information4";
import KycInformation5 from "./Kyc_information5";
import KycInformation6 from "./Kyc_information6";
import Kyc from "./Kyc";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Bars } from "react-loader-spinner";

const KycProcess = () => {
  //   const getSavedProgress = () => {
  //     const savedStep = localStorage.getItem('currentStep');
  //     return savedStep ? parseInt(savedStep, 10) : 1; // Default to step 1 if nothing is saved
  //   };

  const [currentStep, setCurrentStep, currentStepref] = useState("Step-1");

  useEffect(() => {
    getStep();
    // localStorage.setItem('currentStep', currentStep);
  }, []);

  const getStep = async () => {
    try {
      setloader(true);
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      setloader(false);
      setCurrentStep(resp.data === null ? "" : resp.data.verifiedSteps);
      console.log(currentStepref.current, "currentStep");
    } catch (err) {}
  };

  const [loader, setloader] = useState(false);

  const renderStep = () => {
      switch (currentStepref.current) {
        case "":
          return <KycInformation1 onNext={() => setCurrentStep("Step-1")} />;
        case "Step-1":
          return <KycInformation2 onNext={() => setCurrentStep("Step-2")} />;
        case "Step-2":
          return <KycInformation3 onNext={() => setCurrentStep("Step-3")} />;
        case "Step-3":
          return <KycInformation4 onNext={() => setCurrentStep("Step-4")} />;
        case "Step-4":
          return <KycInformation5 onNext={() => setCurrentStep("Step-5")} />;
        case "Step-5":
          return <KycInformation6 onNext={() => setCurrentStep("Step-6")} />;
        case "Step-6":
          return <Kyc onNext={() => setCurrentStep("Step-6")} />;
        default:
          return <KycInformation1 onNext={() => setCurrentStep("Step-1")} />;
      
    };
    }
   

  return <>{loader == true ?   <div className="loadercss">
    <Bars
      height="80"
      width="80"
      color="#33c2b9"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  </div>:<div>{renderStep()}</div> }</>;
};

export default KycProcess;
