import React from "react";
import Header from "./Header";

const Phising = () => {
  return (
    <>
    <Header />
      <div>
        <div className="Verification ">
          <div className="container">
            <div>
              <h6>
                {" "}
                <i className="fa-solid fa-arrow-left-long mr-3"></i> Security
              </h6>
              <div className="row justify-content-center cards anti-phasing-margin ">
                <div className="col-lg-4">
                  <span className="heading">Create Anti-Phasing Code</span>
                  <div className="input-groups icons">
                    <h6 className="input-label">Anti-Phising Code</h6>
                    <input
                      className="input-field"
                      placeholder="Input 4-8 Numbers or letters "
                    />
                    <span className="textgreen eyeicons"> 56 s </span>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">Email Verification code</h6>
                    <input
                      className="input-field"
                      placeholder="Enter the code"
                    />
                    <span className="textgreen eyeicons"> Send </span>
                  </div>

                  <div className="Submit my-4">
                    <button>Confirm</button>
                  </div>

                  <div className="foot">
                    <p>
                      Didn't receive a code ? <a>Resend</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Phising;
