import React, { useState, useRef, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";

export default function Landing() {
  const [offset, setOffset] = useState(0);

  const buyChickenRef = useRef(null);
  const shellChickenRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (buyChickenRef.current) {
        const sectionTop = buyChickenRef.current.offsetTop;
        const sectionHeight = buyChickenRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (shellChickenRef.current) {
        const sectionTop = shellChickenRef.current.offsetTop;
        const sectionHeight = shellChickenRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>

        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero_section_content">
                  <h1>
                    Trade, Invest, Stake, and Secure Your Future with a Top Tire{" "}
                    Crypto Exchange
                  </h1>
                  <p>
                    Experience unparalleled security, innovative tools, and
                    exceptional support on a leading crypto platform
                  </p>
                  <Link to="/register">
                    <button className="get_started_btn">Get Started</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero_section_banner">
                  <img src={require("../assets/hero_banner.webp")} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* dive into the world */}
        <section className="secrets_crypto_section">
          <div className="container">
            <div className="secrets_crypto_content">
              <div className="secrets_crypto_title">
                <h2> Dive Into the World of Crypto Here</h2>
                <p>
                  Uncover the secrets of cryptocurrency trading and investment.
                </p>
              </div>

              <div className="row">
                <div className="col-lg-4 bg_overflow">
                  <div className="secrets_crypto_create mar-top-2">
                    <div className="secrets_crypto_create_icon">
                      <div className="secrets-icon-wrapper">
                        <img
                          src={require("../assets/profile_icon.png")}
                          className="secrets-icon"
                        />
                      </div>
                      <h4 className="secrets_crypto-title">Create Account</h4>
                    </div>
                    <p>
                      Uncover the secrets of cryptocurrency trading and
                      investment.
                    </p>
                    <p className="mar-top-create">
                      <Link to="/">
                        <button className="lets-try-btn">
                          Try it{" "}
                          <span className="mx-1">
                            {" "}
                            <i class="fa-solid fa-angle-right"></i>
                          </span>
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 bg_overflow">
                  <div className="secrets_crypto_create deposit_card mar-top-2">
                    <div className="secrets_crypto_create_icon">
                      <div className="secrets-icon-wrapper">
                        <img
                          src={require("../assets/deposit_icon.png")}
                          className="secrets-icon"
                        />
                      </div>
                      <h4 className="secrets_crypto-title">Deposit</h4>
                    </div>
                    <p>
                      Deposit funds securely into your account to start trading
                      and investing in cryptocurrencies.
                    </p>
                    <p className=" mar-top-deposit">
                      <Link to="/deposit">
                        <button className="lets-try-btn">
                          Try it{" "}
                          <span className="mx-1">
                            {" "}
                            <i class="fa-solid fa-angle-right"></i>
                          </span>
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 bg_overflow">
                  <div className="secrets_crypto_create trade_card mar-top-2">
                    <div className="secrets_crypto_create_icon">
                      <div className="secrets-icon-wrapper">
                        <img
                          src={require("../assets/trade_coin.png")}
                          className="secrets-icon"
                        />
                      </div>
                      <h4 className="secrets_crypto-title">Trade</h4>
                    </div>
                    <p>
                      Start buying, selling, and exchanging cryptocurrencies
                      instantly on our user-friendly platform.
                    </p>
                    <p className="mar-top-trade">
                      <Link to="/trade/ETH_USDT">
                        <button className="lets-try-btn">
                          Try it{" "}
                          <span className="mx-1">
                            {" "}
                            <i class="fa-solid fa-angle-right"></i>
                          </span>
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* advanced trading */}
        <section className="advance_trade_section">
          <div className="container">
            <div className="advance_trade_title">
              <p>Advanced Trading with Fidex</p>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-transImg-wrapper-one">
                    <img
                      src={require("../assets/fast_transaction_icon.png")}
                      className="fast-trans-img"
                    />
                  </div>
                  <h4 className="fast-trans-title">Fast Transactions</h4>
                  <p>
                    Experience lightning-fast trade executions with minimal
                    latency.
                  </p>
                  <p>
                    <Link to="/trade/ETH_USDT">
                      <button className="trade-btn">
                        Trade <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>{" "}
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-transImg-wrapper-two">
                    <img
                      src={require("../assets/low_fees.png")}
                      className="fast-trans-img"
                    />
                  </div>
                  <h4 className="fast-trans-title">Low Fees</h4>
                  <p>
                    Enjoy competitive rates with a transparent fee structure.
                  </p>
                  <p>
                    <Link to="/trade/ETH_USDT">
                      <button className="trade-btn">
                        Trade <i class="fa-solid fa-arrow-right "></i>
                      </button>
                    </Link>
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-transImg-wrapper-three">
                    <img
                      src={require("../assets/robust_icon.png")}
                      className="fast-trans-img"
                    />
                  </div>
                  <h4 className="fast-trans-title">Robust Security</h4>
                  <p>
                    Advanced encryption and secure cold storage for maximum
                    asset protection.
                  </p>
                  <p>
                    <Link to="/trade/ETH_USDT">
                      <button className="trade-btn">
                        Trade <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* discover advanced trading */}
        <div className="" ref={buyChickenRef}>
          <section className="advance_trading_section">
            <div className="container">
              <div className="advance_trading_section_content">
                <div className="row">
                  <div className="col-lg-6 crypto_platform_paralax">
                    <div
                      className="parallax-text left-to-right d-flex"
                      style={{
                        transform: `translateY(${-offset * 0.5}px)`,
                      }}
                    >
                      {" "}
                      <img src={require("../bitcoin/features_card3.webp")} />
                      <img src={require("../bitcoin/features_card2.webp")} />
                      <img src={require("../bitcoin/features_card1.webp")} />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="discover_advance_content">
                      <h4>Crypto Platform</h4>
                      <h2>Discover Advanced Trading Features</h2>
                      <p>
                        Maximize your trading potential with our
                        state-of-the-art platform. Experience unparalleled
                        benefits and efficiency with our extensive array of
                        premium features.
                      </p>
                      <Link to="/register">
                        <button className="get_started_btn">Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* supported */}
          <section className="advance_trading_section">
            <div className="container">
              <div className="supported_currencies">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="supported_currencies_content">
                      <h4>Supported currencies</h4>
                      <h2>
                        Available Cryptocurrencies <br /> on Our Platform
                      </h2>
                      <p>
                        Trade a wide range of cryptocurrencies, including the
                        most <br />
                        popular and emerging digital assets.
                      </p>
                      <Link to="/register">
                        <button className="get_started_btn">Get Started</button>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <section className="buy_chicken_section">
                      <div className="row">
                        {/* previously it had col-lg-3 - now changed for mobile view */}{" "}
                        <div className="col-3">
                          {" "}
                          <div
                            className="parallax-text left-to-right d-flex"
                            style={{
                              transform: `translateY(${-offset * 0.5}px)`,
                            }}
                          >
                            <img src={require("../bitcoin/btc.png")} />
                            <img src={require("../bitcoin/eth.png")} />
                            <img src={require("../bitcoin/xrp.png")} />
                            <img src={require("../bitcoin/usdt.png")} />
                            <img src={require("../bitcoin/bnb.png")} />
                            <img src={require("../bitcoin/matic.png")} />
                            <img src={require("../bitcoin/link.png")} />
                            <img src={require("../bitcoin/trx.png")} />
                            <img src={require("../bitcoin/btc.png")} />
                            <img src={require("../bitcoin/eth.png")} />
                            <img src={require("../bitcoin/xrp.png")} />
                            <img src={require("../bitcoin/usdt.png")} />
                            <img src={require("../bitcoin/bnb.png")} />
                            <img src={require("../bitcoin/matic.png")} />
                            <img src={require("../bitcoin/link.png")} />
                            <img src={require("../bitcoin/trx.png")} />
                          </div>{" "}
                        </div>
                        <div className="col-3">
                          <div
                            className="parallax-text left-to-right d-flex cener_parallax_img"
                            style={{
                              transform: `translateY(${offset * 0.5}px)`,
                            }}
                          >
                            {" "}
                            <img src={require("../bitcoin/bnb.png")} />
                            <img src={require("../bitcoin/matic.png")} />
                            <img src={require("../bitcoin/trx.png")} />
                            <img src={require("../bitcoin/link.png")} />
                            <img src={require("../bitcoin/btc.png")} />
                            <img src={require("../bitcoin/eth.png")} />
                            <img src={require("../bitcoin/xrp.png")} />
                            <img src={require("../bitcoin/usdt.png")} />
                            <img src={require("../bitcoin/bnb.png")} />
                            <img src={require("../bitcoin/matic.png")} />
                            <img src={require("../bitcoin/trx.png")} />
                            <img src={require("../bitcoin/link.png")} />
                            <img src={require("../bitcoin/btc.png")} />
                            <img src={require("../bitcoin/eth.png")} />
                            <img src={require("../bitcoin/xrp.png")} />
                            <img src={require("../bitcoin/usdt.png")} />
                          </div>
                        </div>
                        <div className="col-3">
                          <div
                            className="parallax-text left-to-right d-flex"
                            style={{
                              transform: `translateY(${-offset * 0.5}px)`,
                            }}
                          >
                            <img src={require("../bitcoin/btc.png")} />
                            <img src={require("../bitcoin/eth.png")} />
                            <img src={require("../bitcoin/xrp.png")} />
                            <img src={require("../bitcoin/usdt.png")} />
                            <img src={require("../bitcoin/bnb.png")} />
                            <img src={require("../bitcoin/matic.png")} />
                            <img src={require("../bitcoin/link.png")} />
                            <img src={require("../bitcoin/trx.png")} />
                            <img src={require("../bitcoin/btc.png")} />
                            <img src={require("../bitcoin/eth.png")} />
                            <img src={require("../bitcoin/xrp.png")} />
                            <img src={require("../bitcoin/usdt.png")} />
                            <img src={require("../bitcoin/bnb.png")} />
                            <img src={require("../bitcoin/matic.png")} />
                            <img src={require("../bitcoin/link.png")} />
                            <img src={require("../bitcoin/trx.png")} />
                          </div>{" "}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* keep trading */}
          <section className="keep_trade_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="keep_trade_banner">
                    <div className="keep-relative">
                      <img
                        src={require("../assets/keep_trading_banner.webp")}
                        className="keep_trade_mobile_banner"
                      />
                    </div>
                    <div>
                      <img
                        src={require("../assets/keep_trading_bg.png")}
                        className="keep_trade_bg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="keep_trade_content">
                    <h2>
                      Keep Trading On-The-Go. <br /> Anywhere, Anytime
                    </h2>
                    <p>
                      Trade across platforms on Fidex with our mobile app <br />{" "}
                      and website
                    </p>
                    <img
                      src={require("../assets/trade_qr.webp")}
                      className="trade_qr_img"
                    />
                    <div className="play_apps">
                      <a href="">
                        <img src={require("../assets/gplay.webp")} />
                      </a>
                      <a href="">
                        <img src={require("../assets/ipay.webp")} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* refer and earn */}
        <section className="refer_earn_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="refer_earn_section_content">
                  <h2>Refer & Earn</h2>
                  <p>
                    Invite friends to join our platform and enjoy exclusive
                    rewards.
                    <br /> Benefits from
                  </p>
                  <div className="bonus_btns">
                    <Link to="/refferal">
                      <button>Ultimate Welcome Bonus</button>
                    </Link>
                    <Link to="/refferal">
                      <button>Deposit Bonus</button>
                    </Link>
                    <Link to="/refferal">
                      <button>Trading Bonus</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 refer_earn_img">
                <div className="refer_earn_banner">
                  <img src={require("../assets/refer_earn_banner.webp")} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
}
