import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Site_bar = (data) => {
  
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };
  
const [type,settype ]= useState()
const [mode,setmode ]= useState("")

useEffect(() => {
   var Type= localStorage.getItem("registe_type");
   settype(Type);
    const currentMode = localStorage.getItem('Mode');
    setmode(currentMode ? currentMode:data);
  }, [0]);

  return (
    <div>
      <div className="col-lg-2 ">
        <div className="dashboard_pages_list">
          <Link
            to="/dashboard"
            className={`dashboard_title ${isActive("dashboard")}`}
          >
            <img
              src={require("../assets/dashboard_icon.png")}
              alt="Dashboard"
            />
            <h3>Dashboard</h3>
          </Link>

          <Link
            to="/security"
            className={`dashboard_title ${isActive("security")}`}
          >
            <img
              src={require("../assets/dashboard-security.png")}
              alt="Security"
            />
            <h3>Security</h3>
          </Link>

          {/* <Link
            to="/kycProcess"
            className={`dashboard_title ${isActive("kyc")}`}
          > */}

          {
            type =="Corporates"?
            <Link to="/kyp" className={`dashboard_title ${isActive("kyp")}`}>
            <img
              src={require("../assets/Identification.png")}
              alt="Identification"
            />
            <h3>Identification</h3>
          </Link>
            :
            <Link to="/kyc" className={`dashboard_title ${isActive("kyc")}`}>
            <img
              src={require("../assets/Identification.png")}
              alt="Identification"
            />
            <h3>Identification</h3>
          </Link>
          }

         {
          mode == "pro"?
          <Link
          to="/withdraw"
          className={`dashboard_title ${isActive("withdraw")}`}
        >
          <img
            src={require("../assets/withdraw_icon.png")}
            alt="Withdrawal"
          />
          <h3>Withdrawal</h3>
        </Link>
          :
          ""
         }

        
          <Link
            to="/rewards"
            className={`dashboard_title ${isActive("rewards")}`}
          >
            <img
              src={require("../assets/my_reward_icon.png")}
              alt="My Rewards"
            />
            <h3>My Rewards</h3>
          </Link>

          <Link
            to="/deposithistory"
            className={`dashboard_title ${isActive("deposithistory")}`}
          >
            <img src={require("../assets/history_icon.png")} alt="History" />
            <h3>History</h3>
          </Link>
{/* 
          <Link
            to="/settings"
            className={`dashboard_title ${isActive("settings")}`}
          >
            <img src={require("../assets/Settings_icon.png")} alt="Settings" />
            <h3>Settings</h3>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Site_bar;
