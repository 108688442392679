import React from "react";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { setAuthorization } from "../core/service/axios";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

const TFA = () => {
  const initialFormValue = {
    tfa: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const navigate = useNavigate();

  const { tfa } = formValue;
  const { state } = useLocation();
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.tfaVerify,
        payload: {
          userToken: tfa,
          socketToken: state?.socketToken,
        },
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      console.log("tfa login===", resp);
      if (resp.status) {
        showSuccessToast(resp.Message);
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("tfa_status", resp.tfa);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        showErrorToast(resp.Message);
      }
    }
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is Required";
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification ">
          <div className="container">
            <div>
              <h6>
                {" "}
                <i class="fa-solid fa-arrow-left-long mr-3"></i> Login
              </h6>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  <span class="heading">Two Factor Authentication</span>

                  <div className="notify">
                    {" "}
                    We have sent you an verification code to the register email
                    address{" "}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">2FA Code</h6>
                    <input
                      type="number"
                      min={1000}
                      name="tfa"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={tfa}
                      onChange={handleChange}
                      className="input-field"
                      placeholder="Enter the code"
                    />
                    {tfaValidate == true ? (
                      <p className="text-danger"> {validationnErr.tfa} </p>
                    ) : (
                      ""
                    )}
                    {/* <span className="textgreen eyeicons"> 56 s </span> */}
                  </div>

                  <div className="Submit my-4">
                    {buttonLoader == false ? (
                      <button onClick={formSubmit}>Submit</button>
                    ) : (
                      <button>Loading ...</button>
                    )}
                  </div>

                  <div className="foot">
                    <p>
                      Back to <Link to="/login"> Login page</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TFA;
