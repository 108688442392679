import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [balanceDatas, setbalanceDatas] = useState([]);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const recordPerPage = 5;
  const [perpage, setperpage] = useState(5);
  const [search, setsearch, searchref] = useState("");

  const navigate = useNavigate();

  const depositNav = () => {
    navigate("/deposit");
  };
  const withdrawNav = () => {
    navigate("/Withdraw");
  };
  const convertNav = () => {
    navigate("/swap");
  };

  useEffect(() => {
    getPortfolio();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage);
  }, [0]);

  const handlePageChange = (event, value) => {
    console.log(value, "ujbjjnjn");

    setCurrentPage(value);
    var current_page = +value * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      if (balanceDatas[i] !== undefined) {
        resp_balance.push(balanceDatas[i]);
      }
    }
    setbalanceDetails(resp_balance);
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      console.log(balanceData);
      console.log(balanceData.total_balance);
      console.log(balanceData.available_balance);
      console.log(balanceData.inorder_balance);
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const getUserbalance = async (pages) => {
    // setSiteLoader(false);
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      // setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        if (balanceData[i] !== undefined) {
          resp_balance.push(balanceData[i]);
        }
      }
      // resp_balance = resp_balance.filter(Boolean);
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
    } else {
    }
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      // console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 pad-lft">
              <Side_bar />
            </div>

            <div className="col-lg-10 pad-lft">
              <section className="asset_section">
                <div className="row">
                  <div className="asset_title">Assets</div>
                  <div className="col-lg-12">
                    {/* Estimated Value */}
                    <div className="esti-container">
                      <span className="esti-title">Estimated Value</span>
                      <span className="esti-usdt">
                        {AvailablePrice == "" ||
                        AvailablePrice == null ||
                        AvailablePrice == undefined
                          ? 0.0
                          : AvailablePrice.toFixed(4)}{" "}
                        <span className="esti-span">USD</span>{" "}
                      </span>
                      {/* <span className="esti-num">~ $0.00</span> */}
                    </div>
                    {/* buttons */}
                    <div className="asset-btn-wrapper">
                      <button className="asset-btn" onClick={depositNav}>
                        Deposit
                      </button>
                      <button className="asset-btn" onClick={withdrawNav}>
                        Withdraw
                      </button>
                      <button className="asset-btn" onClick={convertNav}>
                        Convert
                      </button>
                    </div>
                    {/* Assets list */}
                    <h5 className="identify-title">Assets List</h5>
                    <div
                      class="nav nav-tabs border--b_line"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        class="nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Crypto
                      </button>
                      <button
                        class="nav-link "
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Fiat
                      </button>
                    </div>

                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                        tabindex="0"
                      >
                        <div className="table-responsive table-cont dash_table_content">
                          <table className="table ">
                            <thead>
                              <tr className="stake-head font-satoshi">
                                <th>Assets</th>
                                <th className="table_center_text opt-nowrap txt-center pad-left-23">
                                  On Orders
                                </th>
                                <th className="table_center_text opt-nowrap txt-center pad-left-23">
                                  Available Balance
                                </th>
                                <th className="table_action">Total Balance</th>
                              </tr>
                            </thead>

                            <tbody>
                              {balanceDetails && balanceDetails.length > 0 ? (
                                balanceDetails.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="table-flex">
                                        <img src={item?.currencyImage} alt="" />
                                        <div className="table-opt-name">
                                          <h4 className="opt-name font-satoshi font_14">
                                            {item?.currencysymbol}
                                          </h4>
                                          <h3 className="opt-sub font-satoshi font_14">
                                            {item?.currencyName}
                                          </h3>
                                        </div>
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                        {parseFloat(item?.holdAmount).toFixed(
                                          4
                                        )}{" "}
                                        {item?.currencysymbol}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                        {parseFloat(
                                          item?.currencyBalance
                                        ).toFixed(4)}{" "}
                                        {item?.currencysymbol}
                                      </td>
                                      <td className="opt-btn-flex table_action pad-left-23">
                                        {parseFloat(
                                          item?.currencyBalance +
                                            parseFloat(item?.holdAmount)
                                        ).toFixed(4)}{" "}
                                        {item?.currencysymbol}{" "}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../assets/No-data.png")}
                                          width="100px"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        No Records Found
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {balanceDetails && balanceDetails.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(total / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  size="small"
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                        tabindex="0"
                      ></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
