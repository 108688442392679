import React from "react";
import Header from "./Header";

const Changepassword = () => {
  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification ">
          <div className="container">
            <div>
              <h6>
                {" "}
                <i className="fa-solid fa-arrow-left-long mr-3"></i> Security
              </h6>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  <span className="heading">Change Login Password</span>
                  <div className="input-groups icons">
                    <h6 className="input-label">Old Password</h6>
                    <input
                      className="input-field"
                      placeholder="Enter your old password"
                    />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">New Password</h6>
                    <input
                      className="input-field"
                      placeholder="Enter your new password"
                    />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">Confirm Password</h6>
                    <input
                      className="input-field"
                      placeholder="Re-Enter your new password"
                    />
                  </div>

                  <div className="Submit my-4">
                    <button>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
