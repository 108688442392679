import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);


  const submit = async (value) => {
    try {
      console.log(value);
      if (value === "Submit") {
        console.log("===");
        var obj = {
          verifiedSteps: "Step-6",
        };
        var data = {
          apiUrl: apiService.kybSubmition,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/dashboard");
        } else {
          toast.error(resp.Message);
        }
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      setbuttonLoader(false);
    }
  };



  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main information_end">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KypInformation5">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Back
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no_6">6 </span>Certification
                  </h3>
                  <div className="holder_checkbox_content">
                    By clicking Submit, I certify that I am an authorized
                    representative of the legal entity signing up for a FIDEX
                    account and the information provided is true and accurate.
                  </div>
                  <div className="holder_checkbox_content">
                    I have identified all ultimate beneficial owners to the
                    prescribed threshold for the relevant country of formation
                    (25% ownership or more for entities incorporated in the
                    United States, United Kingdom, Canada, Australia, Ireland,
                    and Italy, and 10% ownership or more for all other
                    countries).
                  </div>
                  <div className="holder_checkbox_content">
                    I agree to notify FIDEX with any material changes to the
                    business or ownership structure.
                  </div>
                  <div className="Submit next_subit_buttons my-4">
                  <button
                      className="save_button"
                      onClick={() => submit("Safe for Later")}
                    >
                      Safe for Later
                    </button>
                    <button
                      className="subit_button"
                      onClick={() => submit("Submit")}
                    >
                      Submit
                    </button>
{/*                     
                    <button className="save_button" onClick={submit}>
                      Safe for Later
                    </button>
                    <button className="subit_button" onClick={submit}>
                      Submit
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
