import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";

const Security = () => {
  useEffect(() => {
    getProfile();
    FindData();
    fetchTfaData();
  }, []);

  const [profileData, setprofileData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [APcodes, setAPcodes] = useState("");
  const navigate = useNavigate();
  const [tfaDetails, setTfaDetails] = useState("");
  const [changeCode, setchangeCode] = useState(false);

  const handleClick = (path) => {
    navigate(path);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);

      if (resp.status == true) {
        setprofileData(resp.data);
        // console.log(resp.data);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) {}
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
    };
    setSiteLoader(true);

    var responce = await postMethod(data);
    setSiteLoader(false);

    if (responce.data != null) {
      if (responce.data.APcode != "") {
        var str = responce.data.APcode;
        setAPcodes(str);
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    } else {
      setchangeCode(false);
    }
  };

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);

      setTfaDetails(resp.data.tfastatus);
    } catch (error) {}
  };

  const obfuscateEmail = (email) => {
    if (!email) return "";
    const [localPart, domainPart] = email.split("@");
    const firstFive = localPart.slice(0, 5);
    return `${firstFive}***@${domainPart}`;
  };

  const obfuscateMobileNumber = (mobileNumber) => {
    if (!mobileNumber) return "";
    const firstFive = mobileNumber.slice(0, 5);
    const lastOne = mobileNumber.slice(-1);
    return `${firstFive}****${lastOne}`;
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main assets_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 pad-lft">
                <Side_bar />
              </div>

              <div className="col-lg-10 pad-l-20">
                <div className="dashboard_content border_none">
                  <div className="security_content">
                    <h3>Security</h3>
                  </div>

                  <div className="security_settings">
                    <div>
                      Your security settings is{" "}
                      {profileData.mobileNumber == "" &&
                      localStorage.getItem("tfa_status") == 0 &&
                      changeCode == false ? (
                        <>
                          <span className="low-clr">Low</span>
                        </>
                      ) : localStorage.getItem("tfa_status") == 0 ||
                        changeCode == false ? (
                        <>
                          <span className="medium-clr"> Medium </span>
                        </>
                      ) : (
                        <>
                          <span className="high-clr">High</span>
                        </>
                      )}
                    </div>

                    <div className="low_line">
                      {profileData.mobileNumber == "" &&
                      localStorage.getItem("tfa_status") == 0 &&
                      changeCode == false ? (
                        <>
                          <p className="verify_fail">
                            <i class="ri-shield-keyhole-line"></i> Low
                          </p>
                          <img
                            src={require("../assets/low_line.png")}
                            className="secu_img"
                            alt="Low security"
                          />
                        </>
                      ) : localStorage.getItem("tfa_status") == 0 ||
                        changeCode == false ? (
                        <>
                          <p className="verify_medium">
                            <i class="ri-shield-keyhole-line"></i>{" "}
                            <span>Medium</span>{" "}
                          </p>
                          <img
                            src={require("../assets/mid_line.png")}
                            className="secu_img"
                            alt="Medium security"
                          />
                        </>
                      ) : (
                        <>
                          <p className="verify_success">
                            <i class="ri-shield-keyhole-line"></i> High
                          </p>
                          <img
                            src={require("../assets/high_line.png")}
                            className="secu_img"
                            alt="High security"
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="two_fa_heading">
                    Two-Factor Authentication(2FA)
                  </div>
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/email_icon.png")} />

                      <div>
                        <h3 className="font-satoshi">Email Verification</h3>
                        <p className="font-satoshi">
                          It is used for login, withdrawals, password updates,
                          and security settings
                        </p>
                      </div>
                    </div>
                    <div className="email_id_text font-satoshi">
                      <i class="ri-checkbox-circle-fill"></i>{" "}
                      {obfuscateEmail(profileData.email)}
                    </div>
                  </div>

                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/phone_icon.png")} />

                      <div>
                        <h3 className="font-satoshi">Phone No Verification</h3>
                        <p className="font-satoshi">
                          To safeguard your transactions and account, use your
                          phone number.
                        </p>
                      </div>
                    </div>
                    <div className="email_id_text font-satoshi">
                      <i class="ri-checkbox-circle-fill"></i>
                      {obfuscateMobileNumber(profileData.mobileNumber)}
                    </div>
                  </div>

                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/2fa.png")} />

                      <div className="">
                        <h3 className="font-satoshi">2FA</h3>
                        <p className="font-satoshi">
                          Use 2FA to protect your account and transactions.
                        </p>
                      </div>
                    </div>
                    {tfaDetails === 0 ? (
                      <div className="disabled_text">
                        <p className="font-satoshi">
                          <i class="ri-close-circle-fill"></i> Disabled
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <p className="font-satoshi">
                          <i
                            class="ri-checkbox-circle-fill"
                            style={{ color: "#22b477" }}
                          ></i>{" "}
                          Enabled
                        </p>
                      </div>
                    )}

                    {tfaDetails === 0 ? (
                      <div className="enable_btn">
                        <Link to="/security_2fa">
                          {" "}
                          <button className="font-satoshi">Enable</button>
                        </Link>
                      </div>
                    ) : (
                      <div className="disable_btn">
                        <Link to="/security_2fa">
                          {" "}
                          <button className="font-satoshi">Disable</button>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="two_fa_heading">Advanced Security</div>
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/anti_phishing_icon.png")} />

                      <div>
                        <h3 className="font-satoshi">Anti-phishing Code</h3>
                        <p className="font-satoshi">
                          Displayed in emails from FIDEX to safeguard against
                          <br />
                          phishing attempts.
                        </p>
                      </div>
                    </div>
                    {APcodes === "" || APcodes === undefined ? (
                      <div className="disabled_text">
                        <p className="font-satoshi">
                          <i class="ri-close-circle-fill"></i> Disabled
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <p className="font-satoshi">
                          <i
                            class="ri-checkbox-circle-fill"
                            style={{ color: "#22b477" }}
                          ></i>{" "}
                          Enabled
                        </p>
                      </div>
                    )}
                    {APcodes === "" || APcodes === undefined ? (
                      <div className="enable_btn">
                        <Link to="/anti-phishing">
                          {" "}
                          <button className="font-satoshi">Enable</button>
                        </Link>
                      </div>
                    ) : (
                      <div className="disable_btn">
                        <Link to="/anti-phishing">
                          {" "}
                          <button className="font-satoshi">Change</button>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="two_fa_heading">Password Management</div>
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/login_password_icon.png")} />

                      <div className="">
                        <h3 className="font-satoshi">Login Password</h3>
                        <p className="font-satoshi">
                          Login password is used to log in to your account.
                        </p>
                      </div>
                    </div>

                    <div className="enable_btn">
                      <Link to="/security_change">
                        <button className="font-satoshi">Change</button>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="two_fa_heading">Account Management</div>
                <div className="security_email_content">
                  <div className="security_email_item">
                    <img src={require("../assets/delete_icon.png")} />

                    <div className="">
                      <h3 className="font-satoshi">Delete Account</h3>
                      <p className="font-satoshi">
                        Note: All the related data will be deleted and cannot be
                        recovered after the deletion.
                      </p>
                    </div>
                  </div>

                  <div className="delete_button">
                    <button className="font-satoshi">Delete</button>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Security;
