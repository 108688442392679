import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData4";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import "react-phone-input-2/lib/style.css";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Side_bar from "./Side_bar";
import { Bars } from "react-loader-spinner";
import moment from "moment";

const Dashboard = () => {
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setfromCurrencyRef(currArrayCrypto);
  }, [0]);

  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata] = useState(value);

  const [SubjectErr, setSubjectErr] = useState(value);
  const [CategoryErr, setCategoryErr] = useState(value);
  const [textErr, settextErr] = useState(value);

  const [formErr, setformErr] = useState("");

  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [supportCategories, setCategories] = useState(0);
  const [viewChat, setViewChat] = useState(false);
  const [replyMess, replyMessage, replyMessref] = useState("");
  const [chatDatas, setChatDatas] = useState("");
  const [chatHistory, setchatHistory] = useState([]);

  const { Subject, Category, text } = Formdata;
  const [updateField, setUpdateField] = useState("");

  const getItem = (e) => {
    var { name, value } = e.target;
    if (name === "Category") {
      setUpdateField("category");
    }
    let check = { ...Formdata, ...{ [name]: value } };
    setFormdata(check);
    validate(Formdata);
  };
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    console.log("use effect running");
    if (skipCount) setSkipCount(false);
    if (!skipCount) {
      validate(Formdata);
    }
  }, [updateField]);

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    viewData();
    getCategory();
  }, [0]);
  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      reply();
    }
  };

  const validate = async (condition) => {
    var error = {};
    console.log({ Subject });
    if (condition.Subject == "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (condition.Subject?.length < 5) {
      error.Subject = "Minimum 5 Characters only allowed ";
      setSubjectErr(true);
    } else if (condition.Subject?.length > 50) {
      error.Subject = "Maximum 50 Characters only allowed ";
      setSubjectErr(true);
    } else {
      setSubjectErr(false);
    }

    if (condition.Category === "Choose Category") {
      error.Category = "Category is a required field";
      setCategoryErr(true);
      console.log(condition.Category, "condition.Category");
    } else {
      setCategoryErr(false);
      console.log(condition.Category, "condition.Category");
    }

    if (condition.text == "") {
      error.text = "Message is a required field";
      settextErr(true);
    } else if (condition.text?.length < 10) {
      error.text = "Minimum 10 Characters only allowed ";
      settextErr(true);
    } else if (condition.text?.length > 250) {
      error.text = "Maximum 250 Characters only allowed ";
      settextErr(true);
    } else {
      settextErr(false);
    }
    setformErr(error);
  };

  const submit = async () => {
    validate(Formdata);
    // return false;
    if (
      Formdata.Subject != "" &&
      Formdata.Subject.length > 5 &&
      Formdata.Subject.length < 50 &&
      Formdata.Category != "Choose Category" &&
      Formdata.text != "" &&
      Formdata.text.length > 10 &&
      Formdata.text.length < 250
    ) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData();
    } else {
      //toast.error("All are required fields");
    }
  };
  const viewData = async () => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
      };
      var view = await postMethod(api);
      console.log(view, "=-=-view=-=-=-");
      if (view.status) setuser(view.data.data);
      setTotalpages(view.data.total);
      console.log(view.data.total);
    } catch (error) {}
  };

  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      var response = await getMethod(api);
      if (response.status) {
        setCategories(response.data);
      }
    } catch (error) {}
  };

  const userChat = async (data) => {
    try {
      console.log("=-=-==data=--=-=", data);
      setChatDatas(data);
      setViewChat(true);
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.getSupportDetails,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        setchatHistory(view.Message.reply);
      } else {
        setchatHistory([]);
      }
      console.log(view, "=-=-view=-=-=-view=-=-=-view=-=");
      console.log(view.data.total);
    } catch (error) {}
  };
  const closeTicket = async (data) => {
    try {
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.closeTicket,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        // userChat(chatDatas);
        viewData();
        toast.success(view.Message);
      } else {
        toast.error(view.Message);
      }
    } catch (error) {}
  };

  const reply = async () => {
    try {
      if (Object.keys(replyMessref.current).length > 0) {
        var obj = {
          message: replyMessref.current,
          chatId: chatDatas._id,
          tag: "user",
          image: "",
          status: "0",
        };
        var api = {
          apiUrl: apiService.addUserSupport,
          payload: obj,
        };
        var view = await postMethod(api);
        if (view) {
          console.log("**********");
          userChat(chatDatas);
          replyMessage("");
          toast.success(view.Message);
        }
      } else {
        toast.error("Please enter the message!");
      }
    } catch (error) {}
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <Side_bar />
            </div>

            <div className="col-lg-10">
              <section className="asset_section">
                <div className="row">
                  <div className="withdraw_title_content">
                    <div className="withdraw_title">Support</div>
                  </div>
                  <div className="col-lg-7">
                    <div className="deposit mt-5">
                      <div className="form_div">
                        <div className="sides">
                          <div className="w-100 rights">
                            <h4>
                              {" "}
                              <span>1</span> Subject{" "}
                            </h4>
                            <input
                              type="text"
                              name="Subject"
                              value={Subject}
                              onChange={getItem}
                              placeholder="Enter The Subject"
                              fluid
                              className="dep-drops"
                            />
                            {SubjectErr == true ? (
                              <p
                                className="errorcss"
                                style={{ textAlign: "left" }}
                              >
                                {formErr.Subject}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form_div ">
                        <h4>
                          {" "}
                          <span>2</span> Select Category
                        </h4>
                        <select
                          className="dep-drops"
                          name="Category"
                          value={Category}
                          onChange={getItem}
                        >
                          <option selected disabled>
                            Choose Category
                          </option>
                          {supportCategories.length > 0 ? (
                            supportCategories.map((item, i) => (
                              <option selected>{item.category}</option>
                            ))
                          ) : (
                            <option selected>Choose Category</option>
                          )}
                        </select>
                        {/* <Dropdown
                          placeholder="Select Coin"
                          fluid
                          className="dep-drops"
                          selection
                          options={supportCategories}
                          value={Category}
                          onChange={getItem}
                          defaultValue={supportCategories[0]}
                          onChange={(o) =>
                            onSelect(o, "fromTab")
                          }
                          onChange={handleOnChange_from}
                        /> */}
                        {CategoryErr == true ? (
                          <p className="errorcss" style={{ textAlign: "left" }}>
                            {formErr.Category}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form_div boder-none ">
                        <h4>
                          {" "}
                          <span>3</span> Message
                        </h4>

                        <textarea
                          maxLength="250"
                          name="text"
                          value={text}
                          onChange={getItem}
                          placeholder="Enter the Message"
                          fluid
                          rows="5"
                          className="dep-drops support_textarea"
                        />
                        {textErr == true ? (
                          <p className="errorcss" style={{ textAlign: "left" }}>
                            {formErr.text}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                        <div className="sumbit_btn">
                          {buttonLoader == false ? (
                            <button onClick={submit}>Submit</button>
                          ) : (
                            <button>Loading ...</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div>
                      <div className="container">
                        <div
                          class="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="faq mt-5">
                            <h5 className="faq-title">FAQ</h5>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  1.How do I deposit funds into Fidex?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Log in, go to 'Deposit,' choose your currency,
                                  and send funds to the provided address.
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingTwo"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  2. What are Fidex’s trading and withdrawal
                                  fees?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Fees vary by transaction type and currency.
                                  Check our 'Fees' page for details.
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  3.How do I reset my password?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Click 'Forgot Password' on the login page,
                                  enter your email, and follow the instructions
                                  to reset it.
                                </div>
                              </div>
                            </div>
                            {/* four */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFour"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFour"
                                >
                                  4.Can I trade on Fidex using my mobile device?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Yes, Fidex offers a mobile app for both iOS
                                  and Android, allowing you to trade on the go.
                                </div>
                              </div>
                            </div>
                            {/* five */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFive"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFive"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFive"
                                >
                                  5. How do I enable two-factor authentication
                                  (2FA)?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Go to 'Security Settings' in your account, and
                                  follow the prompts to set up 2FA for added
                                  security.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboard_table">
                  <div className="staking-flex dash_assets">
                    <h5 className="opt-title">Support History</h5>
                    <div className="d-flex gap-2 text_green">
                      View <i class="fa-solid fa-chevron-right"></i>
                    </div>
                  </div>
                  <div className="table-responsive table-cont dash_table_content">
                    <table className="table ">
                      <thead>
                        <tr className="stake-head font-satoshi">
                          <th>Ticket ID</th>
                          <th className="table_center_text">Subject</th>
                          <th className="table_center_text">Message</th>
                          <th className="table_center_text">Category</th>
                          <th className="table_action">Date & Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {user.length > 0 ? (
                          user.map((data, i) => (
                            <tr>
                              <td className="opt-term font-satoshi font_14 table_center_frst">
                                #{data._id.substring(0, 8)}
                              </td>
                              <td className="opt-term font-satoshi font_14 table_center_text">
                                {data.subject}
                              </td>
                              <td className="opt-term font-satoshi font_14 table_center_text ">
                                {data.message}
                              </td>
                              <td className="opt-term font-satoshi font_14 table_center_text">
                                {data.category}
                              </td>
                              <td className="opt-term font-satoshi font_14 table_center_last">
                                {moment(data.updated_at).format("lll")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center py-5">
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../assets/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {user.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(totalPage / recordPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                            size="small"
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
