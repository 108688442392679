import React from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import RewardTable from "./RewardTable";

const Rewards = () => {
  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 pad-lft">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12">
              <section className="identification_section">
                <div className="row">
                  <div className="buy_head">
                    <div className="buy-rewards buy-rewards-col">
                      <span className="reward-title">My Rewards</span>
                      <div className="stake-search-container">
                        <input
                          type="text"
                          placeholder="Search"
                          className="stake-input"
                        />
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                    <RewardTable />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Rewards;
