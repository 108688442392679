import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";

const WithdrawTable = () => {
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getwithdrawHistory(1);
  }, [0]);

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);
    var withdraw_history_list = await postMethod(data);
    setSiteLoader(false);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.pages);
    }
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;

  const handlepagewithdraw = (event, page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  return (
    <>
      <section>
        <Header />
      </section>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main assets_main ">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 pad-lft">
                <Side_bar />
              </div>

              <div className="col-lg-10 pad-lft">
                <section className="asset_section">
                  <div className="row">
                    <div className="buy_head">
                      <div className="Buycrypto_title">History</div>
                      <ul className="history-lists">
                        <Link to="/depositHistory" className="history-links ">
                          Deposit
                        </Link>
                        <Link
                          to="/withdrawHistory"
                          className="history-links active"
                        >
                          Withdraw
                        </Link>
                        <Link to="/tradeHistory" className="history-links">
                          Trade
                        </Link>
                        <Link to="/loginHistory" className="history-links">
                          Login
                        </Link>
                        <Link to="/referralHistory" className="history-links">
                          Referral
                        </Link>
                        <Link to="/adminrewardTable" className="history-links">
                          Reward
                        </Link>
                        <Link to="/stakingHistory" className="history-links">
                          Staking
                        </Link>
                        <Link to="/swapHistory" className="history-links">
                          Swap
                        </Link>
                        <Link to="/openorderHistory" className="history-links ">
                          Open Order History
                        </Link>
                        <Link
                          to="/cancelorderHistory"
                          className="history-links "
                        >
                          Cancel Order History
                        </Link>
                        <Link to="/stoporderHistory" className="history-links ">
                          Stop Order History
                        </Link>
                      </ul>
                      {/* <AssetListTable /> */}

                      <div className="table-responsive table-cont">
                        <table className="table">
                          <thead className="ref_table_head">
                            <tr className="stake-head font-satoshi">
                              <th>Transaction ID</th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                Amount
                              </th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                Currency
                              </th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                Date
                              </th>
                              <th className="opt-btn-flex table-action ">
                                Status
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {withdrawHistory.length > 0 ? (
                              withdrawHistory.map((data, i) => {
                                return (
                                  <tr>
                                    <td className="opt-percent font-satoshi font_14 px-2 pad-top-14px">
                                      {data.txn_id != undefined
                                        ? data.txn_id.slice(0, 10)
                                        : "-"}
                                      <i
                                        class="fa fa-clone"
                                        aria-hidden="true"
                                        onClick={() => copy(data.txn_id)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                      {data.amount == undefined
                                        ? 0
                                        : data.amount.toFixed(4)}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                      {data.currency}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                      {Moment(data.created_at).format("lll")}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 px-2 table_center_last pad-top-14px">
                                      {data.txn_id != "--------" ? (
                                        <span className="text-green">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="text-red">
                                          Pending
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.png")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      No Records Found
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {withdrawHistory && withdrawHistory.length > 0 ? (
                          <div className="pagination">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(
                                  withdrawtotalpage / withdrawrecordpage
                                )}
                                page={withdrawcurrentpage}
                                onChange={handlepagewithdraw}
                                size="small"
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default WithdrawTable;
