import React, { useEffect } from "react";
import useState from "react-usestateref";
import Kyp_information1 from "./Kyp_information1";
import Kyp_information2 from "./Kyp_information2";
import Kyp_information3 from "./Kyp_information3";
import Kyp_information4 from "./Kyp_information4";
import Kyp_information5 from "./Kyp_information5";
import Kyp_information6 from "./Kyp_information6";
import Kyp from "./kyp";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

const KypProcess = () => {
  //   const getSavedProgress = () => {
  //     const savedStep = localStorage.getItem('currentStep');
  //     return savedStep ? parseInt(savedStep, 10) : 1; // Default to step 1 if nothing is saved
  //   };

  const [currentStep, setCurrentStep, currentStepref] = useState("Step-1");

  useEffect(() => {
    getStep();
    // localStorage.setItem('currentStep', currentStep);
  }, []);

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "------");
      setCurrentStep(resp.data === null ? "" : resp.data.verifiedSteps);
      console.log(currentStepref.current, "currentStep");
    } catch (err) {}
  };

  const renderStep = () => {
    switch (currentStepref.current) {
      case "Step-1":
        return <Kyp_information2 onNext={() => setCurrentStep("Step-2")} />;
      case "Step-2":
        return <Kyp_information3 onNext={() => setCurrentStep("Step-3")} />;
      case "Step-3":
        return <Kyp_information4 onNext={() => setCurrentStep("Step-4")} />;
      case "Step-4":
        return <Kyp_information5 onNext={() => setCurrentStep("Step-5")} />;
      case "Step-5":
        return <Kyp_information6 onNext={() => setCurrentStep("Step-6")} />;
      case "Step-6":
        return <Kyp onNext={() => setCurrentStep("Step-6")} />;
      default:
        return <Kyp_information1 onNext={() => setCurrentStep("Step-1")} />;
    }
  };

  return <div>{renderStep()}</div>;
};

export default KypProcess;
