import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [isShowOther, setIsShowOther] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  useEffect(() => {
    getStep();
  }, [0]);
  ///AUTHER -JOTHI START

  const submit = async () => {
    try {
      validate(formValue);
      formValue["verifiedSteps"] = "Step-4";
      if (!!formValue.relation) {
        // &&!!formValue.inflowTransactionfidex &&!!formValue.outflowTransactionfidex &&!!formValue.fidexProducts &&!!formValue.relation &&!!formValue.countryofbank &&!!formValue.Frequency_inflowdata &&!!formValue.verifiedSteps){
        var data = {
          apiUrl: apiService.kycfourth,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/KycInformation5");
        } else {
          toast.error(resp.Message);
        }
      } else {
        validate(formValue);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleResend = async () => {
    setCounter(60);
    setIsResendVisible(false);
    // Add logic to resend the OTP
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleDrop = () => {
    setIsShowDrop((prevDrop) => !prevDrop);
  };
  const handleOther = () => {
    setIsShowOther((prevDrop) => !prevDrop);
  };

  const initialFormValue = {
    inflowsFidex: "",
    outflowsFidex: "",
    inflowTransactionfidex: [],
    outflowTransactionfidex: [],
    InternationalTransfers: [],
    fidexProducts: [],
    relation: "",
    otherrelations: "",
    countryofbank: "",
    Frequency_inflowdata: "",
    outInternationalTransfers: [],
  };

  const [fidexrelationship, setfidexrelationship] = useState([
    { label: "Savings", value: "Savings" },
    { label: "Financing", value: "Financing" },
    { label: "Investment", value: "Investment" },
    { label: "Others", value: "Others" },
  ]);

  const [fidexProductList, setfidexProductList] = useState([
    { label: "Spot", value: "Spot" },
    { label: "Staking", value: "Staking" },
    { label: "Lending", value: "Lending" },
  ]);

  const [Frequency_inflow, setFrequency_inflow] = useState([
    { label: "At account opening", value: "At account opening" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Random/Undefined", value: "Random/Undefined" },
    { label: "Annually", value: "Annually" },
    { label: "Semi-annually", value: "Semi-annually" },
  ]);

  const [inflowsFidexdata, setinflowsFidexdata] = useState([
    { label: "Less than EUR 300,000", value: "Less than EUR 300,000" },
    { label: "Between EUR 300,000 and 500,000" },
    {
      label: "Between EUR 500,000 and 1,500,000",
      value: "Between EUR 500,000 and 1,500,000",
    },
    {
      label: "Between EUR 1,500,000 and 3,000,000",
      value: "Between EUR 1,500,000 and 3,000,000",
    },
    {
      label: "Between EUR 3,000,000 and 6,000,000",
      value: "Between EUR 3,000,000 and 6,000,000",
    },
    { label: "More than EUR 6,000,000", value: "More than EUR 6,000,000" },
  ]);

  const [outflowsFidexdata, setoutflowsFidexdata] = useState([
    { label: "Less than EUR 300,000", value: "Less than EUR 300,000" },
    {
      label: "Between EUR 300,000 and 500,000",
      value: "Between EUR 300,000 and 500,000",
    },
    {
      label: "Between EUR 500,000 and 1,500,000",
      value: "Between EUR 500,000 and 1,500,000",
    },
    {
      label: "Between EUR 1,500,000 and 3,000,000",
      value: "Between EUR 1,500,000 and 3,000,000",
    },
    {
      label: "Between EUR 3,000,000 and 6,000,000",
      value: "Between EUR 3,000,000 and 6,000,000",
    },
    { label: "More than EUR 6,000,000", value: "More than EUR 6,000,000" },
  ]);

  const [inflowtransaction, setinflowtransaction] = useState([
    { label: "Domestic Transfers", value: "Domestic Transfers" },
    { label: "International Transfers", value: "International Transfers" },
    { label: "Bank Card", value: "Bank Card" },
    { label: "Direct debits", value: "Direct debits" },
    { label: "Internal Operations", value: "Internal Operations" },
  ]);

  const [outflowtransaction, setoutflowtransaction] = useState([
    { label: "Domestic Transfers", value: "Domestic Transfers" },
    { label: "International Transfers", value: "International Transfers" },
    { label: "Internal operations", value: "Internal operations" },
  ]);

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationnErr, setvalidationnErr] = useState("");
  const [ShowOtherRelationInput, setShowOtherRelationInput] = useState(false);
  const [otherRelation, setOtherRelation] = useState("");
  const [otherInflowTranscti, setOtherInflowTranscti] = useState("");
  const [relationValidate, setrelationValidate, relationValidateref] =
    useState(false);
  const [
    fidexProductsValidate,
    setfidexProductsValidate,
    fidexProductsValidateref,
  ] = useState(false);
  const [
    inflowsFidexValidate,
    setinflowsFidexValidate,
    inflowsFidexValidateref,
  ] = useState(false);
  const [
    outflowsFidexValidate,
    setoutflowsFidexValidate,
    outflowsFidexValidateref,
  ] = useState(false);
  const [
    InternationalTransfersValidate,
    setInternationalTransfersValidate,
    InternationalTransfersValidateref,
  ] = useState(false);
  const [
    Frequency_inflowdataValidate,
    setFrequency_inflowdataValidate,
    Frequency_inflowdataValidateref,
  ] = useState(false);
  const [
    outflowTransactionfidexValidate,
    setoutflowTransactionfidexValidate,
    outflowTransactionfidexValidateref,
  ] = useState(false);
  const [
    inflowTransactionfidexValidate,
    setinflowTransactionfidexValidate,
    inflowTransactionfidexValidateref,
  ] = useState(false);
  const [
    outInternationalTransfersValidate,
    setoutInternationalTransfersValidate,
    outInternationalTransfersValidateref,
  ] = useState(false);
  const [selectinflowtransactionInput, setselectinflowtransactionInput] =
    useState();
  const [selectoutflowtransactionInput, setselectoutflowtransactionInput] =
    useState();
  const [countryofbankValidate, setcountryofbankValidate] = useState();

  const {
    inflowsFidex,
    outflowsFidex,
    inflowTransactionfidex,
    InternationalTransfers,
    outflowTransactionfidex,
    fidexProducts,
    outInternationalTransfers,
    Frequency_inflowdata,
    relation,
    otherrelations,
    countryofbank,
  } = formValue;

  const handlfidexrelationshipChange = (selectedRelation) => {
    if (selectedRelation.value === "Others") {
      setShowOtherRelationInput(true);
      setFormValue({
        ...formValue,
        relation: "Others",
      });
    } else {
      setShowOtherRelationInput(false);
      const updatedFormValue = {
        ...formValue,
        relation: selectedRelation.value,
      };

      setFormValue(updatedFormValue);
      validate({
        ...formValue,
        relation:
          selectedRelation.value === "Others" ? "" : selectedRelation.value,
      });
    }
  };

  const handleinflowTransactionChange = (event) => {
    const { value, checked } = event.target;
    let updatetransaction;

    if (checked) {
      updatetransaction = [...formValue.inflowTransactionfidex, value];
    } else {
      updatetransaction = formValue.inflowTransactionfidex.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      inflowTransactionfidex: updatetransaction,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);

  };

  const handleoutflowTransactionChange = (event) => {
    const { value, checked } = event.target;
    let updateouttransaction;

    if (checked) {
      updateouttransaction = [...formValue.outflowTransactionfidex, value];
    } else {
      updateouttransaction = formValue.outflowTransactionfidex.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      outflowTransactionfidex: updateouttransaction,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const handleOtherRelationChange = (e) => {
    setOtherRelation(e.target.value);
    const updatedFormValue = {
      ...formValue,
      otherrelations: e.target.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const validate = (values) => {
    let errors = {};

    if (values.relation == "") {
      setrelationValidate(true);
      errors.relation = " Purpose of the relationship is required !";
    } else if (values.relation == "Others" && values.otherrelations == "") {
      setrelationValidate(true);
      errors.otherrelations = " Purpose of the relationship is required !";
    } else {
      setrelationValidate(false);
    }

    if (values.fidexProducts == "") {
      setfidexProductsValidate(true);
      errors.fidexProducts = " Fidex products is required !";
    } else {
      setfidexProductsValidate(false);
    }

    if (values.Frequency_inflowdata == "") {
      setFrequency_inflowdataValidate(true);
      errors.Frequency_inflowdata = " Frequency of inflows is required !";
    } else {
      setFrequency_inflowdataValidate(false);
    }

    if (values.inflowTransactionfidex == "") {
      setinflowTransactionfidexValidate(true);
      errors.inflowTransactionfidex = " Inflow Transaction is required !";
    } else {
      setinflowTransactionfidexValidate(false);
    }

    if (values.inflowTransactionfidex === "International Transfers") {
      setInternationalTransfersValidate(true);
      errors.InternationalTransfers = " International Transfers is required !";
    } else {
      setInternationalTransfersValidate(false);
    }

    if (values.outflowTransactionfidex == "") {
      setoutflowTransactionfidexValidate(true);
      errors.outflowTransactionfidex = " Outflow Transaction is required !";
    } else {
      setoutflowTransactionfidexValidate(false);
    }

    if (values.outflowTransactionfidex === "International Transfers") {
      setoutInternationalTransfersValidate(true);
      errors.InternationalTransfers = " International Transfers is required !";
    } else {
      setoutInternationalTransfersValidate(false);
    }

    if (values.outflowsFidex == "") {
      setoutflowsFidexValidate(true);
      errors.outflowsFidex = " Annual volume of outflows is required !";
    } else {
      setoutflowsFidexValidate(false);
    }

    if (values.inflowsFidex == "") {
      setinflowsFidexValidate(true);
      errors.inflowsFidex = " Annual volume of inflows is required !";
    } else {
      setinflowsFidexValidate(false);
    }

    if (values.countryofbank == "") {
      setcountryofbankValidate(true);
      errors.countryofbank = " Country is required !";
    } else {
      setcountryofbankValidate(false);
    }

    setvalidationnErr(errors);
  };

  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      countryofbank: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue); // Assuming validate is a function to validate the form
  };

  const handleProductChange = (event) => {
    const { value, checked } = event.target;
    let updatedfidexProducts;

    if (checked) {
      updatedfidexProducts = [...formValue.fidexProducts, value];
    } else {
      updatedfidexProducts = formValue.fidexProducts.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      fidexProducts: updatedfidexProducts,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handlefrequencyinflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      Frequency_inflowdata: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleoutflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      outflowsFidex: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleinflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      inflowsFidex: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handlInternationalTransfersChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      InternationalTransfers: event.target.value,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const handloutInternationalTransfersChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      outInternationalTransfers: event.target.value,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kycData = resp.data;
        formValue.inflowsFidex = kycData.inflowsFidex;
        formValue.outflowsFidex = kycData.outflowsFidex;
        formValue.inflowTransactionfidex = kycData.inflowTransactionfidex;
        formValue.outflowTransactionfidex = kycData.outflowTransactionfidex;
        formValue.InternationalTransfers = kycData.InternationalTransfers;
        formValue.fidexProducts = kycData.fidexProducts;
        formValue.relation = kycData.relationshipwithfides;
        formValue.otherrelations = kycData.otherrelations;
        formValue.countryofbank = kycData.countryofbank;
        formValue.Frequency_inflowdata = kycData.frequency_inflowdata;
        formValue.outInternationalTransfers = kycData.outInternationalTransfers;
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KycInformation3">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Verification
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">4</span>Account Holder Activity
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Purpose of the relationship with FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={fidexrelationship}
                        value={fidexrelationship.find(
                          (option) => option.value === relation
                        )}
                        onChange={handlfidexrelationshipChange}
                        placeholder="Select relation"
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {validationnErr && validationnErr.relation && (
                      <p className="errorcss">{validationnErr.relation}</p>
                    )}

                    {ShowOtherRelationInput && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          Please specify your relationship
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={otherRelation}
                            onChange={handleOtherRelationChange}
                            className=""
                            placeholder="Enter your relatioship"
                          />
                        </div>
                        {validationnErr && validationnErr.otherrelations && (
                          <p className="errorcss">
                            {validationnErr.otherrelations}
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Which FIDEX products do you plan to use?
                    </h6>
                    <div className="input-checkboxs">
                      <ul>
                        {fidexProductList.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.fidexProducts.includes(
                                option.value
                              )}
                              onChange={handleProductChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                      {validationnErr && validationnErr.fidexProducts && (
                        <p className="errorcss">
                          {validationnErr.fidexProducts}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Expected annual volume of inflows/incomes to FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={inflowsFidexdata}
                        value={inflowsFidexdata.find(
                          (option) => option.value === inflowsFidex
                        )}
                        onChange={handleinflowincomeChange}
                        placeholder="Select expected annual volume "
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {validationnErr && validationnErr.inflowsFidex && (
                      <p className="errorcss">{validationnErr.inflowsFidex}</p>
                    )}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Frequency of inflows/incomes to FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={Frequency_inflow}
                        value={Frequency_inflow.find(
                          (option) => option.value === Frequency_inflowdata
                        )}
                        onChange={handlefrequencyinflowincomeChange}
                        placeholder="Select frequency of inflows"
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {validationnErr && validationnErr.Frequency_inflowdata && (
                      <p className="errorcss">
                        {validationnErr.Frequency_inflowdata}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Expected annual volume of outflows from FIDEX
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={outflowsFidexdata}
                        value={outflowsFidexdata.find(
                          (option) => option.value === outflowsFidex
                        )}
                        onChange={handleoutflowincomeChange}
                        placeholder="Select expected annual volume "
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                    {validationnErr && validationnErr.outflowsFidex && (
                      <p className="errorcss">{validationnErr.outflowsFidex}</p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Common inflow transactions to FIDEX
                    </h6>

                    <div className="input-checkboxs">
                      <ul>
                        {inflowtransaction.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.inflowTransactionfidex.includes(
                                option.value
                              )}
                              onChange={handleinflowTransactionChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}

                        {formValue.inflowTransactionfidex.includes(
                          "International Transfers"
                        ) && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              Please specify your inflow transactions to FIDEX{" "}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.InternationalTransfers}
                                onChange={handlInternationalTransfersChange}
                                className=""
                                placeholder="please specify"
                              />
                            </div>
                          </div>
                        )}
                        {validationnErr &&
                          validationnErr.inflowTransactionfidex && (
                            <p className="errorcss pad">
                              {validationnErr.inflowTransactionfidex}
                            </p>
                          )}
                        {validationnErr &&
                          validationnErr.InternationalTransfers && (
                            <p className="errorcss pad">
                              {validationnErr.InternationalTransfers}
                            </p>
                          )}
                      </ul>
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Common outflow transactions from FIDEX
                    </h6>

                    <div className="input-checkboxs">
                      <ul>
                        {outflowtransaction.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.outflowTransactionfidex.includes(
                                option.value
                              )}
                              onChange={handleoutflowTransactionChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}

                        {formValue.outflowTransactionfidex.includes(
                          "International Transfers"
                        ) && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              Please specify your outflow transactions to FIDEX{" "}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.outInternationalTransfers}
                                onChange={handloutInternationalTransfersChange}
                                className=""
                                placeholder="please specify"
                              />
                            </div>
                          </div>
                        )}
                        {validationnErr &&
                          validationnErr.outflowTransactionfidex && (
                            <p className="errorcss pad">
                              {validationnErr.outflowTransactionfidex}
                            </p>
                          )}
                        {validationnErr &&
                          validationnErr.outInternationalTransfers && (
                            <p className="errorcss pad">
                              {validationnErr.outInternationalTransfers}
                            </p>
                          )}
                      </ul>
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      Which country is your bank account in?
                    </h6>
                    <div className="input-field ref_input upload_icon kyc_country_dropdown">
                      <Select
                        options={options}
                        value={options.find(
                          (option) => option.value === countryofbank
                        )}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        styles={colourStyles}
                      />
                    </div>

                    {validationnErr && validationnErr.countryofbank && (
                      <p className="errorcss pad">
                        {validationnErr.countryofbank}
                      </p>
                    )}
                  </div>

                  <div className="Submit my-4" onClick={submit}>
                    <button>Next</button>
                    {/* <Link to="/KycInformation5">
                      <button>Next</button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
